import {Component, Input, ViewChild} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import StaffingratioFirmSettings from 'src/app/core/models/staffingration-subscription-settings.model';
import {RenameCustomPeergroupComponent} from '../rename-custom-peergroup/rename-custom-peergroup.component';
import {SRCustomPeerGroupRequestBody} from 'src/app/core/models/firmStaffingpeergroupstatus.model';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
@Component({
  selector: 'app-custom-peergroup-grid',
  templateUrl: './custom-peergroup-grid.component.html',
  styleUrls: ['./custom-peergroup-grid.component.scss'],
})
export class CustomPeergroupGridComponent {
  isDataLoading = false;
  customPeerGroupsList: any = [];
  customPeerGroupsFirmsList: any = [];
  alerts: BentoAlertItemOptions[] = [];
  customPeerGroupsData: any = [];
  successMsg = '';
  errorMessage = '';
  colArr = [];
  colPeerGroupsArr = [];
  isColumnFreezed = true;
  frozenColumnCount = 4;
  isSaving: boolean = false;
  @Input() selectedFirmId: number;
  @Input() currentSurveyYearOfFirm: any;
  @Input() selectedFirmSRSubscriptionInfo: StaffingratioFirmSettings;
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  isSubmitCustomPeerGroupDisabled = false;
  currentRecords: number;
  totalRecords: number;
  isUnsavedChanged: boolean = false;
  lastPressedKey: any = null;
  gridsSelectedRow = 0;
  gridsSelectedCol = 0;
  calledFirstTime = true;
  _lastFocusCell: any;

  constructor(private service: BaseService, public modalService: NgbModal, public activeModal: NgbActiveModal) {}
  async ngOnInit(): Promise<void> {
    this.isDataLoading = true;
    await this.getAvailableFirms();
    await this.getCustomPeerGroupsList();
    await this.getCombinedCustomPeerGroupsAndFirmsData();
    this.totalRecords = this.customPeerGroupsFirmsList.length;
    this.currentRecords = this.totalRecords;
    this.customPeerGroupsData = new wjcCore.CollectionView(this.customPeerGroupsFirmsList);
    this.isDataLoading = false;
  }

  getAvailableFirms() {
    this.isDataLoading = true;
    return this.service
      .get(
        environment.FIClientBaseEndpoint + 'v1/staffingratio/subscriptions/' + this.selectedFirmId + '?surveyYear=',
        this.currentSurveyYearOfFirm
      )
      .toPromise()
      .then(
        (result) => {
          this.isDataLoading = false;
          this.customPeerGroupsFirmsList = result;
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
  async getCombinedCustomPeerGroupsAndFirmsData() {
    if (this.customPeerGroupsList && this.customPeerGroupsList.length > 0) {
      this.customPeerGroupsFirmsList.map((_firm) => {
        this.customPeerGroupsList.map((peerGroup, indx) => {
          if (
            peerGroup.peerFirmMembers &&
            peerGroup.peerFirmMembers.length > 0 &&
            peerGroup.peerFirmMembers.indexOf(_firm.firmId) !== -1
          ) {
            _firm[peerGroup.peerGroupName + indx] = true;
          } else {
            _firm[peerGroup.peerGroupName + indx] = false;
          }
          peerGroup.headerId = peerGroup.peerGroupName + indx;
        });
      });
      this.colPeerGroupsArr = [];
      this.colArr = [];
      this.customPeerGroupsList.map((peerGroup, indx2) => {
        let obj = {binding: peerGroup.peerGroupName + indx2, header: peerGroup.peerGroupName};
        this.colArr.push(obj);
        this.colPeerGroupsArr.push(peerGroup.peerGroupName + indx2);
      });
    }
  }
  getCustomPeerGroupsList() {
    var isGlobal = false;
    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/peergroup?firmId=' +
          this.selectedFirmId +
          '&surveyYear=' +
          this.currentSurveyYearOfFirm +
          '&isGlobal=' +
          isGlobal,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.customPeerGroupsList = result;
        },
        (error) => {
          this.errorMessage = error.error.message;
          /*this.alerts.push({
          type: 'warning',
          msg: this.errorMessage,
          closeable: true,
        });*/
        }
      );
  }
  resetAll() {
    this.isUnsavedChanged = true;
    for (let k = 0; k < this.colArr.length; k++) {
      let groupName = 'Group ' + (k + 1);
      this.colArr[k].header = groupName;
      this.customPeerGroupsList.find((i) => i.headerId == this.colArr[k].binding).peerGroupName = groupName;
    }

    for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
      for (let colName = 0; colName < this.colArr.length; colName++) {
        this.flexGrid.rows[i].dataItem[this.colArr[colName].binding] = false;
        this.flexGrid.rows[i].cssClass = '';
      }
    }
  }
  gridInitialized(flexGrid: wjcGrid.FlexGrid) {
    /*Check for height of popup to add vertical scroll*/
    const addClassToPopup = () => {
      const modalContentDiv = document.querySelector('.sr-custom-peergroup-modal') as HTMLElement | null;
      if (!modalContentDiv) return;

      let popupHeight = modalContentDiv.offsetHeight;
      console.log(popupHeight);
      const modalContentGrid = modalContentDiv.querySelector('.modal-content') as HTMLElement | null;

      if (popupHeight < 500) {
        modalContentGrid.classList.add('overflowY');
      }
    };

    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 50;
      flexGrid.columnHeaders.rows.defaultSize = 50;
      // flexGrid.select(-1,-1);
    }
    flexGrid.hostElement.addEventListener(
      'keydown',
      (event) => {
        this.handleKeyDown(event);
      },
      true //Capture should be true
    );
    flexGrid.formatItem.addHandler((s, e: any) => {
      if (
        e.panel.cellType == wjcGrid.CellType.ColumnHeader &&
        this.colPeerGroupsArr &&
        this.colPeerGroupsArr.length > 0 &&
        this.colPeerGroupsArr.indexOf(s.columns[e.col].binding) !== -1
      ) {
        let val = s.columns[e.col];
        let peerGroup = this.customPeerGroupsList.find((obj) => obj.headerId == val.binding);
        let pgId = null;
        if (peerGroup && peerGroup.peerGroupId) {
          pgId = peerGroup.peerGroupId;
        }
        if (this.selectedFirmSRSubscriptionInfo.peerSubmitted) {
          e.cell.textContent = peerGroup.peerGroupName;
        } else {
          e.cell.textContent = '';

          // Create the heading element
          const heading = document.createElement('h2');
          heading.className = 'h6 mb-0 DataGrid-heading';

          // Create the anchor element
          const anchor = document.createElement('a');
          anchor.href = '';
          anchor.setAttribute('aria-haspopup', 'dialog');
          anchor.id = `peergrouphyperlink-${peerGroup.headerId}`;
          anchor.role = 'button';
          anchor.setAttribute('aria-label', val.header);
          anchor.textContent = peerGroup.peerGroupName;
          anchor.dataset.pgName= val.header;
          anchor.dataset.headerid=peerGroup.headerId
          // Append the anchor to the heading
          heading.appendChild(anchor);

          // Append the heading to the cell
          e.cell.appendChild(heading);
          let el = e.cell.querySelector('a');
          el.onclick = (et: any) => {
            et.preventDefault();
            let dataSet = et.target.dataset;
            this.showRenameCustomPeerGroupPopUp(dataSet);
          };
        }
      }
    });

    this.flexGrid = flexGrid;
    let filter = new wjcGridFilter.FlexGridFilter(this.flexGrid);
    filter.defaultFilterType = wjcGridFilter.FilterType.Both;
    this.gridFilter = filter;
    addClassToPopup();
  }
  focusOnGrid(flexGrid, event) {
    if (!this.calledFirstTime) flexGrid.select(this.gridsSelectedRow, this.gridsSelectedCol);
    else {
      this.calledFirstTime = false;
      flexGrid.select(0, 0);
    }
  }
  getNextColumnToSelect() {
    let grid = this.flexGrid;
    let col = grid.selection.rightCol + 1,
      row = grid.selection.bottomRow;

    // If last cell in a row is already selected.
    if (col >= grid.columns.length) {
      // If there are any more rows in the grid.
      if (row + 1 < grid.rows.length) row++;
      return {col: 0, row};
    }
    //
    return {col, row};
  }

  showRenameCustomPeerGroupPopUp(peerGroupObj) {
    const modalRef = this.modalService.open(RenameCustomPeergroupComponent, {
      ariaLabelledBy: 'modalRenameCustomPeerGroup',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.peerGroup = peerGroupObj;
    modalRef.result.then(
      (result) => {
        if (result) {
          let colObj = this.colArr.find((i) => i.binding == peerGroupObj.headerid);
          if (colObj.header != result) {
            this.colArr.find((i) => i.binding == peerGroupObj.headerid).header = result;
            this.customPeerGroupsList.find((i) => i.headerId == peerGroupObj.headerid).peerGroupName = result;
            this.isUnsavedChanged = true;
          }
          // console.log(this.colArr);
          // this.flexGrid.invalidate()
          // this.gridInitialized(this.flexGrid);
        }
      },
      (reason) => {
        this.closeRenamePG();
      }
    );
  }
  printDoc() {
    // create PrintDocument
    let doc = new wjcCore.PrintDocument({
      title: '',
      copyCss: false, // prevent cross-origin issues in jsfiddle
    });
    var align_center =
      'text-align:center;font-size: 23px; opacity: 0.8; font-weight:normal; padding-bottom:10px;padding-top:10px;';
    // add CSS explicitly (since we can't use copyCss in jsfiddle)
    doc.append('<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet">');
    doc.append('<link href="https://cdn.grapecity.com/wijmo/5.latest/styles/wijmo.min.css" rel="stylesheet">');

    // add some simple text
    doc.append(
      '<p style="' +
        align_center +
        '">Custom Peer Group Selections for ' +
        this.selectedFirmSRSubscriptionInfo.firmName +
        '</p>'
    );
    let tbl = this._renderTable(this.flexGrid);
    doc.append(tbl);
    // print the document
    doc.print();
  }

  // renders grid as a table
  private _renderTable(flex: wjcGrid.FlexGrid) {
    // start table
    var tbl_style = '';
    let tbl = '<table>';
    // headers
    if (flex.headersVisibility & wjcGrid.HeadersVisibility.Column) {
      tbl += '<thead>';
      console.log(flex.columnHeaders);
      for (let r = 0; r < flex.columnHeaders.rows.length; r++) {
        tbl += this._renderRow(flex.columnHeaders, r);
      }
      tbl += '</thead>';
    }
    // body
    tbl += '<tbody style="opacity: 0.8; letter-spacing: 0.0425em;">';
    for (let r = 0; r < flex.rows.length; r++) {
      tbl += this._renderRow(flex.cells, r);
    }
    tbl += '</tbody>';
    // done
    tbl += '</table>';
    return tbl;
  }

  private _renderRow(panel: wjcGrid.GridPanel, r: number) {
    let tr = '',
      row = panel.rows[r];
    if (row.renderSize > 0) {
      tr += '<tr>';
      for (let c = 0; c < panel.columns.length; c++) {
        let col = panel.columns[c];
        if (col.renderSize > 0) {
          // get cell style, content
          let style =
            'width:' +
            col.renderSize +
            'px;' +
            'text-align:' +
            col.getAlignment() +
            ';' +
            'padding-right:6px;font-size: 12px;';
          let content = panel.getCellData(r, c, true);
          /*if (!row.isContentHtml && !col.isContentHtml) {
                  content = wjcCore.escapeHtml(content);
              }*/
          // add cell to row
          if (panel.cellType == wjcGrid.CellType.ColumnHeader) {
            var current_sort = col.currentSort;
            let sort_icon = '';

            if (current_sort == '+')
              sort_icon =
                '<span style="transform:rotate(270deg);display:inline-block;margin-left:6px;">&#x276f;</span>';
            else if (current_sort == '-')
              sort_icon = '<span style="transform:rotate(90deg);display:inline-block;margin-left:6px;">&#x276f;</span>';
            else sort_icon = '';

            //console.log('current_sort'+current_sort);
            tr += '<th style="' + style + '">' + content + sort_icon + '</th>';
          } else {
            // show boolean values as checkboxes
            let raw = panel.getCellData(r, c, false);
            if (raw === true) {
              style =
                'width:' +
                col.renderSize +
                'px;' +
                'text-align:' +
                col.getAlignment() +
                ';' +
                'padding-right:6px;font-size: 16px';
              content = '&#9745;';
            } else if (raw === false) {
              style =
                'width:' +
                col.renderSize +
                'px;' +
                'text-align:' +
                col.getAlignment() +
                ';' +
                'padding-right:6px;font-size: 16px';
              content = '&#9744;';
            }
            tr += '<td style="' + style + '">' + content + '</td>';
          }
        }
      }
      tr += '</tr>';
    }
    return tr;
  }
  async submitCustomPeerGroup() {
    this.isSaving = true;
    let requestBodyAray = [];
    for (let i = 0; i < this.customPeerGroupsList.length; i++) {
      let firmIdsStr = [];
      let firmIds = [];
      this.customPeerGroupsFirmsList.map((_firm) => {
        if (_firm[this.customPeerGroupsList[i].headerId] == true) {
          firmIdsStr.push(_firm.firmId.toString());
          firmIds.push(_firm.firmId);
        }
      });
      requestBodyAray.push(this.getRequestBody(firmIdsStr, this.customPeerGroupsList[i]));
    }
    console.log(requestBodyAray);
    if (requestBodyAray.length > 0) await this.callSaveAPI(requestBodyAray, this.selectedFirmId);
  }
  getRequestBody(firmIds: string[], peerGroup) {
    let requestBody = new SRCustomPeerGroupRequestBody();
    if (peerGroup.peerGroupId && peerGroup.peerGroupId != null && peerGroup.peerGroupId != 'null')
      requestBody.peerGroupId = peerGroup.peerGroupId;
    requestBody.peerGroupName = peerGroup.peerGroupName;
    requestBody.surveyYear = peerGroup.surveyYear;
    requestBody.isGlobal = false;
    requestBody.firmId = parseInt(peerGroup.firmId);
    requestBody.firmIds = firmIds;
    return requestBody;
  }
  callSaveAPI(requestBodyAray: SRCustomPeerGroupRequestBody[], firmId: number) {
    return this.service
      .post(environment.FIClientBaseEndpoint + 'v1/staffingratio/peergroup/custom/' + firmId, requestBodyAray)
      .toPromise()
      .then(
        (result) => {
          this.activeModal.close('Success');
        },
        (error) => {
          console.log(error.error.message);
          this.isSaving = false;
          this.errorMessage = error.error.message;
          this.activeModal.close(this.errorMessage);
        }
      );
  }

  initFilter() {
    this.gridFilter.defaultFilterType = wjcGridFilter.FilterType.Both;
    this.gridFilter.showSortButtons = false;
  }
  onFilterApply() {
    this.currentRecords = this.flexGrid.rows.length;
  }
  freezeColumnToggle() {
    if (this.isColumnFreezed) this.frozenColumnCount = 0;
    else this.frozenColumnCount = 4;

    this.isColumnFreezed = !this.isColumnFreezed;
  }
  openResetAllConfirmation(resetAllModalContent) {
    this.modalService
      .open(resetAllModalContent, {ariaLabelledBy: 'modalResetAllChanges', backdrop: 'static', keyboard: false})
      .result.then(
        (result: string) => {
          if (result === 'confirm') {
            this.resetAll();
          }
        },
        (reason) => {}
      );
  }
  onPeerGroupCheckBoxChange(checkbox) {
    this.isUnsavedChanged = true;
  }
  cancelOperation(confirmationModalContent) {
    if (this.isUnsavedChanged) {
      this.modalService
        .open(confirmationModalContent, {
          ariaLabelledBy: 'modalConfirmUnsavedChanges',
          backdrop: 'static',
          keyboard: false,
        })
        .result.then(
          (result: string) => {
            if (result === 'confirm') {
              this.activeModal.dismiss('Cross click');
            }
          },
          (reason) => {}
        );
    } else {
      this.activeModal.dismiss('Cross click');
    }
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col;
    this.gridsSelectedRow = args.row;
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }

  handleKeyDown(event) {
    this.lastPressedKey = event.code;
    if (wjcCore.closestClass(event.target, 'wj-colheaders') && event.code == 'Space') {
      this._lastFocusCell = this.flexGrid.hitTest(event.target);
      let cell = this.flexGrid.activeCell;
      let el = cell.querySelector('a[id*="peergrouphyperlink-"]') as HTMLInputElement;
      el.click();
      event.preventDefault();
      event.stopPropagation();
    } else if (event.code === 'Space') {
      event.preventDefault();
      let cell = this.flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
      let input = cell.querySelector('input');
      input.click();
      wjcCore.setAttribute(cell as Element, 'aria-checked', (input as HTMLInputElement).checked);
      cell.focus();
    }
  }
  closeRenamePG() {
    if (this._lastFocusCell)
      this.flexGrid.select(new wjcGrid.CellRange(-0, this._lastFocusCell.col), true, this.flexGrid.columnHeaders);
    else this.flexGrid.select(new wjcGrid.CellRange(-0, 4), true, this.flexGrid.columnHeaders);
    this._lastFocusCell = null;
  }
  selectFirstHeader(flexGrid, e) {
    flexGrid.select(new wjcGrid.CellRange(-0, 4), true, this.flexGrid.columnHeaders);
  }
}
