import {Component, OnInit, OnDestroy, HostBinding} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import FirmDetailModel from 'src/app/core/models/firmdetail.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {AuthService} from '../../../services/auth/auth.service';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import {Role} from 'src/app/shared/enums';
import {PeergroupService} from 'src/app/core/services/peergroup/peergroup.service';
import {Firmpeergroupstatus} from 'src/app/core/models/firmpeergroupstatus.model';

@Component({
  selector: 'app-firm-parameter',
  templateUrl: './firm-parameter.component.html',
  styleUrls: ['./firm-parameter.component.scss'],
})
export class FirmParameterComponent implements OnInit, OnDestroy {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  constructor(
    private service: BaseService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private peerGroupService: PeergroupService
  ) {}

  firmDetailModel: FirmDetailModel = new FirmDetailModel();
  errorMessage = '';
  isGetDataLoading = false;
  activeTab = 'Settings';
  alerts: BentoAlertItemOptions[] = [];
  firmId: number;
  currentRoute: string;
  isFirmsPeerGroupSelected = false;
  isEditPeerGroup = false;
  selectedOption: any = null;

  isFirmsUserSelected = false;

  tabData: any[] = ['Settings', 'Users', 'Peer groups'];
  isNewFirm = false;

  peerGroupStatus = new Firmpeergroupstatus();

  ngOnInit(): void {
    this.peerGroupService.currentMessage.subscribe((message) => {
      this.peerGroupStatus = message;
      this.alerts = this.peerGroupStatus.alerts;
    });
    if (this.route.snapshot && this.route.snapshot['_routerState']) {
      this.currentRoute = this.route.snapshot['_routerState'].url;
    }
    if (this.currentRoute && this.currentRoute.includes('/administrator/addfirm')) {
      this.isNewFirm = true;
    }
    if (this.route.snapshot.params.id) {
      this.firmId = Number(this.route.snapshot.params.id);
    } else {
      const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
      this.firmId =
        backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.firmID
          ? backendTokenClaims.userDDO.firmID
          : 0;
    }
    if (!this.isNewFirm) this.getfirmData(this.firmId);

    // Add body class
    document.body.classList.add('Body--adminFirmsSettings');

    if (this.peerGroupService.getSelectedTab != null && this.peerGroupService.getSelectedTab != '') {
      this.onTabChange(this.peerGroupService.getSelectedTab);
    }
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  public get isFAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.FAdmin;
  }

  ngOnDestroy() {
    // Add body class
    document.body.classList.remove('Body--adminFirmsSettings');
    if (this.peerGroupService) {
      this.peerGroupService.setAlerts = [];
    }
  }

  getfirmData(firmId) {
    this.isGetDataLoading = true;
    let params = new HttpParams();
    params = params.set('firmId', firmId);
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm?', params).subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.firmDetailModel = result;
        eventDispatcher.next({type: ActionTypes.SetFirmDetail, payload: result});
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;

        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  closeAlert($event) {}

  onTabChange(tabName) {
    if (!this.tabData.includes(tabName)) return;
    this.activeTab = tabName;
    if (tabName != 'Peer groups') this.peerGroupService.reset();
    this.getfirmData(this.firmId);
  }

  usersActionHandler(message: string) {
    let response = JSON.parse(message);
    this.selectedOption = response.selectedOption;
    if (response.message == 'Add') {
      this.isFirmsUserSelected = true;
    } else if (response.message == 'Edit') {
      this.isFirmsUserSelected = true;
    }

    if (response.message == 'Return' || response.message == '') {
      this.isFirmsUserSelected = false;
    } else if (response.message == 'Deleted') {
      this.alerts.push({
        type: 'warning',
        msg: 'User has been deleted.',
        closeable: true,
      });
      this.isFirmsUserSelected = false;
    }
  }

  handleAddPeerGroupAction() {
    this.peerGroupService.setCustomPeerGroupMode = 'add';
    this.peerGroupService.setIsDefaultView = false;
    this.peerGroupService.peerGroupStatusHandler();
  }
}
