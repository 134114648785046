import {Component, OnInit, HostBinding, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {FormatdateService} from 'src/app/core/services/formatdate/formatdate.service';
import {DateFormat} from 'src/app/shared/enums';
import {PeerCheckServiceService} from '../../peer-check/peer-check/peer-check-service.service';

@Component({
  selector: 'app-balancing',
  templateUrl: './balancing.component.html',
  styleUrls: ['./balancing.component.scss'],
})
export class BalancingComponent implements OnInit {
  firmId: number;
  fiscalPeriods = [];
  selectedFiscalPeriod: string;
  errorMessage = '';
  alerts: BentoAlertItemOptions[] = [];
  balancigData: any;

  // reference to FlexGrid Data Collection View
  data: wjcCore.CollectionView;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  isGetDataLoading = false;
  message = 'No Data Found';
  isVarianceData: boolean;
  balancingValue: any;
  isDisabled: boolean;
  flag: any = false;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private service: BaseService,
    private formatdate: FormatdateService,
    private _peerCheckService: PeerCheckServiceService
  ) {}

  ngOnInit(): void {
    this.firmId = this.getfirmId;
    this.route.params.subscribe((val) => {
      if (val && val.id) {
        this.firmId = val.id;
      } else {
        if (this._peerCheckService.getSelectedFirmId && this._peerCheckService.getSelectedFirmId != this.getfirmId) {
          this.firmId = this._peerCheckService.getSelectedFirmId;
        } else {
          this.firmId = this.getfirmId;
        }
      }
      if (this._peerCheckService.isValidMappingFirm(this.firmId)) {
        this.getPeriodDropDownValue();
      }
    });
    this._peerCheckService.getBalancingValue().subscribe((data) => {
      this.balancingValue = data;
    });
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  getIndicatorFullName(name: string) {
    if (name == 'work_hrs_wp') return 'Worked hours';
    else if (name == 'work_amt_wp') return 'Worked dollars';
    else if (name == 'bill_amt_bp') return 'Billed dollars';
    else if (name == 'bill_amt_bp_wr') return 'Worked dollars';
    else if (name == 'paid_amt_cp') return 'Billed dollars with reversals';
    else if (name == 'paid_amt_cp_wr') return 'Collected dollars with reversals';
    else if (name == 'woff_amt_cp') return 'Written-off dollars';
    else if (name == 'woff_amt_cp_wr') return 'Written-off dollars with reversals';
  }
  onClick(item) {
    this._peerCheckService.setIndicatorName(item.indicatorName);
    this._peerCheckService.setSelectedPeriod(this.selectedFiscalPeriod);
  }

  isClickable(item) {
    if (item.deviation) {
      const deviation = parseFloat(item.deviation.substr(0, item.deviation.length - 1));
      return deviation > 1 || deviation < -1;
    }
    return false;
  }

  //Handler for FlexGrid's Initialized method
  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
  }

  getPeriodDropDownValue() {
    this.isGetDataLoading = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/balancing/period/' + this.firmId, '').subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.fiscalPeriods = result;
        if (this.fiscalPeriods.length > 0) {
          this.flag = true;
        } else {
          this.flag = false;
        }
        this._peerCheckService.setDefaultSubject(this.fiscalPeriods.length > 0 ? this.fiscalPeriods[0].period : null);

        this.selectedFiscalPeriod = this.fiscalPeriods.length > 0 ? this.fiscalPeriods[0].period : null;
        if (this.selectedFiscalPeriod != null) {
          if (this.balancingValue.selected == 'Default') {
            this._peerCheckService.setBalancingValue({...this.balancingValue, value: this.selectedFiscalPeriod});
          } else {
            this.selectedFiscalPeriod = this.balancingValue.value;
          }

          this.getBalancingData();
        } else {
          this.data = new wjcCore.CollectionView();
          this.isDisabled = true;
          this.isVarianceData = false;
        }
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  onChangeFiscalPeriod() {
    this._peerCheckService.setBalancingValue({...this.balancingValue, value: this.selectedFiscalPeriod});
    this.getBalancingData();
  }

  restrictCurrencyFormat(cell, item) {
    if (cell == 'work_hrs_wp') return item == null ? '--' : item;
    else return item === null ? '--' : this.formatter.format(item);
  }

  getBalancingData() {
    this.isGetDataLoading = true;
    this.service
      .get(
        environment.FIClientBaseEndpoint + 'v1/mapping/' + this.firmId + '/forPeriod/' + this.selectedFiscalPeriod,
        ''
      )
      .subscribe(
        (result) => {
          if (result) {
            this.balancigData = result;

            if (this.balancigData.varianceDetails.length > 0) {
              this.flag = true;
            } else {
              this.flag = false;
            }
            if (this.balancigData.varianceDetails) {
              for (let i = 0; i < this.balancigData.varianceDetails.length; i++) {
                if (this.balancigData.varianceDetails[i].deviation != null) {
                  this.balancigData.varianceDetails[i].deviation += '%';
                }
              }
            }
            this._peerCheckService.setDisplayAuthorizeButton(this.balancigData.displayAuthorizeButton);
            if (this.balancigData != null) {
              this.data = new wjcCore.CollectionView(this.balancigData.varianceDetails);

              this.isVarianceData = true;
              if (!this.balancigData.displayAuthorizeButton) this.isDisabled = true;
              else this.isDisabled = false;
            } else {
              this.data = new wjcCore.CollectionView();
              this.isDisabled = true;
              this.isVarianceData = false;
            }
          } else {
            this.flag = false;
            this.balancigData = {};
            this.data = new wjcCore.CollectionView();
            this.isDisabled = true;
            this.isVarianceData = false;
          }
          this.isGetDataLoading = false;
        },
        (error) => {
          this.isGetDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  authorizeExtract() {
    this.isGetDataLoading = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/balancing/authorize/' + this.firmId, '').subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.getBalancingData();
        this.alerts.push({
          type: 'success',
          msg: 'Extract authorized successfully.',
          closeable: true,
        });
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  extractDateFormat(date) {
    return this.formatdate.format(date, DateFormat.mmddpostfixyyyy);
  }
}
