import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import {GlobalPeerGroupDetails} from 'src/app/core/models/globalPeerGroupDetails.model';
import {GlobalPeerGroupList} from 'src/app/core/models/globalPeerGroupList.model';
import {environment} from 'src/environments/environment';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-global-peer-groups-firms',
  templateUrl: './global-peer-groups-firms.component.html',
})
export class GlobalPeerGroupsFirmsComponent implements OnInit {
  @Input() selectedGlobalPeerGroup: GlobalPeerGroupList = new GlobalPeerGroupList();
  @Output() backToGlobalPeerGroupsAction = new EventEmitter();
  searchText: string;

  currentRecords: number;
  totalRecords: number;
  isLoadedFirstTime: boolean;
  firms: any[] = [];
  isGetDataLoading = false;
  data: wjcCore.CollectionView;
  currentInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };
  alerts: BentoAlertItemOptions[] = [];
  globalPeerGroupDetails: GlobalPeerGroupDetails = new GlobalPeerGroupDetails();
  lastPressedKey: any;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  errorMessage: any;
  visibility = '';
  constructor(private service: BaseService, private globalservice: GlobalService) {}

  ngOnInit(): void {
    this.data = new wjcCore.CollectionView();
    this.data.pageSize = this.currentInfo.pageSize;
    this.isLoadedFirstTime = true;
    this.totalRecords = 0;
    this.getGlobalPeerGroupDetails();
    this.searchText = '';
  }

  backToGlobalPeerGroups(): void {
    this.backToGlobalPeerGroupsAction.emit();
  }
  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.currentRecords = this.data.itemCount;
    if (flexGrid.rows)
      // flexGrid.select(-1,-1);
      this.flexGrid = flexGrid;
    flexGrid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
  }
  focusOnGrid(flexGrid, event) {
    flexGrid.select(0, 0);
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }
  // Pagination
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
    this.currentRecords = this.data.itemCount;
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.data.pageSize = itemsPerPage;
    this.currentRecords = this.data.itemCount;
  }

  searchGlobalPeerGroups() {
    let filteredData = this.globalPeerGroupDetails.selectedfirms.filter(
      (element) => element.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
    );
    this.data = new wjcCore.CollectionView(filteredData);
    this.data.pageSize = this.currentInfo.pageSize;
    this.currentRecords = this.data.itemCount;
  }

  getGlobalPeerGroupDetails() {
    this.isGetDataLoading = true;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/peergroup/global/', this.selectedGlobalPeerGroup.peerId)
      .subscribe(
        (result) => {
          this.isGetDataLoading = false;
          this.globalPeerGroupDetails = result;
          this.visibility = this.globalPeerGroupDetails.selectedLocales.toString();
          this.data = new wjcCore.CollectionView(this.globalPeerGroupDetails.selectedfirms);
          this.data.pageSize = this.currentInfo.pageSize;
          this.currentRecords = this.data.itemCount;
          if (this.isLoadedFirstTime) {
            this.totalRecords = this.data.totalItemCount;
            this.isLoadedFirstTime = false;
          }
          this.globalservice.addPaginationAdditionalInfo();
        },
        (error) => {
          this.isGetDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
}
