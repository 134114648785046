import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {environment} from 'src/environments/environment';
import * as wjcCore from '@grapecity/wijmo';
import {PeerCheckServiceService} from '../../peer-check/peer-check/peer-check-service.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {BentoAlertItemOptions} from '@bento/bento-ng';
@Component({
  selector: 'app-variance',
  templateUrl: './variance.component.html',
})
export class VarianceComponent implements OnInit {
  firmId: number;
  varianceData: wjcCore.CollectionView;
  isGetDataLoading = false;
  period: any;
  indicatorName: any;
  data: any;
  headerTitles: any;
  colArr: any = [];
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  displayAuthorizeButton: any;
  balancigData: any;

  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _peerCheckService: PeerCheckServiceService,
    private service: BaseService
  ) {
    this.varianceData = new wjcCore.CollectionView(this.data);
  }

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.firmId = Number(this.route.snapshot.params.id);
    } else {
      const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
      this.firmId =
        backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.firmID
          ? backendTokenClaims.userDDO.firmID
          : 0;
    }
    this._peerCheckService.getIndicatorName().subscribe((data) => {
      this.indicatorName = data;
    });
    this._peerCheckService.getSelectedPeriod().subscribe((data) => {
      this.period = data;
    });

    this.getVarianceData();
    this._peerCheckService.getDisplayAuthorizeButtonValue().subscribe((data) => {
      this.displayAuthorizeButton = data;
    });
  }
  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.flexGrid = flexGrid;
  }
  onFormatItem(s, e) {
    if (s.editRange) {
      return;
    }
    if (s.cells === e.panel) {
      let data = s.getCellData(e.row, e.col);

      if (data == '' || data == null || data == 'null') {
        e.cell.innerHTML = '-';
      }
    }
  }
  getBalancingData() {
    this.isGetDataLoading = true;
    this.service
      .get(environment.FIClientBaseEndpoint + 'v1/mapping/' + this.firmId + '/forPeriod/' + this.period, '')
      .subscribe((result) => {
        this.isGetDataLoading = false;
        this.balancigData = result;
        this._peerCheckService.setDisplayAuthorizeButton(this.balancigData.displayAuthorizeButton);
      });
  }
  onClickAuthorizeExtract() {
    this.isGetDataLoading = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/balancing/authorize/' + this.firmId, '').subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.getBalancingData();
        this.alerts.push({
          type: 'success',
          msg: 'Extract authorized successfully.',
          closeable: true,
        });
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  getVarianceData() {
    this.isGetDataLoading = true;
    if (this.indicatorName != '' && this.period != '') {
      if (this.firmId) {
        this.service
          .get(
            environment.FIClientBaseEndpoint + 'v1/mapping/balancing/variance/firmId/' + this.firmId,
            '/period/' + this.period + '/indicatorId/' + this.indicatorName
          )
          .subscribe(
            (result) => {
              this.isGetDataLoading = false;
              this.data = result;
              this.headerTitles = this.data[0].headerTitles;
              const keys = Object.keys(this.headerTitles);
              for (let i = 0; i < keys.length; i++) {
                if (keys[i] != 'tranIndex') {
                  let obj = {binding: keys[i], header: this.data[0].headerTitles[keys[i]]};
                  this.colArr.push(obj);
                }
              }

              if (this.data != null) {
                for (let i = 0; i < this.data.length; i++) {
                  this.data[i].period = this.data[i].period + '';
                  this.data[i].invPeriod = this.data[i].invPeriod + '';
                  this.data[i].tranPeriod = this.data[i].tranPeriod + '';
                }
                this.varianceData = new wjcCore.CollectionView(this.data);
              } else {
                this.varianceData = new wjcCore.CollectionView();
              }
            },
            (error) => {
              this.isGetDataLoading = false;
              this.errorMessage = error.error;
              this.alerts.push({
                type: 'warning',
                msg: 'Something went wrong, please try again.',
                closeable: true,
              });
            }
          );
      }
    }
  }
}
