<bento-alert [items]="alerts"> </bento-alert>
<section class="Section">
  <header class="Section-header">
    <h2 class="Section-heading">User information</h2>
  </header>
  <span class="label-required-summary">Required field</span>
  <form name="userInfo" #userInfo="ngForm" class="Section-form Form" bfmForm novalidate>
    <div class="Section-body">
      <div *ngIf="isTAdmin && isAddUser" class="form-group Form-input Form-input--medium">
        <label for="firmId" class="bento-label-required">Firm ID</label>
        <input
          id="firmId"
          name="firmId"
          bfmField
          bfmLabel="Firm ID"
          type="text"
          class="bento-input form-control"
          [disabled]="true"
          [(ngModel)]="userData.firmId"
          required
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="firstName" class="bento-label-required">First name</label>
        <input
          id="firstName"
          name="firstName"
          type="text"
          bfmField
          bfmLabel="First name"
          class="bento-input form-control"
          [(ngModel)]="userData.firstName"
          required
          [disabled]="showApiUser && userData.isApiUser"
          [pattern]="validatePattern"
          [validations]="validations.validatePattern"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="lastName" class="bento-label-required">Last name</label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          bfmField
          bfmLabel="Last name"
          class="bento-input form-control"
          [(ngModel)]="userData.lastName"
          required
          [disabled]="showApiUser && userData.isApiUser"
          [pattern]="validatePattern"
          [validations]="validations.validatePattern"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="email" class="bento-label-required">Email</label>
        <input
          id="email"
          name="email"
          type="text"
          bfmField
          bfmLabel="Email"
          class="bento-input form-control"
          [(ngModel)]="userData.email"
          [disabled]="showApiUser && userData.isApiUser"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          required
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="role" id="roleLabel" class="bento-label-required">Role</label>
        <select
          name="role"
          id="role"
          class="form-control"
          bentoSelect
          bfmField
          bfmLabel="Role"
          [disabled]="showApiUser && userData.isApiUser"
          [(ngModel)]="userData.role"
          aria-labelledby="roleLabel"
          required
        >
          <option *ngFor="let type of roleValues" [ngValue]="type.value">{{ type.label }}</option>
        </select>
      </div>
      <div *ngIf="isTAdmin" class="form-group Form-input Form-input--medium">
        <label for="pmmUser" id="pmmUserLabel" class="bento-label-required">PMM user</label>
        <select
          name="pmmUser"
          id="pmmUser"
          class="form-control"
          bentoSelect
          bfmField
          bfmLabel="PMM user"
          [disabled]="showApiUser && userData.isApiUser"
          [(ngModel)]="userData.usesPMM"
          aria-labelledby="pmmUserLabel"
          required
        >
          <option *ngFor="let type of pmmUserValues" [ngValue]="type.value">{{ type.label }}</option>
        </select>
      </div>
      <div *ngIf="isTAdmin" class="form-group Form-input Form-input--medium">
        <label for="staffingRatioUser" id="staffingRatioUserLabel" class="bento-label-required"
          >Staffing ratio user</label
        >
        <select
          name="staffingRatioUser"
          id="staffingRatioUser"
          class="form-control"
          bentoSelect
          bfmField
          bfmLabel="Staffing ratio user"
          [disabled]="showApiUser && userData.isApiUser"
          [(ngModel)]="userData.isStaffingratioUser"
          (change)="onIsSRUserChange(userData.isStaffingratioUser)"
          aria-labelledby="staffingRatioUserLabel"
          required
        >
          <option *ngFor="let type of pmmUserValues" [ngValue]="type.value">{{ type.label }}</option>
        </select>
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="currency" id="currencyLabel" class="bento-label-required">Currency</label>
        <select
          name="currency"
          id="currency"
          class="form-control"
          bentoSelect
          bfmField
          bfmLabel="Currency"
          [(ngModel)]="userData.currency"
          [disabled]="(showApiUser && userData.isApiUser) || userData.isStaffingratioUser"
          aria-labelledby="currencyLabel"
          required
        >
          <ng-container *ngIf="!newzealandCurrency">
            <option *ngFor="let type of currencyValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </ng-container>
          <ng-container *ngIf="newzealandCurrency">
            <option *ngFor="let type of currencyValues" [ngValue]="type.currencyCode">
              {{ type.currencyDescription }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="closeUserInfo()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div *ngIf="isEditUser" class="Actions-item">
          <button
            type="button"
            class="btn btn-danger Actions-button max-width-100"
            [bentoBusyLoader]="isDeleting"
            [disabled]="isSelfUser || isSaving || (showApiUser && userData.isApiUser)"
            (click)="deleteUser()"
          >
            <span class="btn-text">Delete</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            type="submit"
            class="btn btn-primary Actions-button max-width-100"
            [bentoBusyLoader]="isSaving"
            [attr.aria-disabled]="userInfo.invalid || isDeleting || (showApiUser && userData.isApiUser) ? 'true' : 'false'"
            (click)="userInfo.invalid || isDeleting || (showApiUser && userData.isApiUser) ? $event.stopPropagation() : saveUserInfo()"
            [attr.aria-labelledby]="userInfo.invalid || isDeleting || (showApiUser && userData.isApiUser) ? 'aria-required-save' : null "
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
        <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
      </div>
    </footer>
  </form>
</section>
