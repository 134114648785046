import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BentoAlertItemOptions, BentoModalConfirmationCloseReason, BentoModalConfirmationService} from '@bento/bento-ng';
import {
  Detail,
  GLAccDepartment,
  GLAccMappedOffice,
  GLAccOffice,
  GlacOverride,
  Override,
} from 'src/app/core/models/glac-override.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {BulkeditService} from 'src/app/core/services/bulkedit/bulkedit.service';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {PeerCheckServiceService} from '../../../peer-check/peer-check/peer-check-service.service';
import {FiscalPeriodComponent} from '../../shared/fiscal-period/fiscal-period.component';
import {MapAccountGridComponent} from '../../shared/map-account-grid/map-account-grid.component';

@Component({
  selector: 'app-gl-account-codes-edit',
  templateUrl: './gl-account-codes-edit.component.html',
  styleUrls: ['./gl-account-codes-edit.component.scss'],
})
export class GlAccountCodesEditComponent implements OnInit, AfterViewInit {
  glacMappedOfficeName = [];
  selectedAccountCodes = [];
  saveChoices: {value: string; label: string}[];
  model;
  glAccCode: String;
  accCategory: String;
  mappedAccName: String;
  isDataLoading: boolean;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  selectedGlAcOverride: GlacOverride;
  showPercentError: any = false;
  glacMappedOffice: GLAccMappedOffice[];
  glacDepartment: GLAccDepartment[];
  glacOffice: GLAccOffice[];
  defaultOverride: any;
  initialOverride: any;
  mapAccountSelection = {};
  headers: {name: string; index: number; active: number}[];
  avaliableCols: number;
  readyToSubmit = false;
  bulkEditTitle: String = '';
  isOverride = false;
  overrides: Override[] = [];
  selectedOverride: any = null;
  previousSelectedOverride: any = null;
  isinvalidDate = false;

  showValueError = false;
  showInvalidCharError = false;
  showRequiredValueError = false;
  percentValidation = '/^(100|[0-9]|[0-9][0-9])(.[0-9]{0,2})?$/gm';
  validations: any;
  getOut = false;
  @ViewChild(MapAccountGridComponent) mapAccountGrid: MapAccountGridComponent;
  @ViewChild('fiscal') fiscal: FiscalPeriodComponent;
  @ViewChild('editForm', {static: true}) editForm: any;
  constructor(
    private bulkEditService: BulkeditService,
    private service: BaseService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private modalService: BentoModalConfirmationService,
    private cdr: ChangeDetectorRef,
    private _peerCheckService: PeerCheckServiceService
  ) {
    this.validations = {
      percentValidation: {
        pattern: 'Error: Percent can not be more than 100',
      },
    };
  }
  ngAfterViewInit(): void {
    if (this.getOut) {
      return;
    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    const obj = new GlacOverride();

    this.selectedAccountCodes = this.bulkEditService.getSelectedRecords();
    this.glacMappedOfficeName = this.bulkEditService.getglacMappedOfficeName();
    let requestFor = this.bulkEditService.getServiceFor();
    this.isOverride = requestFor == 'Override' ? true : false;

    if (this.isFAdmin && (this.selectedAccountCodes.length == 0 || this.glacMappedOfficeName.length == 0)) {
      this.router.navigate(['mappings/gl-account-codes']);
    }

    if (this.isTAdmin && (this.selectedAccountCodes.length == 0 || this.glacMappedOfficeName.length == 0)) {
      this.getOut = true;
      this.router.navigate(['mappings/gl-account-codes/firms/' + this.getfirmId]);
    }

    this.model = {
      allocationType: 'P',
      isDepartmentChecked: false,
      isOfficeChecked: false,
      isPmOfficeChecked: false,

      allocationValue: null,
    };
    if (!this.getOut) {
      this.headers = [
        {
          name: 'Department',
          index: 0,
          active: this.model.isDepartmentChecked ? 1 : 0,
        },
        {
          name: 'Action',
          index: 5,
          active: 1,
        },
        {
          name: 'Office',
          index: 1,
          active: this.model.isOfficeChecked ? 1 : 0,
        },
        {
          name: 'Mapped account name',
          index: 2,
          active: 1,
        },
        {
          name: 'Mapped office',
          index: 3,
          active: this.model.isPmOfficeChecked ? 1 : 0,
        },
        {
          name: 'Percent',
          index: 4,
          active: this.model.allocationType === 'P' ? 1 : 0,
        },
        {
          name: 'Dollars',
          index: 4,
          active: this.model.allocationType === 'D' ? 1 : 0,
        },
      ];

      this.selectedGlAcOverride = new GlacOverride();
      this.selectedGlAcOverride.override = new Override();
      obj.override = new Override();
      this.selectedGlAcOverride.override.reallocationType = this.model.allocationType;
      obj.override.reallocationType = this.model.allocationType;

      this.saveChoices = [
        {value: 'P', label: 'Percent'},
        {value: 'D', label: 'Amount'},
      ];

      let mapCategory = this.selectedAccountCodes.reduce(
        (r, i) => ((r[i.accountCategory] = r[i.accountCategory] || []).push(i), r),
        {}
      );
      let mapAccname = this.selectedAccountCodes.reduce((r, i) => ((r[i.pmCode] = r[i.pmCode] || []).push(i), r), {});
      let glAcCode: String[] = [...new Set(this.selectedAccountCodes.map((item) => item.firmCode))];
      let glAcCodeDescription = [
        ...new Set(this.selectedAccountCodes.map((item) => item.firmDescription + ' (' + item.firmCode + ')')),
      ];
      this.selectedGlAcOverride.accountCodes = glAcCode;
      obj.accountCodes = glAcCode;
      var length = glAcCode.length > 3 ? 3 : glAcCode.length;
      this.glAccCode =
        Object.keys(glAcCode).length == 1
          ? this.selectedAccountCodes[0].firmCode
          : glAcCode
              .filter((obj, idx) => idx < length)
              .toString()
              .replace(/,/g, ', ');
      this.accCategory =
        Object.keys(mapCategory).length == 1 ? this.selectedAccountCodes[0].accountCategory : 'Multiple';
      this.mappedAccName =
        Object.keys(mapAccname).length == 1
          ? this.selectedAccountCodes[0].pmCode === undefined ||
            this.selectedAccountCodes[0].pmCode === null ||
            this.selectedAccountCodes[0].pmCode === ''
            ? '--'
            : this.selectedAccountCodes[0].pmCode
          : 'Multiple';
      this.bulkEditTitle =
        this.selectedAccountCodes.length > 1
          ? 'Bulk edit ' +
            this.selectedAccountCodes.length +
            ' GL accounts: ' +
            glAcCodeDescription
              .filter((obj, idx) => idx < length)
              .toString()
              .replace(/,/g, ', ')
          : 'Edit GL account: ' + this.selectedAccountCodes &&
            this.selectedAccountCodes[0] &&
            this.selectedAccountCodes[0].firmDescription + ' (' + this.selectedAccountCodes[0].firmCode + ')';

      if (this.isOverride) this.fetchOverrideDetails(this.getfirmId, this.glAccCode);

      this.fetchDepartmentDetails(this.getfirmId);
      this.fetchOfficeDetails(this.getfirmId);
      this.fetchMappedOficeDetails();
      this.onMapAccountChange();
      this.verifySelection();
    }
    var option = new Detail();
    let pmOfcCode = this.glacMappedOfficeName.find((x) => x.description == this.selectedAccountCodes[0].pmCode);
    if (
      this.selectedAccountCodes &&
      this.selectedAccountCodes[0] &&
      this.selectedAccountCodes[0].pmCode != '' &&
      pmOfcCode
    ) {
      option.mappedAccountId = pmOfcCode.code;
    }

    if (this.model.allocationType == 'P') {
      option.amount =
        obj && obj.override && obj.override.details && obj.override.details.length == 0
          ? this.selectedGlAcOverride.override.accountTotalAmount
          : null;
    } else {
      option.amount = null;
    }
    if (obj && obj.override) {
      obj.override.details.push(option);
      this.initialOverride = obj;
      this._peerCheckService.setSelectedGlAcOverrideSubject(obj);
    }
  }

  saveCurrentSelection(selection: any) {
    //this.selectedGlAcOverride.override.details = JSON.parse(selection);
    this.verifySelection();
  }

  updateFiscalDateRange(selection) {
    var data = JSON.parse(selection);
    this.selectedGlAcOverride.override.startPeriod = data.from_date;
    this.selectedGlAcOverride.override.endPeriod = data.to_date;
    this.verifySelection();
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  generateRequestbody() {
    let selection = JSON.parse(JSON.stringify(this.selectedGlAcOverride));
    //selection.override.accountTotalAmount = this.model.allocationValue;
    selection.override.details.forEach((obj) => {
      if (obj.mappedOfficeId === 'FTE ALLOCATION') {
        obj.mappedOfficeId = null;
        obj.fteAllocation = true;
      }
      if (obj.departmentObj) {
        delete obj['departmentObj'];
      }
      obj.amount = parseFloat(obj.amount);
    });

    return selection;
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  public get isFAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.FAdmin;
  }

  saveGLAccOverrideDetails() {
    this.isDataLoading = true;
    const requestBody = this.generateRequestbody();
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/mapping/glaccount/override/' + this.getfirmId, requestBody)
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.alerts.push({
            type: 'success',
            msg: 'Data saved successfully.',
            timeout: 2500,
            closeable: true,
          });
          if (this.isTAdmin) {
            this.router.navigate(['mappings/gl-account-codes/firms/' + this.getfirmId]);
          } else {
            this.router.navigate(['mappings/gl-account-codes']);
          }
        },
        (error) => {
          this.errorMessage = error.error;
          var msg = '';
          if (this.errorMessage.hasOwnProperty('message')) {
            msg = this.errorMessage.message;
          } else {
            msg = 'Something went wrong, please try again.';
          }
          this.isDataLoading = false;
          this.displayError(msg);
        }
      );
  }

  onBentoAlertOccured(alerts: BentoAlertItemOptions[]) {
    this.alerts = [...this.alerts, ...alerts];
  }

  fetchOverrideDetails(firmID, glAccCode) {
    this.isDataLoading = true;
    this.service
      .get(
        environment.FIClientBaseEndpoint + 'v1/mapping/glaccount/override/firmId/' + firmID + '/accountCode/',
        glAccCode
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.overrides = result;

          this.overrides.forEach((element) => {
            element &&
              element.details &&
              element.details.filter((obj) => {
                if (obj.fteAllocation) {
                  obj.mappedOfficeId = 'FTE ALLOCATION';
                }
              });
          });
        },

        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  fetchDepartmentDetails(firmId) {
    this.isDataLoading = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/department/', firmId).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.glacDepartment = result;
      },

      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  fetchOfficeDetails(firmId) {
    this.isDataLoading = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/gloffice/', firmId).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.glacOffice = result;
      },

      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  fetchMappedOficeDetails() {
    this.isDataLoading = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/office/pm/assigned/', this.getfirmId).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.glacMappedOffice = result;
      },

      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  onAllocationTypeChange(requestFromOverride = false) {
    if (!requestFromOverride) {
      this.selectedGlAcOverride.override.accountTotalAmount = null;
    }
    this._peerCheckService.getSelectedGlAcOverrideSubject().subscribe((data) => {
      this.defaultOverride = data;
    });
    if (!requestFromOverride) {
      if (!this.fiscal.prevSelectedOverride) {
        this.mapAccountGrid.selectedDetails = [];
        this.mapAccountGrid.addCode();
        this.model.isDepartmentChecked = false;
        this.model.isOfficeChecked = false;
        this.model.isPmOfficeChecked = false;
        this.selectedGlAcOverride.override.details = this.mapAccountGrid.selectedDetails;
      } else {
        if (this.defaultOverride.override.reallocationType == this.model.allocationType) {
          this.model.isOfficeChecked = this.defaultOverride.override.details[0]['officeCode'] ? true : false;
          this.model.isDepartmentChecked = this.defaultOverride.override.details[0]['departmentCode'] ? true : false;
          this.model.isPmOfficeChecked = this.defaultOverride.override.details[0]['mappedOfficeId'] ? true : false;
          this.selectedGlAcOverride.override.details = this.defaultOverride.override.details;
          this.selectedGlAcOverride.override.accountTotalAmount = this.defaultOverride.override.accountTotalAmount;
        } else {
          this.model.isDepartmentChecked = false;
          this.model.isOfficeChecked = false;
          this.model.isPmOfficeChecked = false;
          this.selectedGlAcOverride.override.details = this.initialOverride.override.details;
          this.selectedGlAcOverride.override.accountTotalAmount = this.initialOverride.override.accountTotalAmount;
        }
      }
      this.onMapAccountChange();
    }

    this.selectedGlAcOverride.override.reallocationType = this.model.allocationType;
    if (this.model.allocationType === 'D') {
      this.headers.forEach((obj) => {
        if (obj.name == 'Department' || obj.name == 'Office' || obj.name == 'Dollars') {
          obj.active = 1;
        } else if (obj.name == 'Percent') {
          obj.active = 0;
        }
      });
    } else {
      this.headers.forEach((obj) => {
        if (obj.name == 'Dollars') {
          obj.active = 0;
        } else if (obj.name == 'Percent') {
          obj.active = 1;
        }
      });
      this.onMapAccountChange();
    }
    this.getAvailableCol();
    this.verifySelection();
  }

  onMapAccountChange() {
    this.headers.forEach((obj) => {
      this.selectedGlAcOverride &&
        this.selectedGlAcOverride.override &&
        this.selectedGlAcOverride.override.details &&
        this.selectedGlAcOverride.override.details.forEach((item) => {
          if (obj.name == 'Department') {
            obj.active = this.model.allocationType == 'D' || this.model.isDepartmentChecked ? 1 : 0;
            item.departmentCode =
              this.model.allocationType == 'D' || this.model.isDepartmentChecked ? item.departmentCode : null;
          } else if (obj.name == 'Office') {
            obj.active = this.model.allocationType == 'D' || this.model.isOfficeChecked ? 1 : 0;
            item.officeCode = this.model.allocationType == 'D' || this.model.isOfficeChecked ? item.officeCode : null;
          } else if (obj.name == 'Mapped office') {
            obj.active = this.model.isPmOfficeChecked ? 1 : 0;
            item.mappedOfficeId = this.model.isPmOfficeChecked ? item.mappedOfficeId : null;
          }
        });
    });
    this.verifySelection();
    this.getAvailableCol();
  }

  getAvailableCol() {
    this.avaliableCols = this.headers.filter((x) => x.active == 1).length;
  }

  onBlur() {
    const value = this.selectedGlAcOverride.override.accountTotalAmount + '';
    if (value.indexOf('.') == 0 && value != '.') {
      const val1 = '0' + value;
      this.selectedGlAcOverride.override.accountTotalAmount = parseFloat(val1);
    }
  }

  verifyDateRangeInvalidPattern(isValid = false) {
    if (!isValid) {
      this.readyToSubmit = false;
      this.isinvalidDate = true;
    } else {
      this.isinvalidDate = false;
    }
  }

  verifySelection() {
    var details = this.selectedGlAcOverride.override.details;

    this.readyToSubmit = true;
    if (this.isinvalidDate) {
      this.readyToSubmit = false;
      return;
    }

    if (!this.selectedGlAcOverride.override.startPeriod) {
      this.readyToSubmit = false;
      return;
    }
    if (
      (this.selectedGlAcOverride.override.startPeriod == null &&
        this.selectedGlAcOverride.override.endPeriod != null) ||
      (Number(this.selectedGlAcOverride.override.startPeriod) > Number(this.selectedGlAcOverride.override.endPeriod) &&
        this.selectedGlAcOverride.override.endPeriod != null)
    ) {
      this.readyToSubmit = false;
    }
    // if (!this.selectedGlAcOverride.override.startPeriod || !this.selectedGlAcOverride.override.endPeriod) {
    //   this.readyToSubmit = false;
    // }

    if (
      this.selectedGlAcOverride.override.startPeriod &&
      this.selectedGlAcOverride.override.endPeriod &&
      Number(this.selectedGlAcOverride.override.startPeriod) > Number(this.selectedGlAcOverride.override.endPeriod)
    ) {
      this.readyToSubmit = false;
    } else if (
      this.selectedGlAcOverride.override.startPeriod &&
      Number(this.selectedGlAcOverride.override.startPeriod) < 200001
    ) {
      this.readyToSubmit = false;
    } else if (
      this.selectedGlAcOverride.override.endPeriod &&
      Number(this.selectedGlAcOverride.override.endPeriod) > 209912
    ) {
      this.readyToSubmit = false;
    }
    if (details && details.length == 0) {
      this.readyToSubmit = false;
    }
    if (
      details &&
      details.findIndex((item) => {
        return (
          this.model.allocationType == 'D' &&
          (item.departmentCode == null ||
            item.officeCode == null ||
            item.amount == 0 ||
            (this.model.isPmOfficeChecked && item.mappedOfficeId == null))
        );
      }) >= 0
    ) {
      this.readyToSubmit = false;
    }
    if (
      details &&
      details.findIndex(
        (item) =>
          (this.model.allocationType == 'P' && item.amount == 0) ||
          (this.model.isDepartmentChecked && item.departmentCode == null) ||
          (this.model.isOfficeChecked && item.officeCode == null) ||
          (this.model.isPmOfficeChecked && item.mappedOfficeId == null)
      ) >= 0
    ) {
      this.readyToSubmit = false;
    }

    if (details && details.findIndex((item) => item.mappedAccountId == null) >= 0) {
      this.readyToSubmit = false;
    }

    if (this.isOverride) {
      let selection = this.overrides.find((obj) => {
        return obj.id == this.selectedOverride;
      });
      if (selection != undefined && JSON.stringify(selection) == JSON.stringify(this.selectedGlAcOverride.override)) {
        this.readyToSubmit = false;
      }
    }
    if (this.readyToSubmit) {
      let total = 0;
      for (let i = 0; i < details.length; i++) {
        total += parseFloat(details[i].amount + '');
      }
      if (
        parseFloat(total + '').toFixed(2) ==
        parseFloat(this.selectedGlAcOverride.override.accountTotalAmount + '').toFixed(2)
      ) {
        this.readyToSubmit = true;
      } else {
        this.readyToSubmit = false;
      }
    }
  }

  closeBulkEdit() {
    const unsavedData = 'There are unsaved changes. Would you like to save them?';
    this.displayError(unsavedData, 'SAVE', "DON'T SAVE");
  }

  displayError(message, confirmMessage = 'OK', cancelMessage = 'CANCEL') {
    this.modalService
      .open(message, {
        titleText: this.isOverride ? 'GL Account Code Override' : 'Bulk Edit',
        confirmButtonText: confirmMessage,
        cancelButtonText: cancelMessage,
        closeButtonText: 'Close',
        buttonType: 'primary',
      })
      .result.then(
        (result: BentoModalConfirmationCloseReason) => {
          if (confirmMessage == 'SAVE' && result == 'Confirm' && this.readyToSubmit) {
            this.saveGLAccOverrideDetails();
          } else if (confirmMessage == 'SAVE' && result == 'Confirm' && !this.readyToSubmit) {
            this.displayError('Please make sure all fields are populated.');
          }
          if (cancelMessage == "DON'T SAVE" && result == 'Cancel') {
            this.router.navigate(['mappings/gl-account-codes/firms/' + this.getfirmId]);
          }

          if (confirmMessage == 'YES' && result == 'Confirm') {
            this.deleteOverride(this.getfirmId);
          }
        },
        (reason) => {}
      );
  }

  saveOverrideDetails() {
    if (this.readyToSubmit) {
      this.saveGLAccOverrideDetails();
    } else {
      this.displayError('Please make sure all fields are populated.');
    }
  }

  valueStyleObj() {
    if (this.showValueError) {
      return {'border-color': '#8f221a', 'box-shadow': '0 0 0 1px #8f221a'};
    }
    return {};
  }

  onValueChange() {
    const value = this.selectedGlAcOverride.override.accountTotalAmount + '';
    if (value) {
      if (value.indexOf('.') != -1) {
        const valarr = value.split('.');
        if (valarr[1].length != 2) {
          this.showValueError = true;
        } else {
          this.showValueError = false;
        }
      } else {
        this.showValueError = false;
      }
      if (this.model.allocationType == 'P') {
        if (this.selectedGlAcOverride.override.accountTotalAmount > 100) {
          this.showPercentError = true;
          this.showValueError = false;
        } else {
          this.showPercentError = false;
        }
      }
    }

    this.verifySelection();
  }

  onOverridePeriodChanged(overrideId) {
    this.showValueError = false;
    this.showPercentError = false;
    let obj = new GlacOverride();
    this.selectedOverride = overrideId;
    if (overrideId == null) {
      this.model = {
        allocationType: 'P',
        isDepartmentChecked: false,
        isOfficeChecked: false,
        isPmOfficeChecked: false,

        allocationValue: null,
      };
      this.selectedGlAcOverride = new GlacOverride();
      this.selectedGlAcOverride.override = new Override();
      this.selectedGlAcOverride.override.reallocationType = this.model.allocationType;

      obj.override = new Override();
      obj.override.reallocationType = this.model.allocationType;
      var option = new Detail();
      let pmOfcCode = this.glacMappedOfficeName.find((x) => x.description == this.selectedAccountCodes[0].pmCode);
      if (pmOfcCode != undefined) {
        option.mappedAccountId = pmOfcCode.code;
      }
      //option.amount = 100;
      this.selectedGlAcOverride.override.details.push(option);
      obj.override.details.push(option);
    } else {
      let override = this.overrides.find((obj) => {
        return obj.id == overrideId;
      });
      if (override != undefined) {
        this.selectedGlAcOverride = new GlacOverride();
        obj = new GlacOverride();
        this.selectedGlAcOverride.override = JSON.parse(JSON.stringify(override));
        obj.override = JSON.parse(JSON.stringify(override));

        let departmentSelectionflag =
          this.selectedGlAcOverride &&
          this.selectedGlAcOverride.override &&
          this.selectedGlAcOverride.override.details &&
          this.selectedGlAcOverride.override.details.find((obj) => {
            return obj.departmentCode == null;
          });
        let officeSelectionflag =
          this.selectedGlAcOverride &&
          this.selectedGlAcOverride.override &&
          this.selectedGlAcOverride.override.details &&
          this.selectedGlAcOverride.override.details.find((obj) => {
            return obj.officeCode == null;
          });
        let pmOfficeSelectionflag =
          this.selectedGlAcOverride &&
          this.selectedGlAcOverride.override &&
          this.selectedGlAcOverride.override.details &&
          this.selectedGlAcOverride.override.details.find((obj) => {
            return obj.mappedOfficeId == null && !obj.fteAllocation;
          });

        this.model = {
          allocationType: this.selectedGlAcOverride.override.reallocationType,
          isDepartmentChecked: departmentSelectionflag == undefined,
          isOfficeChecked: officeSelectionflag == undefined,
          isPmOfficeChecked: pmOfficeSelectionflag == undefined,

          allocationValue: null,
        };
      }
    }
    this.selectedGlAcOverride.accountCodes.push(this.glAccCode);
    obj.accountCodes.push(this.glAccCode);
    this._peerCheckService.setSelectedGlAcOverrideSubject(obj);
    this.onMapAccountChange();
    this.onAllocationTypeChange(true);
  }

  deleteSelectedOverride() {
    const message = 'Are you sure you want to delete the current override?';
    this.displayError(message, 'YES', 'NO');
  }

  deleteOverride(firmId) {
    this.isDataLoading = true;
    this.service
      .delete(
        environment.FIClientBaseEndpoint + 'v1/mapping/glaccount/override/firmId/' + firmId + '/overrideId/',
        this.selectedOverride
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.overrides = [];

          this.fetchOverrideDetails(this.getfirmId, this.glAccCode);

          this.selectedOverride = null;
          this.onOverridePeriodChanged(this.selectedOverride);

          this.fiscal.onResetFiscal();
        },

        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
}
