<div class="staffing-ratio-page" [bentoBusyLoader]="isDataLoading" role="main">
  <bento-alert [items]="alerts"></bento-alert>
  <header class="Main-header"><h1 class="Main-heading">PMM</h1></header>
  <div class="bento-tabset tabs-top pading-note">
    <ul ngbNav #nav="ngbNav" class="nav nav-tabs" ngPreserveWhitespaces [animation]="false">
      <li ngbNavItem="Peer Monitor Manager">
        <a ngbNavLink>Peer Monitor Manager</a>
        <ng-template ngbNavContent>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Release</th>
                <th scope="col" style="width: 120px">Version</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="form-group">
                    <a [routerLink]="['.']" (click)="launchPMM()">Peer Monitor Manager</a>
                  </div>
                </td>

                <td class="MappedAccountGrid-cell" style="width: 120px">
                  <div class="form-group">{{pmmVersion}}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li ngbNavItem="One Offs" *ngIf="isTAdmin || isFAdmin">
        <a ngbNavLink>Additional Downloads</a>
        <ng-template ngbNavContent>
          <app-one-offs></app-one-offs>
        </ng-template>
      </li>
      <li ngbNavItem="FAQs">
        <a ngbNavLink>FAQs</a>
        <ng-template ngbNavContent>
          <app-pmm-faq></app-pmm-faq>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="tab-content"></div>
  </div>
</div>
