<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main timeKeepers" role="main">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <h1 class="Main-heading">Timekeepers</h1>
      </div>
      <div class="col">
        <app-action-dropdown
          #actionButton
          [actionDropdownItems]="actionDropdownItems"
          (dropDownSelectChange)="dropDownOnSelectChange($event)"
        >
        </app-action-dropdown>
      </div>
    </div>
    <div class="note">
      <div class="row">
        <div class="col mapping-details">
          <app-mapping-firms-details #mappingFirmDetailsComponent></app-mapping-firms-details>
        </div>
        <div class="col">
          <div role="alert" aria-live="polite" class="notify">
            <p class="note-info">
              Note - Press <span class="shortcut">Alt + Shift + Down Arrow </span> to see filter options for selected
              column on the grid.
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="Main-body FilterPanel FilterPanel--toggle FilterPanel--toggle--Modal">
    <aside
      class="FilterPanel-control FilterPanel-control-scroll FilterPanel-control--Modal"
      [ngClass]="{'is-open': isFilterOpen == true}"
    >
      <form class="FilterPanel-control--Timekeeper" name="timekeeperFilter" #timekeeperFilter="ngForm" bfmForm>
        <header class="FilterPanel-header">
          <h2 class="h3 FilterPanel-heading">Filter</h2>
          <button
            type="button"
            class="btn btn-icon"
            [attr.aria-label]="isFilterOpen ? 'Close filter panel' : 'Open filter panel'"
            [ngbTooltip]="isFilterOpen ? 'Close filter panel' : 'Open filter panel'"
            placement="left"
            (click)="onClickFilter(true)"
          >
            <i [ngClass]="'bento-icon-close-x'" aria-hidden="true"></i>
          </button>
        </header>

        <div class="FilterPanel-body" id="FilterPanel-body-scroll-tk" [bentoBusyLoader]="isMainBusyLoaderBusy">
          <ul class="FilterPanel-list">
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display"></div>
              <div class="FilterPanel-input">
                <app-fiscal-period
                  #fiscalPeriodComponent
                  [fiscalPeriodModel]="fiscalPeriodModel"
                  [fiscalDateRange]="fiscalDateRange"
                  (selectionChanged)="onFiscalPeriodChange($event)"
                  (fiscalPeriodLoaded)="onFiscalPeriodChange($event, true)"
                  (bentoAlertOccured)="onBentoAlertOccured($event)"
                  [requestFrom]="'Timekeeper'"
                ></app-fiscal-period>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  id="isFteVisible"
                  name="isFteVisible"
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isFteVisible"
                ></bento-checkbox>
                <label for="isFteVisible" class="sr-only">Display FTE column</label>
              </div>
              <div class="FilterPanel-input FormRange">
                <div class="FormRange-group">
                  <div class="FormRange-item FormRange-item--select">
                    <label for="fteFrom">FTE from</label>
                    <input
                      min="0.0"
                      max="1.0"
                      step="0.1"
                      type="number"
                      name="fteFrom"
                      id="fteFrom"
                      #fteFrom
                      class="form-control"
                      bfmField
                      bfmLabel="fteFrom"
                      [(ngModel)]="tkHistoryRecordRequest.fteFrom"
                      [validations]="validations.tkFilterFte"
                      [pattern]="tkFilterFteVal"
                      (keyup)="validateNumber(fteFrom)"
                    />
                  </div>
                  <div class="FormRange-item FormRange-item--mediator">thru.</div>
                  <div class="FormRange-item FormRange-item--select">
                    <label for="fteTo">FTE to</label>
                    <input
                      type="number"
                      name="fteTo"
                      id="fteTo"
                      #fteTo
                      min="0.0"
                      max="1.0"
                      step="0.1"
                      class="form-control"
                      bfmField
                      bfmLabel="fteTo"
                      [(ngModel)]="tkHistoryRecordRequest.fteTo"
                      [validations]="validations.tkFilterFte"
                      [pattern]="tkFilterFteVal"
                      (keyup)="validateNumber(fteTo)"
                    />
                  </div>
                </div>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isNameVisible"
                  id="isNameVisible"
                  name="isNameVisible"
                >
                </bento-checkbox>
                <label for="isNameVisible" class="sr-only">Display name column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="names">Name</label>
                <bento-multiselect-overlay
                  name="names"
                  id="names"
                  class="FormMultiselect"
                  [(ngModel)]="tkHistoryFilterData.name"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedNamesText"
                  (ngModelChange)="onNamesSelected($event)"
                  [itemsObservable]="tkFilterData.nameStream"
                  [listItemTemplate]="customListNameListItemTemplate"
                  [searchCompare]="searchCompare"
                  ngDefaultControl
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListNameListItemTemplate let-item="item" let-index="index">
                  <div>
                    {{ item }}
                  </div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isTKINITVisible"
                  id="isTkinitChecked"
                  name="isTkinitChecked"
                ></bento-checkbox>
                <label for="isTkinitChecked" class="sr-only">Display TKINIT column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="tkinits">TKINIT</label>
                <bento-multiselect-overlay
                  ngDefaultControl
                  name="tkinits"
                  id="tkinits"
                  class="FormMultiselect"
                  [(ngModel)]="tkHistoryFilterData.tkId"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedTkinitsText"
                  (ngModelChange)="onTkinitItemsSelected($event)"
                  [itemsObservable]="tkFilterData.tkinitStream"
                  [listItemTemplate]="customListTkinitItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListTkinitItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isTitleVisible"
                  id="isTitleVisible"
                  name="isTitleVisible"
                ></bento-checkbox>
                <label for="isTitleVisible" class="sr-only">Display title column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="title">Title</label>
                <bento-multiselect-overlay
                  name="title"
                  id="title"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.firmTitle"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedFirmTitleText"
                  (ngModelChange)="onTitleSelected($event)"
                  [itemsObservable]="tkFilterData.firmTitleStream"
                  [listItemTemplate]="customListTitleItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListTitleItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isOfficeVisible"
                  id="isOfficeVisible"
                  name="isOfficeVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isOfficeVisible" class="sr-only">Display office column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="office">Office</label>
                <bento-multiselect-overlay
                  name="office"
                  id="office"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.firmOffice"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedFirmOfficeText"
                  (ngModelChange)="onOfficeSelected($event)"
                  [itemsObservable]="tkFilterData.firmOfficeStream"
                  [listItemTemplate]="customListOfficeItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListOfficeItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isDeptVisible"
                  id="isDeptVisible"
                  name="isDeptVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isDeptVisible" class="sr-only">Display department column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="department">Department</label>
                <bento-multiselect-overlay
                  name="department"
                  id="department"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.department"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedDeptText"
                  [overlayMaxHeight]="400"
                  (ngModelChange)="onDeptSelected($event)"
                  [itemsObservable]="tkFilterData.departmentStream"
                  [listItemTemplate]="customListDeptItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListDeptItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isSectionVisible"
                  id="isSectionVisible"
                  name="isSectionVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isSectionVisible" class="sr-only">Display section column</label>
              </div>
              <div class="FilterPanel-input">
                <!-- TODO: Placeholder to hook with services API -->
                <label for="section">Section</label>
                <select id="section" bentoSelect>
                  <option>Item 1</option>
                  <option>Item 2</option>
                  <option>Item 3</option>
                  <option>Item 4</option>
                </select>
              </div>
            </li>
            <li class="FilterPanel-listItem" *ngIf="fmsType != '4' && fmsType != '5' && fmsType != '3'">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isUdfVisible"
                  id="isUdfVisible"
                  name="isUdfVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isUdfVisible" class="sr-only">Display UDF column</label>
              </div>
              <div class="FilterPanel-input">
                <!-- TODO: Placeholder to hook with services API -->
                <label for="UDF">UDF</label>
                <select id="UDF" bentoSelect>
                  <option>Item 1</option>
                  <option>Item 2</option>
                  <option>Item 3</option>
                  <option>Item 4</option>
                </select>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isPeerOfficeVisible"
                  id="isPeerOfficeVisible"
                  name="isPeerOfficeVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isPeerOfficeVisible" class="sr-only">Display peer office column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="peerOffice">Peer office</label>
                <bento-multiselect-overlay
                  name="peerOffice"
                  id="peerOffice"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.peerOffice"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPeerOfficeText"
                  (ngModelChange)="onPeerOfficeSelected($event)"
                  [itemsObservable]="tkFilterData.peerOfficeStream"
                  [listItemTemplate]="customListPeerOfficeItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListPeerOfficeItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isPeerPracticeVisible"
                  id="isPeerPracticeVisible"
                  name="isPeerPracticeVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isPeerPracticeVisible" class="sr-only">Display practice column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="peerPractice">Peer practice</label>
                <bento-multiselect-overlay
                  name="peerPractice"
                  id="peerPractice"
                  #peerPractice
                  class="FormMultiselect"
                  [(ngModel)]="tkHistoryFilterData.peerPractice"
                  ngDefaultControl
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPeerPracticeText"
                  (ngModelChange)="onPeerPracticeSelected($event)"
                  [itemsObservable]="tkFilterData.peerPracticeStream"
                  [listItemTemplate]="customListPeerPracticeItemTemplate"
                  [searchCompare]="searchCompare"
                  ngDefaultControl
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListPeerPracticeItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isPeerTitleVisible"
                  id="isPeerTitleVisible"
                  name="isPeerTitleVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isPeerTitleVisible" class="sr-only">Display peer title column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="peerTitle">Peer title</label>
                <bento-multiselect-overlay
                  id="peerTitle"
                  name="peerTitle"
                  class="FormMultiselect"
                  [(ngModel)]="tkHistoryFilterData.peerTitle"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPeerTitleText"
                  (ngModelChange)="onPeerTitleSelected($event)"
                  [itemsObservable]="tkFilterData.peerTitleStream"
                  [listItemTemplate]="customListPeerTitleItemTemplate"
                  [searchCompare]="searchCompare"
                  ngDefaultControl
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListPeerTitleItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isExpYearsVisible"
                  id="isExpYearsVisible"
                  name="isExpYearsVisible"
                  ngDefaultControl
                ></bento-checkbox>
                <label for="isExpYearsVisible" class="sr-only">Display experience years column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="experienceYear">Experience years</label>
                <input
                  type="number"
                  name="experienceYear"
                  id="experienceYear"
                  #experienceYear
                  class="form-control"
                  bfmField
                  bfmLabel="expYears"
                  [(ngModel)]="tkHistoryFilterData.experienceYear"
                  (ngModelChange)="onExpYearSelected($event)"
                  ngDefaultControl
                  (keyup)="validateNumber(experienceYear)"
                />
              </div>
            </li>
          </ul>
        </div>
        <footer class="FilterPanel-footer--Modal">
          <button type="button" role="button" (click)="resetTKFilter(true)" class="btn btn-outline-secondary">
            <span class="btn-text">Clear</span>
          </button>
          <button
            type="submit"
            role="button"
            [disabled]="isFilterButtonDisabled"
            (click)="retrieveTKHistoryRecords(true, null, null, true)"
            class="btn btn-primary"
          >
            <span class="btn-text">Filter</span>
          </button>
        </footer>
        <!-- <div class="form-group Form-group footer">
          <div class="bento-form-buttons divClass">
            <button type="button" class="btn btn-outline-secondary" (click)="resetTKFilter(true)">
              <span class="btn-text">Clear</span>
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="isFilterButtonDisabled"
              (click)="retrieveTKHistoryRecords(true, null, null, true)"
            >
              <span class="btn-text">Filter</span>
            </button>
          </div>
        </div> -->
      </form>
    </aside>

    <div class="FilterPanel-data">
      <div class="row">
        <div class="col Filter-buttons">
          <button
            class="btn btn-outline-secondary filter-common-btn"
            role="button"
            (click)="isFilterOpen = !isFilterOpen"
          >
            Filter
          </button>
          <button class="btn-clear-filter" role="button" [disabled]="!enableClearFilter" (click)="resetTKFilter(true)">
            Clear filters
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-icon btn-sm"
            [attr.aria-label]="isColumnFreezed ? columnUnfreezeMessage : columnFreezeMessage"
            [ngbTooltip]="isColumnFreezed ? columnUnfreezeMessage : columnFreezeMessage"
            placement="right"
            (click)="isColumnFreezed = !isColumnFreezed"
          >
            <i [ngClass]="isColumnFreezed ? 'bento-icon-lock' : 'bento-icon-unlocked'" aria-hidden="false"></i>
          </button>
        </div>
        <div class="col">
          <h2 class="h4 bulkEditControlHeader" id="bulkEditControls">Bulk edit</h2>
          <form
            bfmForm
            name="timekeeperEditField"
            class="TimekeeperOverrides"
            aria-labelledby="bulkEditControls"
            #timekeeperEditField="ngForm"
          >
            <span class="label-required-summary" id="requiredField">Required field</span>
            <div class="TimekeeperOverrides-group">
              <div class="Form-combobox TimekeeperOverrides-item TimekeeperOverrides-item--select">
                <label for="editField" class="bento-label-required">Edit field</label>
                <bento-combobox
                  id="editField"
                  name="editField"
                  class="form-control"
                  bfmField
                  bfmLabel="Edit field"
                  aria-describedby="requiredField"
                  (ngModelChange)="onChangeEditField($event)"
                  [(ngModel)]="tkEditFieldData.editField"
                  [itemsObservable]="tkEditFieldData.editFieldDataStream"
                  [options]="comboboxOptions"
                  [placeholder]="false"
                  [disabled]="!isRecordSelected"
                  [revertOnInvalidInput]="true"
                  ngDefaultControl
                  required
                ></bento-combobox>
              </div>
              <div
                class="Form-combobox TimekeeperOverrides-item TimekeeperOverrides-item--select"
                *ngIf="tkEditFieldData.editField == 'Experience year'"
              >
                <label for="newValueExpYears" class="bento-label-required">New value</label>
                <input
                  type="number"
                  name="newValueExpYears"
                  id="newValueExpYears"
                  #newValueExpYears
                  class="form-control"
                  [(ngModel)]="tkEditFieldData.newValue"
                  [disabled]="!isRecordSelected"
                  bfmField
                  bfmLabel="New value"
                  required
                  aria-describedby="requiredField"
                  (keyup)="validateNumber(newValueExpYears)"
                />
              </div>
              <div
                class="Form-combobox TimekeeperOverrides-item TimekeeperOverrides-item--select"
                *ngIf="tkEditFieldData.editField == 'FTE'"
              >
                <label for="newValueFTE" class="bento-label-required">New value</label>
                <input
                  type="number"
                  min="0.0"
                  max="1.0"
                  step="0.1"
                  name="newValueFTE"
                  id="newValueFTE"
                  #newValueFTE
                  class="form-control"
                  bfmField
                  bfmLabel="New value"
                  [(ngModel)]="tkEditFieldData.newValue"
                  [disabled]="!isRecordSelected"
                  [validations]="validations.tkFilterFte"
                  [pattern]="tkFilterFteVal"
                  required
                  (keyup)="validateNumber(newValueFTE)"
                  aria-describedby="requiredField"
                />
              </div>
              <div
                class="Form-combobox TimekeeperOverrides-item TimekeeperOverrides-item--select"
                *ngIf="tkEditFieldData.editField != 'Experience year' && tkEditFieldData.editField != 'FTE'"
              >
                <label for="newValue" class="bento-label-required">New value</label>
                <bento-combobox
                  #newValue
                  ngDefaultControl
                  id="newValue"
                  name="newValue"
                  class="form-control"
                  bfmField
                  bfmLabel="New value"
                  required
                  aria-describedby="requiredField"
                  [(ngModel)]="tkEditFieldData.newValue"
                  [itemsObservable]="tkEditFieldData.editFieldNewValueStream"
                  [options]="comboboxOptions"
                  [placeholder]="false"
                  [disabled]="!isRecordSelected"
                  [revertOnInvalidInput]="true"
                ></bento-combobox>
              </div>
              <div class="TimekeeperOverrides-item TimekeeperOverrides-item--select">
                <label for="duration" class="bento-label-required">Duration</label>
                <bento-combobox
                  id="duration"
                  #duration
                  name="duration"
                  class="form-control"
                  [(ngModel)]="tkEditFieldData.duration"
                  [itemsObservable]="tkEditFieldData.editFieldDurationStream"
                  [options]="comboboxOptions"
                  [placeholder]="false"
                  [disabled]="!isRecordSelected"
                  [revertOnInvalidInput]="true"
                  bfmField
                  bfmLabel="Duration"
                  required
                  aria-describedby="requiredField"
                  ngDefaultControl
                ></bento-combobox>
              </div>
              <div class="TimekeeperOverrides-item TimekeeperOverrides-item--action">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  [attr.aria-disabled]="!isRecordSelected || timekeeperEditField.invalid"
                  [attr.aria-labelledby]="
                    !isRecordSelected || timekeeperEditField.invalid ? 'aria-required-apply' : null
                  "
                  (click)="applyTKEdit()"
                >
                  <span class="btn-text">Apply</span>
                  <span
                    *ngIf="!isRecordSelected || timekeeperEditField.invalid"
                    class="sr-only"
                    id="aria-required-apply"
                    >Fill required fields to Apply</span
                  >
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="FilterPanel-dataGrid">
        <wj-flex-grid
          #flexGrid
          class="DataGrid"
          [frozenColumns]="isColumnFreezed ? numberOfCoulmnToFreeze : 0"
          [itemsSource]="gridData"
          [stickyHeaders]="true"
          [showSort]="true"
          [showMarquee]="true"
          [readOnly]="true"
          [allowDragging]="false"
          [autoRowHeights]="true"
          [bentoBusyLoader]="isDataLoading"
          (initialized)="gridInitialized(flexGrid)"
          (loadedRows)="onPageCheckBoxChange(flexGrid)"
          (updatedView)="updatedView(flexGrid)"
          (sortedColumn)="sortGrid($event.getColumn().binding, $event.getColumn())"
        >
          <wj-flex-grid-filter
            #filter
            (initialized)="initFilter()"
            (filterChanged)="onFilterChange()"
          ></wj-flex-grid-filter>
          <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'" let-col="col">
            <ng-container *ngIf="col.index === 0">
              <bento-checkbox
                id="selectAll"
                [ngModel]="isAllSelected()"
                (change)="onHeaderCheckBoxChange()"
                [indeterminate]="isIndeterminate()"
              >
              </bento-checkbox>
              <label for="selectAll" class="sr-only">Select all</label>
            </ng-container>
          </ng-template>
          <ng-template wjFlexGridCellTemplate [cellType]="'RowHeader'" let-col="col" let-row="row" let-item="item">
            <ng-container *ngIf="col.index === 0">
              <bento-checkbox
                id="selectRow-{{ row.index }}"
                [ngModel]="item.selected"
                (change)="item.disabled || (item.selected = !item.selected); onCheckBoxChange()"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}" class="sr-only">{{ item.name }}</label>
            </ng-container>
          </ng-template>
          <wj-flex-grid-column
            [header]="'Period'"
            [binding]="'period'"
            [visible]="true"
            [minWidth]="40"
            [width]="150"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              {{ item.period }}
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Last name'"
            [binding]="'lastName'"
            [visible]="tkFilterData.isNameVisible && fmsType != '4' && fmsType != '5' && fmsType != '3'"
            [minWidth]="50"
            [width]="170"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'First name'"
            [binding]="'firstName'"
            [visible]="tkFilterData.isNameVisible && fmsType != '4' && fmsType != '5' && fmsType != '3'"
            [minWidth]="50"
            [width]="170"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Name'"
            [binding]="'firstName'"
            [visible]="tkFilterData.isNameVisible && (fmsType == '4' || fmsType == '5' || fmsType == '3')"
            [minWidth]="50"
            [width]="170"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'TKINIT'"
            [binding]="'tkId'"
            [visible]="tkFilterData.isTKINITVisible"
            [minWidth]="40"
            [width]="150"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'FTE'"
            [binding]="'fte'"
            [visible]="tkFilterData.isFteVisible"
            [minWidth]="30"
            [width]="130"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <label for="fteValue-{{ row.index }}" class="sr-only">FTE</label>
              <input
                class="form-control"
                id="fteValue-{{ row.index }}"
                name="fteValue-{{ row.index }}"
                [(ngModel)]="item.fte"
                [disabled]="item.dataProcessing.fte"
                type="number"
                [min]="0"
                [max]="1"
                [step]="0.1"
                (focusout)="handleFteChange(item)"
                (keypress)="validateNumberWith($event)"
              />
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'O/D'"
            [binding]="'fteDuration'"
            [visible]="tkFilterData.isFteVisible"
            [minWidth]="30"
            [width]="90"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <label for="fteDuration-{{ row.index }}" class="sr-only">FTE duration</label>
              <input
                class="form-control"
                id="fteDuration-{{ row.index }}"
                name="fteDuration-{{ row.index }}"
                [(ngModel)]="item.fteDuration"
                [disabled]="item.dataProcessing.fteDuration"
                type="number"
                [min]="0"
                (focusout)="handleFteChange(item, 'OD')"
                (keypress)="validateNumberWith($event)"
              />
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Peer title'"
            [binding]="'peerTitle'"
            [visible]="tkFilterData.isPeerTitleVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <label for="peerTitle-{{ row.index }}" class="sr-only">Peer title</label>
              <bento-combobox
                id="peerTitle-{{ row.index }}"
                name="peerTitle-{{ row.index }}"
                (focusout)="handlePeerTitleChange(item)"
                [(ngModel)]="item.peerTitle"
                [itemsObservable]="tkFilterData.peerTitleStream"
                [options]="comboboxOptions"
                [placeholder]="'Select here...'"
                [appendTo]="'main'"
                [disabled]="item.dataProcessing.peerTitle"
                [revertOnInvalidInput]="true"
                bentoFlexGridControl
              >
              </bento-combobox>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'O/D'"
            [binding]="'peerTitleDuration'"
            [visible]="tkFilterData.isPeerTitleVisible"
            [minWidth]="30"
            [width]="90"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <label for="peerTitleDuration-{{ row.index }}" class="sr-only">Peer title duration</label>
              <input
                class="form-control"
                id="peerTitleDuration-{{ row.index }}"
                name="peerTitleDuration-{{ row.index }}"
                [(ngModel)]="item.peerTitleDuration"
                [disabled]="item.dataProcessing.peerTitleDuration"
                type="number"
                [min]="0"
                (focusout)="handlePeerTitleChange(item, 'OD')"
                (keypress)="validateNumberWith($event)"
              />
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Title'"
            [binding]="'firmTitle'"
            [visible]="tkFilterData.isTitleVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Peer practice'"
            [binding]="'peerPractice'"
            [visible]="tkFilterData.isPeerPracticeVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <ng-container *ngIf="!item.isSplitPracticeOverride">
                <label for="peerPractice-{{ row.index }}" class="sr-only">Peer practice</label>
                <bento-combobox
                  id="peerPractice-{{ row.index }}"
                  name="peerPractice-{{ row.index }}"
                  (focusout)="handlePeerPracticeChange(item)"
                  [(ngModel)]="item.peerPractice"
                  [itemsObservable]="tkFilterData.peerPracticeStream"
                  [options]="comboboxOptions"
                  [placeholder]="'Select here...'"
                  [appendTo]="'main'"
                  [disabled]="item.dataProcessing.peerPractice"
                  [revertOnInvalidInput]="true"
                  bentoFlexGridControl
                >
                </bento-combobox>
              </ng-container>
              <ng-container class="DataGrid-status" *ngIf="item.isSplitPracticeOverride">
                <span class="DataGrid-overrideIndicator">
                  <span class="sr-only">Override:</span>
                </span>
                <button class="splitButton" (click)="onSplitsClick(item, 'grid', 'peerPractice')">Split</button>
              </ng-container>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'O/D'"
            [binding]="'peerPracticeDuration'"
            [visible]="tkFilterData.isPeerPracticeVisible"
            [minWidth]="30"
            [width]="90"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <ng-container *ngIf="!item.isSplitPracticeOverride">
                <label for="peerPracticeDuration-{{ row.index }}" class="sr-only">Peer practice duration</label>
                <input
                  class="form-control"
                  id="peerPracticeDuration-{{ row.index }}"
                  name="peerPracticeDuration-{{ row.index }}"
                  [(ngModel)]="item.peerPracticeDuration"
                  [disabled]="item.dataProcessing.peerPracticeDuration"
                  type="number"
                  [min]="0"
                  (focusout)="handlePeerPracticeChange(item, 'OD')"
                  (keypress)="validateNumberWith($event)"
                />
              </ng-container>
              <ng-container *ngIf="item.isSplitPracticeOverride"> - - </ng-container>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Department'"
            [binding]="'department'"
            [visible]="tkFilterData.isDeptVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Section'"
            [binding]="'section'"
            [visible]="tkFilterData.isSectionVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'UDF'"
            [binding]="'udf'"
            [visible]="tkFilterData.isUdfVisible && fmsType != '4' && fmsType != '5' && fmsType != '3'"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Peer office'"
            [binding]="'peerOffice'"
            [visible]="tkFilterData.isPeerOfficeVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <ng-container *ngIf="!item.isSplitOfficeOverride">
                <label for="peerOffice-{{ row.index }}" class="sr-only">Peer office</label>
                <bento-combobox
                  id="peerOffice-{{ row.index }}"
                  name="peerOffice-{{ row.index }}"
                  (focusout)="handlePeerOfficeChange(item)"
                  [(ngModel)]="item.peerOffice"
                  [itemsObservable]="tkFilterData.peerOfficeStream"
                  [options]="comboboxOptions"
                  [placeholder]="'Select here...'"
                  [appendTo]="'main'"
                  [disabled]="item.dataProcessing.peerOffice"
                  [revertOnInvalidInput]="true"
                  bentoFlexGridControl
                ></bento-combobox>
              </ng-container>
              <div class="DataGrid-status" *ngIf="item.isSplitOfficeOverride">
                <span class="DataGrid-overrideIndicator">
                  <span class="sr-only">Override:</span>
                </span>
                <button class="splitButton" (click)="onSplitsClick(item, 'grid', 'peerOffice')">Split</button>
              </div>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'O/D'"
            [binding]="'peerOfficeDuration'"
            [visible]="tkFilterData.isPeerOfficeVisible"
            [minWidth]="30"
            [width]="90"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <ng-container *ngIf="!item.isSplitOfficeOverride">
                <label for="peerOfficeDuration-{{ row.index }}" class="sr-only">Peer office duration</label>
                <input
                  class="form-control"
                  id="peerOfficeDuration-{{ row.index }}"
                  name="peerOfficeDuration-{{ row.index }}"
                  [(ngModel)]="item.peerOfficeDuration"
                  [disabled]="item.dataProcessing.peerOfficeDuration"
                  type="number"
                  [min]="0"
                  (focusout)="handlePeerOfficeChange(item, 'OD')"
                  (keypress)="validateNumberWith($event)"
                />
              </ng-container>
              <ng-container class="DataGrid-status" *ngIf="item.isSplitOfficeOverride">- -</ng-container>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Office'"
            [binding]="'firmOffice'"
            [visible]="tkFilterData.isOfficeVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Experience years'"
            [binding]="'experienceYear'"
            [visible]="tkFilterData.isExpYearsVisible"
            [minWidth]="50"
            [width]="170"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <label for="experienceYear-{{ row.index }}" class="sr-only">Experience years</label>
              <input
                class="form-control"
                id="experienceYear-{{ row.index }}"
                name="experienceYear-{{ row.index }}"
                [(ngModel)]="item.experienceYear"
                [disabled]="item.dataProcessing.experienceYear"
                type="number"
                [min]="0"
                (focusout)="handleExperienceYearChange(item)"
                (keypress)="validateNumberWith($event)"
              />
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'O/D'"
            [binding]="'experienceYearDuration'"
            [visible]="tkFilterData.isExpYearsVisible"
            [minWidth]="30"
            [width]="90"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <label for="experienceYearDuration-{{ row.index }}" class="sr-only">Experience years duration</label>
              <input
                class="form-control"
                id="experienceYearDuration-{{ row.index }}"
                name="experienceYearDuration-{{ row.index }}"
                [(ngModel)]="item.experienceYearDuration"
                [disabled]="item.dataProcessing.experienceYearDuration"
                type="number"
                [min]="0"
                (focusout)="handleExperienceYearChange(item, 'OD')"
                (keypress)="validateNumberWith($event)"
              />
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Grad/Comp year'"
            [binding]="'gradCompYear'"
            [visible]="true"
            [minWidth]="50"
            [width]="170"
            [isReadOnly]="true"
            [wordWrap]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              {{ item.gradCompYear }}
            </ng-template>
          </wj-flex-grid-column>
        </wj-flex-grid>
        <!-- BENTO PAGINATION -->
        <bento-pagination
          #pagination
          [page]="gridData.pageIndex + 1"
          [directionLinks]="true"
          [boundaryLinks]="true"
          [totalItems]="totalCount"
          [(itemsPerPage)]="gridData.pageSize"
          (itemsPerPageChange)="onPageSizeChanged($event)"
          (pageChange)="onPageChanged($event)"
        ></bento-pagination>
      </div>
    </div>
  </div>
</main>

<ng-template #errorModal let-c="close" let-d="dismiss">
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalWarning" class="modal-header h2">Warning!</h3>
  <div class="modal-body">
    <p>{{ warningMessage }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="c('Close click')">OK</button>
  </div>
</ng-template>
