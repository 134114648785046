<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isTimeKeeperUpdated">
  <header class="Section-header">
    <h2 class="Section-heading">Timekeeper settings</h2>
  </header>
  <span class="label-required-summary">Required field</span>
  <form name="timeKeeper" #timeKeeper="ngForm" class="Section-form Form" bfmForm *ngIf="isGetTimeKeeperSettings">
    <div class="Section-body">
      <fieldset class="Form-set">
        <legend class="sr-only">Timekeeper rate source settings</legend>
        <div class="form-group Form-input Form-input--medium" *ngIf="firmType === '4'">
          <label for="standardRateSource">Rate source</label>
          <input
            type="text"
            name="standardRateSource"
            id="standardRateSource"
            class="form-control"
            pattern=".*\S+.*"
            bfmField
            bfmLabel="Rate source"
            [(ngModel)]="timekeeperSettings.standardRateSource"
          />
        </div>
        <div class="form-group Form-input Form-input--medium" *ngIf="firmType === '1'">
          <label for="standardRateSource" id="standardRateSourceLabel">Standard rate source</label>
          <select
            name="standardRateSource"
            id="standardRateSource"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Standard rate source"
            [(ngModel)]="timekeeperSettings.standardRateSource"
            aria-labelledby="standardRateSourceLabel"
          >
            <option *ngFor="let type of standarRateSourceValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>
        <ng-container *ngIf="firmType === '3'">
          <div class="form-group Form-input Form-input--medium">
            <label for="standardRateSource" id="standardRateSourceLabel">Aderant rate source</label>
            <select
              name="standardRateSource"
              id="standardRateSource"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Aderant rate source"
              aria-labelledby="standardRateSourceLabel"
              [(ngModel)]="timekeeperSettings.standardWorkRateSource"
            >
              <option *ngFor="let type of aderantRateSourceValues" [ngValue]="type.value">
                {{ type.label }}
              </option>
            </select>
          </div>
          <div
            class="form-group Form-input Form-input--medium"
            *ngIf="timekeeperSettings.standardRateSource == 'Rate Level / Base Amt'"
          >
            <label for="rateNumber" id="rateNumberLabel">Rate level number (STD)</label>
            <select
              name="rateNumber"
              id="rateNumber"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Rate level number (STD)"
              aria-labelledby="rateNumberLabel"
              [(ngModel)]="timekeeperSettings.rateNumber"
            >
              <option *ngFor="let type of rateNumbers" [ngValue]="type">
                {{ type }}
              </option>
            </select>
          </div>
          <div
            class="form-group Form-input Form-input--medium"
            *ngIf="timekeeperSettings.standardRateSource == 'User defined rate source'"
          >
            <label for="userDefinedStandardRateSource">User defined rate source</label>
            <input
              type="text"
              name="userDefinedStandardRateSource"
              id="userDefinedStandardRateSource"
              class="form-control"
              bfmField
              bfmLabel="User defined rate source"
              [(ngModel)]="timekeeperSettings.userDefinedStandardRateSource"
            />
          </div>
          <div class="form-group Form-input Form-input--medium">
            <label for="titleSource" id="aderantTitleSourceLabel">Aderant title source</label>
            <select
              name="titleSource"
              id="titleSource"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Aderant title source"
              aria-labelledby="aderantTitleSourceLabel"
              [(ngModel)]="timekeeperSettings.titleSource"
            >
              <option *ngFor="let type of titleSourceValues" [ngValue]="type.value">
                {{ type.label }}
              </option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="firmType === '4'">
          <div class="form-group Form-input Form-input--medium">
            <label for="currencySource">Currency Source</label>
            <input
              type="text"
              name="currencySource"
              id="currencySource"
              class="form-control"
              bfmField
              bfmLabel="Currency Source"
              pattern=".*\S+.*"
              [(ngModel)]="timekeeperSettings.currencySource"
            />
          </div>
        </ng-container>
        <div class="form-group Form-input Form-input--medium" *ngIf="firmType != '4' && firmType != '3'">
          <label for="titleSource" id="titleSourceLabel" class="bento-label-required">Title source</label>
          <select
            name="titleSource"
            id="titleSource"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Title source"
            required
            aria-labelledby="titleSourceLabel"
            [(ngModel)]="timekeeperSettings.titleSource"
          >
            <option *ngFor="let type of titleSourceValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>

        <div
          class="form-group Form-input Form-input--medium"
          *ngIf="firmType === '1' && timekeeperSettings.standardRateSource == 'timerate'"
        >
          <label for="rateNumber" id="rateNumberLabel">Rate number</label>
          <select
            name="rateNumber"
            id="rateNumber"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Rate number"
            aria-labelledby="rateNumberLabel"
            [(ngModel)]="timekeeperSettings.rateNumber"
          >
            <option *ngFor="let type of rateNumbers" [ngValue]="type">
              {{ type }}
            </option>
          </select>
        </div>
        <!-- <div class="form-group Form-input Form-input--medium Form-checkbox" *ngIf="firmType === '3'">
          <bento-checkbox
            name="sameSource"
            id="sameSource"
            [(ngModel)]="timekeeperSettings.sameSource"
          ></bento-checkbox>
          <label for="sameSource" class="checkbox-inline">Grad year - same source</label>
        </div> -->
      </fieldset>

      <fieldset class="Form-set" *ngIf="!timekeeperSettings.sameSource">
        <legend class="h4 Form-legend">Partner</legend>
        <div class="form-group Form-input Form-input--medium">
          <label for="gradYearSource" id="gradYearSourceLabel">Partner graduation year source</label>
          <div id="gradYearSourceDescription" class="hint-text" *ngIf="timekeeperSettings.gradYearSource === 'L'">
            Upload a file on the Timekeepers tab of the Mapping tools
          </div>
          <select
            name="gradYearSource"
            id="gradYearSource"
            class="form-control"
            aria-describedby="gradYearSourceDescription"
            bentoSelect
            bfmField
            bfmLabel="Partner graduation year source"
            aria-labelledby="gradYearSourceLabel"
            [(ngModel)]="timekeeperSettings.gradYearSource"
            (ngModelChange)="partnerGradSourceChange($event)"
          >
            <option *ngFor="let type of gradeSourceValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.gradYearSource === 'U'">
          <label for="gradYearName" class="bento-label-required">User defined field</label>
          <div class="hint-text">Format: table.column</div>
          <input
            type="text"
            name="gradYearName"
            id="gradYearName"
            class="form-control"
            bfmField
            bfmLabel="table.column"
            pattern="^[a-zA-Z0-9_]+\.[a-zA-Z0-9_]+$"
            [validations]="validations.validatePattern"
            [(ngModel)]="timekeeperSettings.gradYearName"
            required
          />
        </div>
        <div class="form-group Form-input Form-input--small">
          <label for="partnerAnniversaryMonth" id="partnerAnniversaryMonthLabel" class="bento-label-required"
            >Anniversary month</label
          >
          <select
            name="partnerAnniversaryMonth"
            id="partnerAnniversaryMonth"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Anniversary Month"
            required
            aria-labelledby="partnerAnniversaryMonthLabel"
            [(ngModel)]="timekeeperSettings.partnerAnniversaryMonth"
          >
            <option *ngFor="let type of anniversaryMonth" [ngValue]="type">
              {{ type }}
            </option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.gradYearSource === 'C'">
          <label for="gradYearName" id="gradYearNameLabel" class="bento-label-required"
            >Partner graduation year name</label
          >
          <select
            name="gradYearName"
            id="gradYearName"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Partner graduation year name"
            required
            aria-labelledby="gradYearNameLabel"
            [(ngModel)]="timekeeperSettings.gradYearName"
          >
            <option *ngFor="let type of partnerGradeYearNameValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.gradYearSource != 'L'">
          <label for="gradYearType" id="gradYearTypeLabel">Partner graduation year type</label>
          <select
            name="gradYearType"
            id="gradYearType"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Partner graduation year type"
            aria-labelledby="gradYearTypeLabel"
            [(ngModel)]="timekeeperSettings.gradYearType"
          >
            <option *ngFor="let type of yearTypeValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>
      </fieldset>

      <fieldset class="Form-set">
        <legend class="h4 Form-legend">Associate</legend>
        <div class="form-group Form-input Form-input--medium">
          <label for="compYearSource" id="compYearSourceLabel">Associate graduation year source</label>
          <div id="compYearSourceDescription" class="hint-text" *ngIf="timekeeperSettings.compYearSource === 'L'">
            Upload a file on the Timekeepers tab of the Mapping tools
          </div>
          <select
            name="compYearSource"
            id="compYearSource"
            class="form-control"
            aria-describedby="compYearSourceDescription"
            bentoSelect
            bfmField
            bfmLabel="Associate graduation year source"
            aria-labelledby="compYearSourceLabel"
            [(ngModel)]="timekeeperSettings.compYearSource"
            (ngModelChange)="associateGradSourceChange($event)"
          >
            <option *ngFor="let type of gradeSourceValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.compYearSource === 'U'">
          <label for="compYearName" class="bento-label-required">User defined field</label>
          <div class="hint-text">Format: table.column</div>
          <input
            type="text"
            name="compYearName"
            id="compYearName"
            class="form-control"
            bfmField
            bfmLabel="table.column"
            pattern="^[a-zA-Z0-9_]+\.[a-zA-Z0-9_]+$"
            [validations]="validations.validatePattern"
            required
            [(ngModel)]="timekeeperSettings.compYearName"
          />
        </div>
        <div class="form-group Form-input Form-input--medium row">
          <div class="col">
            <label for="associateAnniversaryMonth" id="associateAnniversaryMonthLabel" class="bento-label-required"
              >Anniversary month</label
            >
            <select
              name="associateAnniversaryMonth"
              id="associateAnniversaryMonth"
              class="form-control"
              bentoSelect
              bfmField
              required
              aria-labelledby="associateAnniversaryMonthLabel"
              bfmLabel="Anniversary Month"
              [(ngModel)]="timekeeperSettings.associateAnniversaryMonth"
            >
              <option *ngFor="let type of anniversaryMonth" [ngValue]="type">
                {{ type }}
              </option>
            </select>
          </div>
          <div class="col">
            <label for="associateAnniversaryYear" id="associateAnniversaryYearLabel" class="bento-label-required"
              >Initial anniversary</label
            >
            <select
              name="associateAnniversaryYear"
              id="associateAnniversaryYear"
              class="form-control"
              bentoSelect
              bfmField
              required
              aria-labelledby="associateAnniversaryYearLabel"
              bfmLabel="Anniversary Year"
              [(ngModel)]="timekeeperSettings.associateAnniversaryYear"
            >
              <option *ngFor="let type of anniversaryYear" [ngValue]="type">
                {{ type }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.compYearSource === 'C'">
          <label for="GradeYearNameValues" id="GradeYearNameValuesLabel" class="bento-label-required"
            >Associate graduation year name</label
          >
          <select
            name="GradeYearNameValues"
            id="GradeYearNameValues"
            class="form-control"
            bentoSelect
            bfmField
            required
            bfmLabel="Associate graduation year name"
            aria-labelledby="GradeYearNameValuesLabel"
            [(ngModel)]="timekeeperSettings.compYearName"
          >
            <option *ngFor="let type of partnerGradeYearNameValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.compYearSource != 'L'">
          <label for="compYearType" id="compYearTypeLabel">Associate graduation year type</label>
          <select
            name="compYearType"
            id="compYearType"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Associate graduation year type"
            aria-labelledby="compYearTypeLabel"
            [(ngModel)]="timekeeperSettings.compYearType"
          >
            <option *ngFor="let type of yearTypeValues" [ngValue]="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>
        <div *ngIf="timekeeperSettings.sameSource">
          <div class="form-group Form-input Form-input--medium">
            <label for="gradYearSource" id="gradYearSourceLabel">Grad/Comp year source</label>
            <div id="gradYearSourceDescription" class="hint-text" *ngIf="timekeeperSettings.gradYearSource === 'L'">
              Upload a file on the Timekeepers tab of the Mapping tools
            </div>
            <select
              name="gradYearSource"
              id="gradYearSource"
              class="form-control"
              aria-describedby="gradYearSourceDescription"
              bentoSelect
              bfmField
              bfmLabel="Grad/Comp year source"
              aria-labelledby="gradYearSourceLabel"
              [(ngModel)]="timekeeperSettings.gradYearSource"
            >
              <option *ngFor="let type of gradeSourceValues" [ngValue]="type.value">
                {{ type.label }}
              </option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.gradYearSource === 'U'">
            <label for="gradYearName">User defined field</label>
            <input
              type="text"
              name="gradYearName"
              id="gradYearName"
              class="form-control"
              pattern=".*\S+.*"
              bfmField
              bfmLabel="User defined field"
              [(ngModel)]="timekeeperSettings.gradYearName"
            />
          </div>
          <div
            *ngIf="timekeeperSettings.gradYearSource === 'C' || timekeeperSettings.gradYearSource === 'U'"
            class="form-group Form-input Form-input--medium"
          >
            <label for="gradYearType" id="gradYearTypeLabel">Grad/Comp year type</label>
            <select
              name="gradYearType"
              id="gradYearType"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Grad/Comp year type"
              aria-labelledby="gradYearTypeLabel"
              [(ngModel)]="timekeeperSettings.gradYearType"
            >
              <option *ngFor="let type of yearTypeValues" [ngValue]="type.value">
                {{ type.label }}
              </option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium" *ngIf="timekeeperSettings.gradYearSource === 'C'">
            <label for="GradeYearNameValues" id="GradeYearNameValuesLabel">Grad/Comp year name</label>
            <select
              name="GradeYearNameValues"
              id="GradeYearNameValues"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Grad/Comp year name"
              aria-labelledby="GradeYearNameValuesLabel"
              [(ngModel)]="timekeeperSettings.compYearName"
            >
              <option *ngFor="let type of partnerGradeYearNameValues" [ngValue]="type.value">
                {{ type.label }}
              </option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium">
            <label for="partnerAnniversaryMonth" id="partnerAnniversaryMonthLabel">Partner anniversary month</label>
            <select
              name="partnerAnniversaryMonth"
              id="partnerAnniversaryMonth"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Partner anniversary month"
              aria-labelledby="partnerAnniversaryMonthLabel"
              [(ngModel)]="timekeeperSettings.partnerAnniversaryMonth"
            >
              <option *ngFor="let type of anniversaryMonth" [ngValue]="type">
                {{ type }}
              </option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium">
            <label for="associateAnniversaryMonth" id="associateAnniversaryMonthLabel"
              >Associate anniversary month</label
            >
            <select
              name="associateAnniversaryMonth"
              id="associateAnniversaryMonth"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Associate anniversary month"
              aria-labelledby="associateAnniversaryMonthLabel"
              [(ngModel)]="timekeeperSettings.associateAnniversaryMonth"
            >
              <option *ngFor="let type of anniversaryMonth" [ngValue]="type">
                {{ type }}
              </option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium">
            <label for="associateAnniversaryYear" id="associateAnniversaryYearLabel"
              >Associate initial anniversary</label
            >
            <select
              name="associateAnniversaryYear"
              id="associateAnniversaryYear"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Associate anniversary year"
              aria-labelledby="associateAnniversaryYearLabel"
              [(ngModel)]="timekeeperSettings.associateAnniversaryYear"
            >
              <option *ngFor="let type of anniversaryYear" [ngValue]="type">
                {{ type }}
              </option>
            </select>
          </div>
        </div>
      </fieldset>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetTimekeeper()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item">
          <button type="submit" class="btn btn-primary" [disabled]="timeKeeper.invalid" (click)="saveTimekeeper()">
            <span class="btn-text">Save</span>
          </button>
        </div>
      </div>
    </footer>
  </form>
</section>
