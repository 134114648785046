<header class="Main-header">
  <h1 class="Main-heading Main-heading-mb-0">Rates Reports</h1>
</header>
<div class="Main-body Content">
  <div class="bento-tabset tabs-top">
    <ul
      ngbNav
      #nav="ngbNav"
      [activeId]="activeTab"
      (navChange)="onTabChange($event.nextId)"
      class="nav nav-tabs"
      ngPreserveWhitespaces
    >
      <li [ngbNavItem]="title" id="{{ title }}" *ngFor="let title of tabData">
        <a ngbNavLink>
          <bento-tabs-label [label]="title"></bento-tabs-label>
        </a>
        <ng-template ngbNavContent>
          <div *ngIf="activeTab === 'Rates reports'">
            <app-rates-reports></app-rates-reports>
          </div>

          <div *ngIf="activeTab === 'Reports in progress'">
            <app-reports-progress></app-reports-progress>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="tab-content"></div>
  </div>
</div>
