export const mainOfficeCountryValues = [
  {value: 'United States', label: 'United States'},
  {value: 'Australia', label: 'Australia'},
  {value: 'United Kingdom', label: 'United Kingdom'},
  {value: 'Canada', label: 'Canada'},
];

export const mainOfficeCountryFullName = [
  {countryCode: 'US', countryDescription: 'United States'},
  {countryCode: 'AU', countryDescription: 'Australia'},
  {countryCode: 'UK', countryDescription: 'United Kingdom'},
  {countryCode: 'CA', countryDescription: 'Canada'},
  {countryCode: 'NZ', countryDescription: 'New Zealand'},
];

export const defaultAccessFullName = [
  {countryCode: 'US', countryDescription: 'United States'},
  {countryCode: 'AU', countryDescription: 'Australia'},
  {countryCode: 'UK', countryDescription: 'United Kingdom'},
  {countryCode: 'CAN', countryDescription: 'Canada'},
  {countryCode: 'NZ', countryDescription: 'New Zealand'},
];

export const types = [
  {status: 1, name: 'Completed'},
  {status: 2, name: 'Completed'},
  {status: 3, name: 'In complete'},
];
export const statusValues = [
  {value: 1, label: 'Not started'},
  {value: 2, label: 'In Progress (2)'},
  {value: 3, label: 'Completed'},
  {value: 4, label: 'In Progress (4)'},
];
export const currencyValues = [
  {value: 'GBP', label: 'British Pounds'},
  {value: 'USD', label: 'US Dollars'},
  {value: 'AUD', label: 'Australian Dollars'},
  {value: 'CAD', label: 'Canadian Dollars'},
  {value: 'EUR', label: 'Euros'},
];
export const peerGroupValues = [
  {value: 'T', label: 'Exclude'},
  {value: 'F', label: 'Include'},
];
export const performancePeerGroupValues = [
  {value: 'N', label: 'Exclude'},
  {value: 'Y', label: 'Include'},
];

export const peerGroupsAllowesValues = [
  {value: false, label: 'No'},
  {value: true, label: 'Yes'},
];
export const firmTypeValues = [
  {value: '1', label: 'Elite Enterprise'},
  {value: '3', label: 'Aderant'},
  {value: '4', label: 'Elite 3E'},
  {value: '5', label: '3E Cloud'},
  {value: '9', label: 'Generic'}
];

export const standarRateSourceValues = [
  {value: 'accrate', label: 'accrate'},
  {value: 'timekeep', label: 'timekeep'},
  {value: 'timerate', label: 'timerate'},
];

export const aderantRateSourceValues = [
  {value: 'Std Amt / Base Amt', label: 'Standard amount / base amount'},
  {value: 'Std Amt / toBill Amt', label: 'Standard amount / to bill amount'},
  {value: 'Base Amt / ToBill Amt', label: 'Base amount / to bill amount'},
  {value: 'Rate Level / Base Amt', label: 'Rate Level / base amount'},
  {value: 'User defined rate source', label: 'User defined rate source'},
];

export const gradeSourceValues = [
  {value: 'C', label: 'Column'},
  {value: 'L', label: 'Load'},
  {value: 'U', label: 'User-Defined'},
];

export const gradeYearNameValues = [
  {value: 'tkgrdate', label: 'tkgrdate'},
  {value: 'tkbrdate', label: 'tkbrdate'},
];

export const yearTypeValues = [
  {value: 'D', label: 'Date'},
  {value: 'Y', label: 'Year'},
];

export const glLedgerValues = [
  {dataCode: 'C', dataName: 'Cash'},
  {dataCode: 'A', dataName: 'Accrual'},
];

export const glDeptSourceValues = [
  {dataCode: 'GLDepartment', dataName: 'GL Department'},
  {dataCode: 'GLSection', dataName: 'GL Section'},
];

export const glLedger3EValues = [
  {dataCode: 'C', dataName: 'Cash'},
  {dataCode: 'A', dataName: 'Accrual'},
];

export const titleSourceValues = [
  {value: 'RANK_CODE', label: 'RANK_CODE'},
  {value: 'PERSNL_TYP_CODE', label: 'PERSNL_TYP_CODE'},
];

export const gradYearName3EValues = [
  {value: 'Timekeeper.JDDate', label: 'Timekeeper.JDDate'},
  {value: 'Timekeeper.CompDate', label: 'Timekeeper.CompDate'},
  {value: 'Timekeeper.RateYear', label: 'Timekeeper.RateYear'},
  {value: 'TkprDate.HireDate', label: 'TkprDate.HireDate'},
  {value: 'TkprSchool.GradDate', label: 'TkprSchool.GradDate'},
];

export const UDF_FIELD_NAME_VALUES = [
  {value: 'LOCATION_CODE', label: 'LOCATION_CODE'},
  {value: 'PERSNL_TYP_CODE', label: 'PERSNL_TYP_CODE'},
  {value: 'WORK_TYPE_CODE', label: 'WORK_TYPE_CODE'},
  {value: 'OTHER', label: 'OTHER'},
];

export const PracticeGroupComponentOptions = [
  {name: 'Department', selected: false, id: 1},
  {name: 'Section', selected: false, id: 2},
  {name: 'Office', selected: false, id: 3},
  {name: 'User-defined', selected: false, id: 4},
];

export const aderantGradeYearNameValues = [
  {value: 'GRAD_YEAR', label: 'GRAD_YEAR'},
  {value: 'COMP_YEAR', label: 'COMP_YEAR'},
];
export const aderatnGLLedgerValues = [
  {dataCode: '1', dataName: '1'},
  {dataCode: '2', dataName: '2'},
];

export const elite3EGLValue = [
  {id: 5721, firmId: 907, dataCode: 'Accrual', dataName: 'Accrual'},
  {id: 5722, firmId: 907, dataCode: 'Cash', dataName: 'Cash'},
];

export const wijmoLocalLicenceKey = {
  /* eslint-disable max-len */
  key: 'Thomson Reuters*,169966291453339#B07eYICRiwiI34zZ784MadjVZVlbzcHM8ZTYV96ZZR5ZuFXN5ZFdC56RzEmRwR7VldnaXdmS84UM6h5YM5GSwRzaYljTjdXe4QHULh4U42idrNTT4QzbQNmWJNXRix4USNGWx2CbYpVb8InU4cUWnlDcHxkYDNleJB7RQ9UWxNENSdWcKF6YZlzKrJWVPRjW7NlMZBVRalVcJNlR5wkd0V6UrJFead4V5gVSwNEUxg4NDlFcNtyczwEc8pmZVVlYnVUVtZDZoJWM4V4R0RlVSpnNORXd8FHRvUVNKF7b8N4TnhjdrUjMGJHdFlGcIRTa7NjdyZ6TthGRGx4NntGWkNkarclUul6U7x6bhJHNTt6doZ5ZMV7Y6cnZ4V4QyZ4QYV7cEJVS7RUZyRnZGx6YF3kTO34UGRGMSVla8U5SzhWW9gmbo3iT9MEVLlWbjp6Z9JEUIpnMZN5b7V4cupUczpmI0IyUiwiI7IUQGVDRBdjI0ICSiwyM5gTNzUjN9gTM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIyM5EzM8ADIyAjMxIjMwIjI0ICdyNkIsIiKzJXZ4VXZSBibvNXbvhGViojIh94QiwiI9MzMzUDNxkjM6YTO9YTMiojIklkIs4XXbpjInxmZiwiIyYnMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwbbMJ',
};

export const tAdminAddUserRole = [
  {value: 'tadmin', label: 'Thomson Reuters Admin'},
  {value: 'fadmin', label: 'Firm Admin'},
  {value: 'guser', label: 'General User'},
  {value: 'noaccess', label: 'No access'},
];

export const fAdminAddUserRole = [
  {value: 'fadmin', label: 'Firm Admin'},
  {value: 'guser', label: 'General User'},
];

export const pmmUserValues = [
  {value: false, label: 'No'},
  {value: true, label: 'Yes'},
];

export const colorPalette = [
  'rgba(43, 56, 102, 1)',
  'rgba(193, 92, 95, 1)',
  'rgba(59, 122, 111, 1)',
  'rgba(100, 88, 183, 1)',
  'rgba(112, 112, 112, 1)',
];
