<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main" [bentoBusyLoader]="isGetDataLoading">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <h1 class="Main-heading">Titles</h1>
        <span class="sr-only" id="titlesName">Titles {{ rowCount }} rows {{ colCount }} columns</span>
        <app-mapping-firms-details></app-mapping-firms-details>
      </div>
      <div class="col">
        <app-action-dropdown
          #actionButton
          [actionDropdownItems]="actionDropdownItems"
          (dropDownSelectChange)="dropDownOnSelectChange($event)"
        >
        </app-action-dropdown>
      </div>
    </div>
  </header>
  <div class="Main-body">
    <wj-flex-grid
      #flexGrid
      class="DataGrid DataGridFlexScroll"
      [class.list-grid]="isListGrid"
      [itemsSource]="data"
      [stickyHeaders]="true"
      [showMarquee]="true"
      [showSort]="true"
      [allowDragging]="false"
      [autoRowHeights]="true"
      (initialized)="gridInitialized(flexGrid)"
    >
      <wj-flex-grid-filter #filter (initialized)="initFilter()"></wj-flex-grid-filter>
      <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'" let-col="col">
        <ng-container *ngIf="col.index === 0">
          <bento-checkbox
            id="selectAll"
            [ngModel]="isAllSelected()"
            (change)="onHeaderCheckBoxChange()"
            [indeterminate]="isIndeterminate()"
          ></bento-checkbox>
          <label for="selectAll" class="sr-only">Select all</label>
        </ng-container>
      </ng-template>
      <ng-template wjFlexGridCellTemplate [cellType]="'RowHeader'" let-col="col" let-row="row" let-item="item">
        <ng-container *ngIf="col.index === 0">
          <bento-checkbox
            id="selectRow-{{ row.index }}"
            [ngModel]="item.selected"
            (change)="item.disabled || (item.selected = !item.selected); onCheckBoxChange()"
          ></bento-checkbox>
          <label for="selectRow-{{ row.index }}" class="sr-only">{{ item.name }}</label>
        </ng-container>
      </ng-template>
      <wj-flex-grid-column
        [header]="'Code'"
        [binding]="'firmCode'"
        [visible]="columnDefinitionsByBinding['firmCode'].visible"
        [minWidth]="columnDefinitionsByBinding['firmCode'].minWidth"
        [width]="columnDefinitionsByBinding['firmCode'].width"
        [isReadOnly]="columnDefinitionsByBinding['firmCode'].isReadOnly"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Name'"
        [binding]="'firmDescription'"
        [minWidth]="columnDefinitionsByBinding['firmDescription'].minWidth"
        [width]="columnDefinitionsByBinding['firmDescription'].width"
        [visible]="columnDefinitionsByBinding['firmDescription'].visible"
        [isReadOnly]="columnDefinitionsByBinding['firmDescription'].isReadOnly"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Mapped title name'"
        [binding]="'pmCode'"
        [visible]="columnDefinitionsByBinding['pmCode'].visible"
        [minWidth]="columnDefinitionsByBinding['pmCode'].minWidth"
        [width]="columnDefinitionsByBinding['pmCode'].width"
        [wordWrap]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
          <label for="mappedTitleName" class="sr-only">Mapped title name</label>
          <bento-combobox
            id="mappedTitleName"
            [(ngModel)]="cell.value"
            (ngModelChange)="onChangeDropdownValue($event, cell)"
            [itemsObservable]="dataEmitter"
            [options]="comboboxOptions"
            [placeholder]="'Select here...'"
            [appendTo]="'main'"
            (ready)="onComboboxReady()"
            bentoFlexGridControl
          ></bento-combobox>
        </ng-template>
      </wj-flex-grid-column>
    </wj-flex-grid>
    <!-- TODO: Comment out pagination per Product -->
    <!-- BENTO PAGINATION -->
    <!-- <bento-pagination
      [page]="data.pageIndex + 1"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [totalItems]="data.totalItemCount"
      [(itemsPerPage)]="data.pageSize"
      (itemsPerPageChange)="onPageSizeChanged($event)"
      (pageChange)="onPageChanged($event)"
    ></bento-pagination> -->
  </div>
  <footer class="Main-footer">
    <div class="Actions justify-content-end">
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" [disabled]="isDisabled" (click)="resetTitles()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item">
        <button type="submit" class="btn btn-primary" [disabled]="isDisabled" (click)="saveTitles()">
          <span class="btn-text">Save</span>
        </button>
      </div>
    </div>
  </footer>
</main>
