import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject} from 'rxjs';
import SplitPostModel, {
  SplitDetail,
  SplitDetails,
  SplitOverrides,
  TimekeeperDetails,
} from 'src/app/core/models/splitAccount.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {GlobalService} from 'src/app/core/services/global/global.service';
import $ from 'jquery';

@Component({
  selector: 'app-split-account-grid',
  templateUrl: './split-account-grid.component.html',
  styleUrls: ['./split-account-grid.component.scss'],
})
export class SplitAccountGridComponent implements OnInit {
  @Input() public header: any;
  @Input() selectedOptions: any;
  @Input() public headers: any;
  @Input() public sourceOfClick: any;
  @ViewChild('splitDetail', {static: true}) splitDetail: any;
  splitsValidation = '^[1-9][0-9]?$|^100$';
  splitsValidationOd = '^[1-9][0-9]?$|^99$';
  validations: any;
  selectedTimeKeeper: any;
  timeKeeperDetails: TimekeeperDetails[] = [];
  splitDetails: SplitDetails[] = [];
  selectedDetails: SplitOverrides;
  splitPostModel: SplitPostModel = new SplitPostModel();
  alerts: BentoAlertItemOptions[] = [];
  isConfirmationVisible = false;
  isInvalidAction = false;
  closeResult: string;
  peerPracticeDropDownValues = [];
  peerOfficeDropDownValues = [];
  firmId: number;
  practiceDataStream: BehaviorSubject<any>;
  officeDataStream: BehaviorSubject<any>;
  errorMessage: any;
  successMsg: string;
  validateSplits = true;
  totalPercentage: number;
  constructor(public activeModal: NgbActiveModal, private route: ActivatedRoute, private service: BaseService, private globalservice: GlobalService) {
    this.validations = {
      splitsValidation: {
        pattern: 'Error: Value should be between 1 to 100',
      },
      splitsValidationOd: {
        pattern: 'Error: Value should be between 1 to 99',
      },
    };
    this.selectedDetails = new SplitOverrides();
    this.successMsg = '';
  }
  comboboxOptions = {
    searchable: true, // Make combobox searchable,
    labelFormatter: (row) => `${row.description}`,
  };

  ngOnInit(): void {
    this.practiceDataStream = new BehaviorSubject(this.peerPracticeDropDownValues);
    this.officeDataStream = new BehaviorSubject(this.peerOfficeDropDownValues);
    if (this.selectedTimeKeeper && this.selectedTimeKeeper.length == 1) this.getSplitData();
    else this.addCode();
  }
  onSplitsChange() {
    let cnt = 0;
    for (let i = 0; i < this.selectedDetails.splitDetails.length; i++) {
      if (
        (!this.selectedDetails.splitDetails[i].selectedOfficeOption &&
          !this.selectedDetails.splitDetails[i].selectedPracticeOption) ||
        !this.selectedDetails.splitDetails[i].percentage ||
        parseInt(this.selectedDetails.splitDetails[i].percentage + '') < 1 ||
        parseInt(this.selectedDetails.splitDetails[i].percentage + '') > 100 ||
        !this.selectedDetails.splitDetails[i].duration ||
        parseInt(this.selectedDetails.splitDetails[i].duration + '') < 1 ||
        parseInt(this.selectedDetails.splitDetails[i].duration + '') > 99
      ) {
        this.validateSplits = false;
        break;
      }
      cnt++;
    }
    if (cnt == this.selectedDetails.splitDetails.length) {
      this.validateSplits = true;
    }
    this.globalservice.skipFocusTwiceCombobox();
  }
  getSplitData() {
    let url = `v1/timekeeper/history/override/split/${this.firmId}`;
    // if (this.header === 'Split Office') {
    //   url = `v1/timekeeper/history/override/office/split/${this.firmId}`;
    // } else if (this.header === 'Split Practice') {
    //   url = `v1/timekeeper/history/override/practice/split/${this.firmId}`;
    // }
    this.service
      .get(
        environment.FIClientBaseEndpoint + url,
        `?period=${this.selectedTimeKeeper[0].period}&tkId=${this.selectedTimeKeeper[0].tkId}`
      )
      .subscribe(
        (response) => {
          this.selectedDetails = response;
          if (!this.selectedDetails.splitDetails || !this.selectedDetails.splitDetails.length) {
            this.addCode();
            return;
          } else {
            this.selectedDetails.splitDetails.forEach((obj) => {
              var opt = this.peerPracticeDropDownValues.find((peerOption) => peerOption.code == obj.pmPracticeId);
              obj.selectedPracticeOption = opt ? opt : null;

              var opt1 = this.peerOfficeDropDownValues.find((peerOption) => peerOption.code == obj.pmOfficeId);
              obj.selectedOfficeOption = opt1 ? opt1 : null;
            });
          }
          this.globalservice.skipFocusTwiceCombobox();
        },
        (error) => {
          this.errorMessage = error.error;
          var msg = '';
          if (this.errorMessage.hasOwnProperty('message')) {
            msg = this.errorMessage.message;
          } else {
            msg = 'Something went wrong, please try again.';
          }
          this.activeModal.close(msg);
        }
      );
  }

  deleteCode(index) {
    this.selectedDetails.splitDetails.splice(index, 1);
    this.onSplitsChange();
  }

  addCode() {
    var option = new SplitDetail();
    if (this.selectedDetails.splitDetails == null || this.selectedDetails.splitDetails == undefined)
      this.selectedDetails.splitDetails = [];
    this.selectedDetails.splitDetails.push(option);
    this.onSplitsChange();
  }

  saveData() {
    this.totalPercentage = 0;
    for (let index = 0; index < this.selectedDetails.splitDetails.length; index++) {
      if (this.selectedDetails.splitDetails[index].percentage) {
        this.totalPercentage = Number(this.selectedDetails.splitDetails[index].percentage) + this.totalPercentage;
      }
    }
    if (this.totalPercentage <= 100) {
      for (let i = 0; i < this.selectedTimeKeeper.length; i++) {
        let objTimekeeperDetails = new TimekeeperDetails();
        objTimekeeperDetails.tkId = this.selectedTimeKeeper[i].tkId;
        objTimekeeperDetails.startPeriod = this.selectedTimeKeeper[i].period;
        this.timeKeeperDetails.push(objTimekeeperDetails);
      }
      for (let index = 0; index < this.selectedDetails.splitDetails.length; index++) {
        let objSplitDetails = new SplitDetails();
        if (this.selectedDetails.splitDetails[index].selectedOfficeOption) {
          objSplitDetails.officeId = parseInt(this.selectedDetails.splitDetails[index].selectedOfficeOption.code);
        }
        if (this.selectedDetails.splitDetails[index].selectedPracticeOption) {
          objSplitDetails.practiceId = parseInt(this.selectedDetails.splitDetails[index].selectedPracticeOption.code);
        }
        objSplitDetails.percentage = Number(this.selectedDetails.splitDetails[index].percentage);
        objSplitDetails.duration = Number(this.selectedDetails.splitDetails[index].duration);
        this.splitDetails.push(objSplitDetails);
      }
      this.splitPostModel.splitDetails = this.splitDetails;
      this.splitPostModel.timekeeperDetails = this.timeKeeperDetails;
      let url = 'v1/timekeeper/history/override/split/';
      // if (type === 'office') {
      //   url = 'v1/timekeeper/history/override/office/';
      // } else if (type == 'practice') {
      //   url = 'v1/timekeeper/history/override/practice/';
      // }
      this.service.post(environment.FIClientBaseEndpoint + url + this.firmId, this.splitPostModel).subscribe(
        (response) => {
          this.successMsg = 'Data modified successfully.';
          this.activeModal.close('Success');
        },
        (error) => {
          this.errorMessage = error.error;
          var msg = '';
          if (this.errorMessage.hasOwnProperty('message')) {
            msg = this.errorMessage.message;
          } else {
            msg = 'Something went wrong, please try again.';
          }
          this.activeModal.close(msg);
        }
      );
    } else {
      if (!this.isInvalidAction && this.isConfirmationVisible) {
        this.errorMessage = 'Total Percentage should not be greater than 100.';
        this.activeModal.close('Total Percentage should not be greater than 100.');
      } else {
        this.alerts.push({
          type: 'warning',
          msg: 'Total Percentage should not be greater than 100.',
          closeable: true,
        });
      }
    }
  }
  removeTimekeeper(index) {
    if (this.selectedTimeKeeper.length > 1) {
      this.selectedTimeKeeper.splice(index, 1);
    } else {
      this.isInvalidAction = true;
    }
  }
  cancelOrContinue() {
    this.isConfirmationVisible = !this.isConfirmationVisible;
    this.globalservice.skipFocusTwiceCombobox();
    
    setTimeout(function () {
      $('.split_close').focus();
    }, 0);
  }

  keyPress(event: KeyboardEvent, value) {
    if (parseInt(value + event.key) > 100) {
      event.preventDefault();
    }
  }
}
