<div role="dialog"
tabindex="-1"
aria-modal="true"
aria-labelledby="modalShareView" class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
    <h3 id="modalShareView" class="modal-header h2">Edit Custom Peer Group Name</h3>
  <form name="renameCustomPeerGroup" #renameCustomPeerGroup="ngForm" class="Section-form Form" bfmForm >
    <div class="modal-body">
      <span class="bento-label-required">Please enter a new name for this Custom Peer Group:</span>
      <div class="form-group"  style="padding-top: 15px;">
        
        <input 
          type="text"
          name="custPGName"
          id="custPGName"
          class="form-control"
          bfmField
          bfmLabel="Custom peer group name"
          required
          aria-label="Custom peer group name"
          [pattern]="validatePattern"
          [validations]="validations.validatePattern"
          [(ngModel)]="peerGroupName"/>
      </div>

    </div>
    <div class="modal-footer a11y">
      <button type="button" 
      class="btn btn-primary" 
      (click)="renameCustomPeerGroup.invalid || peerGroupName == peerGroup.pgname ? $event.stopPropagation() :activeModal.close(peerGroupName)" 
      [attr.aria-disabled]=" renameCustomPeerGroup.invalid || peerGroupName == peerGroup.pgname ? 'true' : 'false'" 
      [attr.aria-labelledby]="renameCustomPeerGroup.invalid || peerGroupName == peerGroup.pgname ? 'aria-error' : null"
       >Save</button>
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
      <div class="sr-only" id="aria-error">Save</div>
    </div> 
  </form>
  </div>
  