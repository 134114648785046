import { Component, ViewChild } from '@angular/core';
import * as mockfirmdata from '../staffing-ratio-add-peer-groups/mock-firm-data.json';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import {environment} from 'src/environments/environment';
import { BaseService } from 'src/app/core/services/base/base.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import StaffingratioFirmSettings, { CustomSegmentRequestBody, SRDataProcessing } from 'src/app/core/models/staffingration-subscription-settings.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import { BackendTokenClaims } from 'src/app/core/models/tokenResponse';
import { setSelectionRange } from '@grapecity/wijmo';

@Component({
  selector: 'app-staffing-ratio-cutomise-segment',
  templateUrl: './staffing-ratio-cutomise-segment.component.html',
  styleUrls: ['./staffing-ratio-cutomise-segment.component.scss'],
})
export class StaffingRatioCutomiseSegmentComponent {

  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  currentRecords: number = 0;
  totalRecords: number =0;
  enableClearFilter = false;
  data: wjcCore.CollectionView;
  allFirmSegmentData: StaffingratioFirmSettings[] =[];
  originalAllFirmSegmentData: StaffingratioFirmSettings[] =[];
  isDataLoading: boolean = false;
  alerts: BentoAlertItemOptions[] = [];
  currentSurveyYearOfFirm:number;
  lastPressedKey: any = null;
  constructor(
    private authService: AuthService,
    private service: BaseService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isDataLoading=true;
    await this.getCurrentSurveyYear();
    await this.getAllSegmentData();
    this.allFirmSegmentData.forEach(val => this.originalAllFirmSegmentData.push(Object.assign({}, val)));
    this.data = new wjcCore.CollectionView(this.allFirmSegmentData);
    this.totalRecords = this.allFirmSegmentData.length;
  }
  gridInitialized(flexGrid: wjcGrid.FlexGrid, gridHeaderId: string = '') {
    flexGrid.hostElement.addEventListener(
      'keydown',
      (e) => {
        this.lastPressedKey = e.code;

        // Manually select the nect cell.
        if (e.code === 'Tab') {
          let cell = this.getNextColumnToSelect();
          flexGrid.select(cell.row, cell.col);
          //
          e.preventDefault();
        }
      },
      true
    );
    flexGrid.rows.defaultSize = 42;
    flexGrid.columnHeaders.rows.defaultSize = 42;
    flexGrid.autoGenerateColumns = false;
    this.flexGrid = flexGrid;
    this.currentRecords = flexGrid.rows.length;
  }
  getNextColumnToSelect() {
    let grid = this.flexGrid;
    let col = grid.selection.rightCol + 1,
      row = grid.selection.bottomRow;

    // If last cell in a row is already selected.
    if (col >= grid.columns.length) {
      // If there are any more rows in the grid.
      if (row + 1 < grid.rows.length) row++;
      return { col: 0, row };
    }
    //
    return { col, row };
  }

  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    if (this.lastPressedKey === 'Tab') {
      let column = args.getColumn();
      if (column.binding === 'amLawSegment' || column.binding === 'lawyerSegment' || column.binding === 'officeSegment') {
        let input = args.panel
          .getCellElement(args.row, args.col)
          .querySelector('input');
        // Select the content of the input element.
        input.focus();
        setSelectionRange(input, 0, -1);
      }
      this.lastPressedKey = null;
    }
  }

initFilter() {
  this.gridFilter.showSortButtons = false;
}

onFilterChange() {
  this.enableClearFilter = true;
}
onFilterApply(){
  this.currentRecords = this.flexGrid.rows.length;
}
saveCustomeSegment(row :StaffingratioFirmSettings ,cell,columnName : string) {
  let indx = this.allFirmSegmentData.indexOf(row);
  let originalOption = this.originalAllFirmSegmentData[indx];
  if((columnName == 'amLawSegment' && originalOption.amLawSegment!= row.amLawSegment) ||
    (columnName == 'lawyerSegment' && originalOption.lawyerSegment!= row.lawyerSegment) ||
    (columnName == 'officeSegment' && originalOption.officeSegment!= row.officeSegment)){
      row.dataProcessing.amLawSegment  = columnName == 'amLawSegment' ? true : false;
      row.dataProcessing.lawyerSegment  = columnName == 'lawyerSegment' ? true : false;
      row.dataProcessing.officeSegment  = columnName == 'officeSegment' ? true : false;

    // if(cell && cell.trim().length >0){
    let requestBodyArray : CustomSegmentRequestBody []=[];
    let requestBody = new CustomSegmentRequestBody();
    requestBody.firmId= row.firmId;
    requestBody.surveyYear = this.currentSurveyYearOfFirm;
    requestBody.amLawSegment = row.amLawSegment;
    requestBody.lawyerSegment = row.lawyerSegment;
    requestBody.officeSegment = row.officeSegment;
    requestBodyArray.push(requestBody);

    this.service.post(environment.FIClientBaseEndpoint + 'v1/staffingratio/subscription/segment',requestBodyArray ).subscribe(
      (result) => {
        this.originalAllFirmSegmentData[indx] = JSON.parse(JSON.stringify(row));
        row.dataProcessing.amLawSegment  = false;
        row.dataProcessing.lawyerSegment  = false;
        row.dataProcessing.officeSegment  = false;
        this.alerts.push({
          type: 'success',
          msg: 'Data saved successfully.',
          timeout: 2500,
          closeable: true,
        });
      },

      (error) => {
        row.amLawSegment = originalOption.amLawSegment;
        row.lawyerSegment = originalOption.lawyerSegment;
        row.officeSegment = originalOption.officeSegment;
        row.dataProcessing.amLawSegment  = false;
        row.dataProcessing.lawyerSegment  = false;
        row.dataProcessing.officeSegment  = false;
        this.alerts.push({
          type: 'warning',
          msg: error.error,
          closeable: true,
        });
      }
    );
    // }else{
       
    //   row.amLawSegment = originalOption.amLawSegment;
    //   row.lawyerSegment = originalOption.lawyerSegment;
    //   row.officeSegment = originalOption.officeSegment;
    //   row.dataProcessing.amLawSegment  = false;
    //   row.dataProcessing.lawyerSegment  = false;
    //   row.dataProcessing.officeSegment  = false;
    //   return
    // }
  }

}
getAllSegmentData(){
  return this.service
  .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/subscriptions/'+this.firmId+'?surveyYear='+this.currentSurveyYearOfFirm, '')
  .toPromise()
  .then(
    (result) => {
      this.allFirmSegmentData = result; 
      this.allFirmSegmentData.forEach((o) => {
        o.dataProcessing = new SRDataProcessing();
      });  
      this.isDataLoading=false;      
    },
    (error) => {
      this.isDataLoading=false;
      this.alerts.push({
        type: 'warning',
        msg:error.error.message,
        closeable: true,
      });
    }
  );
}
 private get firmId(): number {
  const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
  if (backendTokenClaims && backendTokenClaims.userDDO) {
    return backendTokenClaims.userDDO.firmID;
  }
}

getCurrentSurveyYear() {
  return this.service
    .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/surveyyear/'+this.firmId, '')
    .toPromise()
    .then(
      (result) => {
        this.currentSurveyYearOfFirm = result;      
      },
      (error) => {
        this.alerts.push({
          type: 'warning',
          msg:error.error.message,
          closeable: true,
        });
      }
    );
}

}
