import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RatesReportRequest} from 'src/app/core/models/rates-report-request.model';
import {RatesReportModal} from 'src/app/core/models/rates-report.model';

@Component({
  selector: 'app-reports-tab',
  templateUrl: './reports-tab.component.html',
  styleUrls: ['./reports-tab.component.scss'],
})
export class ReportsTabComponent implements OnInit {
  tabData: any[] = ['Rates reports', 'Reports in progress'];
  isMainBusyLoaderBusy = false;
  activeTab = 'Rates reports';

  constructor() {}

  ngOnInit(): void {}

  onTabChange(tabName) {
    if (!this.tabData.includes(tabName)) return;
    this.activeTab = tabName;
  }
}
