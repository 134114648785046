export const mainOfficeCountryValues = [
  {value: 'United States', label: 'United States'},
  {value: 'Australia', label: 'Australia'},
  {value: 'United Kingdom', label: 'United Kingdom'},
  {value: 'Canada', label: 'Canada'},
];

export const mainOfficeCountryFullName = [
  {countryCode: 'US', countryDescription: 'United States'},
  {countryCode: 'AU', countryDescription: 'Australia'},
  {countryCode: 'UK', countryDescription: 'United Kingdom'},
  {countryCode: 'CA', countryDescription: 'Canada'},
  {countryCode: 'NZ', countryDescription: 'New Zealand'},
];

export const defaultAccessFullName = [
  {countryCode: 'US', countryDescription: 'United States'},
  {countryCode: 'AU', countryDescription: 'Australia'},
  {countryCode: 'UK', countryDescription: 'United Kingdom'},
  {countryCode: 'CAN', countryDescription: 'Canada'},
  {countryCode: 'NZ', countryDescription: 'New Zealand'},
];

export const types = [
  {status: 1, name: 'Completed'},
  {status: 2, name: 'Completed'},
  {status: 3, name: 'In complete'},
];
export const statusValues = [
  {value: 1, label: 'Not started'},
  {value: 2, label: 'In Progress (2)'},
  {value: 3, label: 'Completed'},
  {value: 4, label: 'In Progress (4)'},
];
export const currencyValues = [
  {value: 'GBP', label: 'British Pounds'},
  {value: 'USD', label: 'US Dollars'},
  {value: 'AUD', label: 'Australian Dollars'},
  {value: 'CAD', label: 'Canadian Dollars'},
  {value: 'EUR', label: 'Euros'},
];
export const peerGroupValues = [
  {value: 'T', label: 'Exclude'},
  {value: 'F', label: 'Include'},
];
export const performancePeerGroupValues = [
  {value: 'N', label: 'Exclude'},
  {value: 'Y', label: 'Include'},
];

export const peerGroupsAllowesValues = [
  {value: false, label: 'No'},
  {value: true, label: 'Yes'},
];
export const firmTypeValues = [
  {value: '1', label: 'Elite Enterprise'},
  {value: '3', label: 'Aderant'},
  {value: '4', label: 'Elite 3E'},
  {value: '5', label: '3E Cloud'},
  {value: '9', label: 'Generic'},
];

export const standarRateSourceValues = [
  {value: 'accrate', label: 'accrate'},
  {value: 'timekeep', label: 'timekeep'},
  {value: 'timerate', label: 'timerate'},
];

export const aderantRateSourceValues = [
  {value: 'Std Amt / Base Amt', label: 'Standard amount / base amount'},
  {value: 'Std Amt / toBill Amt', label: 'Standard amount / to bill amount'},
  {value: 'Base Amt / ToBill Amt', label: 'Base amount / to bill amount'},
  {value: 'Rate Level / Base Amt', label: 'Rate Level / base amount'},
  {value: 'User defined rate source', label: 'User defined rate source'},
];

export const gradeSourceValues = [
  {value: 'C', label: 'Column'},
  {value: 'L', label: 'Load'},
  {value: 'U', label: 'User-Defined'},
];

export const gradeYearNameValues = [
  {value: 'tkgrdate', label: 'tkgrdate'},
  {value: 'tkbrdate', label: 'tkbrdate'},
];

export const yearTypeValues = [
  {value: 'D', label: 'Date'},
  {value: 'Y', label: 'Year'},
];

export const glLedgerValues = [
  {dataCode: 'C', dataName: 'Cash'},
  {dataCode: 'A', dataName: 'Accrual'},
];

export const glDeptSourceValues = [
  {dataCode: 'GLDepartment', dataName: 'GL Department'},
  {dataCode: 'GLSection', dataName: 'GL Section'},
];

export const glLedger3EValues = [
  {dataCode: 'C', dataName: 'Cash'},
  {dataCode: 'A', dataName: 'Accrual'},
];

export const titleSourceValues = [
  {value: 'RANK_CODE', label: 'RANK_CODE'},
  {value: 'PERSNL_TYP_CODE', label: 'PERSNL_TYP_CODE'},
];

export const gradYearName3EValues = [
  {value: 'Timekeeper.JDDate', label: 'Timekeeper.JDDate'},
  {value: 'Timekeeper.CompDate', label: 'Timekeeper.CompDate'},
  {value: 'Timekeeper.RateYear', label: 'Timekeeper.RateYear'},
  {value: 'TkprDate.HireDate', label: 'TkprDate.HireDate'},
  {value: 'TkprSchool.GradDate', label: 'TkprSchool.GradDate'},
];

export const UDF_FIELD_NAME_VALUES = [
  {value: 'LOCATION_CODE', label: 'LOCATION_CODE'},
  {value: 'PERSNL_TYP_CODE', label: 'PERSNL_TYP_CODE'},
  {value: 'WORK_TYPE_CODE', label: 'WORK_TYPE_CODE'},
  {value: 'OTHER', label: 'OTHER'},
];

export const PracticeGroupComponentOptions = [
  {name: 'Department', selected: false, id: 1},
  {name: 'Section', selected: false, id: 2},
  {name: 'Office', selected: false, id: 3},
  {name: 'User-defined', selected: false, id: 4},
];

export const aderantGradeYearNameValues = [
  {value: 'GRAD_YEAR', label: 'GRAD_YEAR'},
  {value: 'COMP_YEAR', label: 'COMP_YEAR'},
];
export const aderatnGLLedgerValues = [
  {dataCode: '1', dataName: '1'},
  {dataCode: '2', dataName: '2'},
];

export const elite3EGLValue = [
  {id: 5721, firmId: 907, dataCode: 'Accrual', dataName: 'Accrual'},
  {id: 5722, firmId: 907, dataCode: 'Cash', dataName: 'Cash'},
];

export const wijmoLocalLicenceKey = {
  /* eslint-disable max-len */
  key: 'Thomson Reuters*,815418578922576#B0P9ijIEJCLi4TPBFnU7IDUTtmMBxUcrskMV3iT0hWT6gEevYzRIRGRtV4bChGeCdUZrNnW7smWyIHOkhzYCFjcI9ENBxkamB7MwJGSltSZo9Ue6sUcwlGSml4b6M4Mshke996KzgzUxZGayUEakV4ZrwUbvFVZLZDTKZlNE94ZDhFRsl6Kyh7awNlcvVEN8glRv2CaNZjZoRGexU6cvJlY4cjTl34azRnUil7U68WZThHU8FHRWJzNCh4cwMja4EjeBlnYEFTNMVzLvpETYVGZp3mbZVjUkRWNalEVKR5Ux26YPNEOmlWeC9kN6JHcmpVQCNVRmhmb0dkdId4TsBjUvEzV7E5VMZnUSJFZIVUYwETWv3UcNRkVvlERnRDTl5mQyRnWZhWW8h6TC3kexJlctZmMn5mN7YFMVF5MXZXUxVFZUlGbH5Wb5MnS9JlUHhHSu3UTykXU9N6TjZWdPt6YiojITJCLiEzQ5QUN5IkNiojIIJCL5MTNwQzMzYTO0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI6EjM4gDMgAjMyEzMyAjMiojI4J7QiwiIqMnclRXdlJFIu36ct3GaUJiOiEmTDJCLiYzN5IjM9gzN5gTM4UTM8IiOiQWSiwSfdtlOicGbmJCLiIjdzIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICfuBk',
};

export const tAdminAddUserRole = [
  {value: 'tadmin', label: 'Thomson Reuters Admin'},
  {value: 'fadmin', label: 'Firm Admin'},
  {value: 'guser', label: 'General User'},
  {value: 'noaccess', label: 'No access'},
];

export const fAdminAddUserRole = [
  {value: 'fadmin', label: 'Firm Admin'},
  {value: 'guser', label: 'General User'},
];

export const pmmUserValues = [
  {value: false, label: 'No'},
  {value: true, label: 'Yes'},
];

// Branding colors - TR Orange, TR Racing Green, TR Dark Teal**, TR Dark Sky**, TR Dark Gold**

export const colorPalette = [
  'rgba(214, 64, 0, 1)',
  'rgba(18, 48, 21, 1)',  
  'rgba(77, 178, 153, 1)',
  'rgba(8, 116, 227, 1)',
  'rgba(233, 176, 69, 1)',
];
