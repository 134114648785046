<app-global-header></app-global-header>
<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main" [bentoBusyLoader]="isGetDataLoading">
  <div class="Actions">
    <header class="Main-header">
      <div class="row">
        <div class="col">
          <h1 class="Main-heading">Extract Jobs</h1>
          <span class="sr-only" id="ExtractJobsName">Extract Jobs Grid {{ rowCount }} rows {{ colCount }} columns</span>
        </div>
      </div>
    </header>
    <div role="alert" aria-live="polite" class="notify Actions-item">
      <p class="note-info">
        Note - Press <span class="shortcut">Alt + Shift + Down Arrow </span> to see filter options for selected column.
      </p>
    </div>
  </div>
  <div class="Main-body">
    <wj-flex-grid
      #flexGrid
      class="DataGrid DataGridFlexScroll detailGrid"
      [autoGenerateColumns]="false"
      [itemsSource]="extractHistoryData"
      [isReadOnly]="true"
      [autoRowHeights]="true"
      [virtualizationThreshold]="extractHistoryData && extractHistoryData.length"
      [showMarquee]="true"
      (initialized)="gridInitialized(flexGrid)"
      headersVisibility="All"
      headersFocusability="All"
      [selectionMode]="'Cell'"
    >
      <wj-flex-grid-filter #filter (initialized)="initFilter()"></wj-flex-grid-filter>
      <wj-flex-grid-column [header]="'Firm id'" [binding]="'firmId'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Job type'" [binding]="'jobType'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Job source'" [binding]="'jobSource'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'User'" [binding]="'userFirstName'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
          <div>{{ item.userFirstName }} {{ item.userLastName }}</div>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Email'" [binding]="'userEmail'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Job id'" [binding]="'jobId'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Calendar period'" [binding]="'calendarPeriod'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Extract date'" [binding]="'extractDate'"> </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Start time'" [binding]="'jobStartTimestamp'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'End time'" [binding]="'jobEndTimestamp'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Status'" [binding]="'jobStatus'"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Region'" [binding]="'zipFileRegion'"></wj-flex-grid-column>

      <ng-template wjFlexGridDetail #flexGridDetails let-item="item">
        <div class="extractDiv">
          <wj-flex-grid
            #detailsFlexGrid
            (initialized)="gridDetailsInitialized(detailsFlexGrid, true)"
            [itemsSource]="item.stepData"
            [isReadOnly]="true"
            [headersVisibility]="'Column'"
            [autoGenerateColumns]="false"
            [autoRowHeights]="true"
            class="extractGrid"
            [showMarquee]="true"
            [selectionMode]="'Cell'"
          >
            <wj-flex-grid-column
              [align]="'left'"
              [header]="'Step'"
              [binding]="'message'"
              [width]="400"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [align]="'left'"
              [header]="'Start time'"
              [binding]="'startTimestamp'"
              [width]="200"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [align]="'left'"
              [header]="'End time'"
              [binding]="'endTimestamp'"
              [width]="200"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [align]="'left'"
              [header]="'Status'"
              [binding]="'status'"
              [width]="200"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [align]="'left'"
              [header]="'Message'"
              [binding]="'errorMessage'"
              [wordWrap]="true"
              [width]="'*'"
              [minWidth]="600"
            ></wj-flex-grid-column>
          </wj-flex-grid>
        </div>
      </ng-template>
    </wj-flex-grid>
  </div>
</main>
