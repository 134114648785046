import {Component, OnInit, ViewChild} from '@angular/core';
import {BentoAlertItemOptions, BentoModalConfirmationService, BentoSideNavItem} from '@bento/bento-ng';
import {BentoSideNavTemplateContext} from '@bento/bento-ng/lib/side-nav/side-nav.component';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {PeergroupService} from 'src/app/core/services/peergroup/peergroup.service';
import {Role} from 'src/app/shared/enums';
import * as wjcCore from '@grapecity/wijmo';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';
import {FirmPeerGroupList} from 'src/app/core/models/firmpeergroupstatus.model';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';

@Component({
  selector: 'app-firm-peer-groups',
  templateUrl: './firm-peer-groups.component.html',
})
export class FirmPeerGroupsComponent implements OnInit {
  approved = true;
  global = false;
  pending = false;
  currentStatus = 'Approved';
  firmsPeerGroups: FirmPeerGroupList[] = [];
  gridData: wjcCore.CollectionView;
  isDataLoading = false;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  firmPeerGroupList: FirmPeerGroupList[];
  numberOfPendingPeerGroup = 0;
  gridsSelectedRow = 0;
  gridsSelectedCol = 0;
  lastPressedKey: any;
  templatePeergroupLink = CellMaker.makeLink({
    text: '${item.peerlabel}',
    label: 'Link: ${item.peerlabel}',
    click: (e, ctx) => this.updateServiceWith(ctx.item),
    attributes: {
      tabIndex: -1,
    },
  });

  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  @ViewChild('itemTemplate', {static: true}) itemTemplateTS: BentoSideNavTemplateContext;

  items: BentoSideNavItem[] = [
    {
      label: 'Approved',
      selected: true,
      action: () => {
        this.approvedSection();
      },
    },
    {
      label: 'Global',
      selected: false,
      action: () => {
        this.globalSection();
      },
    },
    {
      label: 'Pending',
      selected: false,
      action: () => {
        this.pendingSection();
      },
    },
  ];
  constructor(
    private authService: AuthService,
    private peerGroupService: PeergroupService,
    private service: BaseService,
    private route: ActivatedRoute,
    private modalService: BentoModalConfirmationService
  ) {}

  ngOnInit(): void {
    let currentStatus = this.peerGroupService.getCurrentStatus;
    if (currentStatus != null && currentStatus != '') {
      this.items.forEach((obj) => {
        if (obj.label == currentStatus) {
          obj.selected = true;
          if (currentStatus == 'Approved') {
            this.approvedSection();
          } else if (currentStatus == 'Global') {
            this.globalSection();
          } else if (currentStatus == 'Pending') {
            this.pendingSection();
          }
        } else {
          obj.selected = false;
        }
      });
    }
    this.getPeerGroups();
  }

  approvedSection() {
    this.currentStatus = 'Approved';
    this.approved = true;
    this.global = false;
    this.pending = false;
    this.selectedStatusChanged(this.currentStatus);
  }
  globalSection() {
    this.currentStatus = 'Global';
    this.global = true;
    this.approved = false;
    this.pending = false;
    this.selectedStatusChanged(this.currentStatus);
  }
  pendingSection() {
    this.currentStatus = 'Pending';
    this.pending = true;
    this.global = false;
    this.approved = false;
    this.selectedStatusChanged(this.currentStatus);
  }

  updateService(selectedOption) {
    this.peerGroupService.setCurrentStatus = this.currentStatus;
    this.peerGroupService.setselectedPeerGroup = selectedOption;
  }

  public get isFAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.FAdmin;
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : this.route.snapshot.params.id;
  }

  refreshData() {
    this.numberOfPendingPeerGroup = 0;
    this.firmsPeerGroups = this.firmPeerGroupList.filter((obj) => {
      this.numberOfPendingPeerGroup += obj.isPending ? 1 : 0;
      if (this.currentStatus == 'Approved') {
        return obj.isApproved;
      } else if (this.currentStatus == 'Global') {
        return obj.isGlobal;
      } else if (this.currentStatus == 'Pending') {
        return obj.isPending;
      }
    });
    this.gridData = new wjcCore.CollectionView(this.firmsPeerGroups);
    this.gridData.trackChanges = true;
  }

  getPeerGroups() {
    this.isDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/', this.getfirmId).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.firmPeerGroupList = result;
        this.refreshData();
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  gridInitialized(flexGrid, gridHeaderId: string = '') {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    flexGrid.rowHeaders.columns.splice(0, 1);
    flexGrid.columns.getColumn('peerid').format = 's0';

    if (gridHeaderId && flexGrid) {
      flexGrid.cells.hostElement.setAttribute('aria-labelledby', gridHeaderId);
      // flexGrid.select(-1,-1);
    }
    this.flexGrid = flexGrid;
    flexGrid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
  }
  focusOnGrid(flexGrid, event) {
    flexGrid.select(0, 0);
  }

  selectedStatusChanged(status) {
    this.getPeerGroups();
  }

  updateServiceWith(selectedOption) {
    this.peerGroupService.setIsDefaultView = false;
    this.peerGroupService.setCurrentStatus = this.currentStatus;
    this.peerGroupService.setselectedPeerGroup = selectedOption;
    this.peerGroupService.peerGroupStatusHandler();
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col;
    this.gridsSelectedRow = args.row;
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }

  handleKeyDown(flexGrid, event) {
    if (event.code === 'Space') {
      let cell = flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
      let input = cell.querySelector('a');
      input.click();
      event.preventDefault();
    }
  }
}
