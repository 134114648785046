<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main Balancing" role="main" [bentoBusyLoader]="isGetDataLoading">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <h1 class="Main-heading">Balancing</h1>
        <app-mapping-firms-details></app-mapping-firms-details>
      </div>
    </div>
  </header>
  <div class="Main-body">
    <div class="row align-items-end BalancingDetails">
      <div class="col-4">
        <div class="Form-input--medium">
          <label for="fiscalPeriod">Select data for:</label>
          <select
            id="fiscalPeriod"
            name="fiscalPeriod"
            bentoSelect
            [(ngModel)]="selectedFiscalPeriod"
            [disabled]="!(fiscalPeriods && fiscalPeriods.length)"
            (change)="onChangeFiscalPeriod()"
          >
            <option *ngFor="let item of fiscalPeriods" [ngValue]="item.period">
              {{ item.period }} {{ item.variance == true ? '(Variance)' : '' }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-8 BalancingStatus" *ngIf="isVarianceData">
        <ul class="bento-list bento-list--inline bento-list--bullets BalancingStatus-list">
          <li class="bento-list-item">Extract submitted on {{ extractDateFormat(balancigData.extractDateTime) }}</li>
          <li class="bento-list-item">
            <strong class="text-uppercase">{{
              balancigData.alreadyAuthorized == 'Y' ? 'Extract is authorized' : 'Extract is not authorized'
            }}</strong>
          </li>
        </ul>
      </div>
    </div>
    <wj-flex-grid
      #flexGrid
      class="DataGrid"
      [itemsSource]="data"
      [headersVisibility]="'Column'"
      [stickyHeaders]="true"
      [showMarquee]="true"
      [showSort]="true"
      [allowDragging]="false"
      [autoRowHeights]="true"
      (initialized)="gridInitialized(flexGrid)"
    >
      <wj-flex-grid-column
        [header]="'Billable'"
        [binding]="'indicatorFullName'"
        [minWidth]="300"
        [width]="'4*'"
        [isReadOnly]="true"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Financial Insight Reported'"
        [binding]="'extrVal'"
        [minWidth]="150"
        [width]="'2*'"
        [visible]="true"
        [isReadOnly]="true"
        [wordWrap]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-item="item">
          {{ restrictCurrencyFormat(cell.item.indicatorName, item.extrVal) }}
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Firm Reported'"
        [binding]="'prodVal'"
        [minWidth]="150"
        [width]="'2*'"
        [visible]="true"
        [isReadOnly]="true"
        [wordWrap]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-item="item">
          {{ restrictCurrencyFormat(cell.item.indicatorName, item.prodVal) }}
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column
        format="c"
        [header]="'Variance'"
        [binding]="'variance'"
        [minWidth]="100"
        [width]="'2*'"
        [visible]="true"
        [isReadOnly]="true"
        [wordWrap]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-item="item">
          <a class="" [routerLink]="['./', 'variance']" (click)="onClick(item)" *ngIf="isClickable(item)">
            {{ restrictCurrencyFormat(cell.item.indicatorName, item.variance) }}
          </a>
          <span *ngIf="!isClickable(item)">{{ restrictCurrencyFormat(cell.item.indicatorName, item.variance) }}</span>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'% Deviation'"
        [binding]="'deviation'"
        [minWidth]="100"
        [width]="'*'"
        [visible]="true"
        [isReadOnly]="true"
        [wordWrap]="true"
      >
      </wj-flex-grid-column>
    </wj-flex-grid>
    <p role="alert" aria-live="polite" class="alert alert-light" *ngIf="flexGrid.rows && flexGrid.rows.length === 0">
      {{ message }}
    </p>
  </div>
  <footer class="Main-footer">
    <div class="Actions justify-content-end">
      <div class="Actions-item">
        <button
          type="button"
          [disabled]="isDisabled || isGetDataLoading"
          [hidden]="!flag"
          class="btn btn-primary"
          (click)="authorizeExtract()"
        >
          <span class="btn-text">Authorize extract</span>
        </button>
      </div>
    </div>
  </footer>
</main>
