import {Component, OnInit, ViewChild} from '@angular/core';
import FirmBasicsModel from '../../models/firm-basics.model';
import {BackendTokenClaims} from '../../models/tokenResponse';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService} from '../../services/auth/auth.service';
import {environment} from 'src/environments/environment';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import StaffingratioFirmSettings from '../../models/staffingration-subscription-settings.model';
import * as wjcGrid from '@grapecity/wijmo.grid';
declare let TRAAC: any;
@Component({
  selector: 'app-staffing-ratio',
  templateUrl: './staffing-ratio.component.html',
  styleUrls: ['./staffing-ratio.component.scss'],
})
export class StaffingRatioComponent implements OnInit {
  selectedFirmSRSubscriptionInfo: StaffingratioFirmSettings;
  selectedFirmId: number;
  currentSurveyYearOfFirm: any;
  isFullyDefined = false;
  isDataLoading = false;
  isContentLoaded = false;
  successMsg = '';
  errorMessage = '';
  selectedRecord = [];
  selectBinding = 'selected';
  customPeerGroupsData: any = [];
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;

  activeAccordianIds = ['first'];
  selectedItems: any[] = [];
  titleMappingDocLink = '';
  staffingRatioGuideLink = '';
  isQuickSightEnabled = false;
  firmBasicData = new FirmBasicsModel();
  alerts: BentoAlertItemOptions[] = [];

  constructor(
    private authService: AuthService,
    private service: BaseService,
    public modalService: NgbModal,
    private featureToggleService: FeatureToggleService
  ) {
    this.isDataLoading = true;
    this.selectedFirmId = this.getFirmId;
  }

  async ngOnInit(): Promise<void> {
    this.isDataLoading = true;
    //this.customPeerGroupsData.pageSize = this.pageInfo.pageSize;
    await this.getCurrentSurveyYear();
    await this.getFirmSubscriptionInfo();
    await this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    this.setupLabelsAndManageFlags();
    if (this.isQuickSightEnabled) {
      this.trackStaffingRatioClickEvent();
    }
    this.isContentLoaded = true;
    this.isDataLoading = false;

    //Tabbing of disabled nav item - remove if not required
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        const selector = '.bento-tabset .nav-link.disabled';
        const elements = document.querySelectorAll(selector);

        elements.forEach((element) => {
          element.removeAttribute('tabindex');
        });
      });
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
    //Tabbing of disabled nav item - remove if not required
  }
  setupLabelsAndManageFlags() {
    if (this.selectedFirmSRSubscriptionInfo.reportReady) {
      this.activeAccordianIds = ['third'];
    }
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims && backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  trackStaffingRatioClickEvent() {
    /**Remove the call to feature API ***** */
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Staffing Ratio click', {email: this.authService.getUserEmail()}).build());
    }
  }
  loaderActionHandler(loading: boolean) {
    if (loading) this.isDataLoading = true;
    else this.isDataLoading = false;
  }

  getCurrentSurveyYear() {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/surveyyear/' + this.selectedFirmId, '')
      .toPromise()
      .then(
        (result) => {
          this.currentSurveyYearOfFirm = result;
        },
        (error) => {
          let erMsg = 'Something went wrong. Please try again.';
          if (error && error.error && error.error.message) {
            erMsg = error.error.message;
          }
          this.alerts.push({
            type: 'warning',
            msg: erMsg,
            closeable: true,
          });
        }
      );
  }

  getFirmSubscriptionInfo() {
    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/subscription/' +
          this.selectedFirmId +
          '?surveyYear=' +
          this.currentSurveyYearOfFirm,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.selectedFirmSRSubscriptionInfo = result;
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
        }
      );
  }
  private get getFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO.firmID;
  }
}
