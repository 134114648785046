<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main" [bentoBusyLoader]="isDataLoading">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <h1 class="Main-heading">Data Manager</h1>
        <span class="sr-only" id="DataManagerName"
        >Data Manager Grid {{ rowCount }} rows {{ colCount }} columns</span
          >
      </div>
    </div>   
  <div class="row">
    <div class="col">
      <div class="form-group dm-combo">
        <label for="basic-combobox"> Select a year </label>
        <bento-combobox
          id="basic-combobox"
          [(ngModel)]="selectedYear"
          [itemsObservable]="listOfYearStream"
          [options]="comboboxOptions"
          (ngModelChange)="yearSelectionChange()"
          [attr.aria-controls]="'basic-combobox'"
          (keydown)="handleComboboxKeydown($event)"
        ></bento-combobox>
      </div>
    </div>
      <div class="col ">
        <div role="alert" aria-live="polite" class="notify">       
          <p class="note-info">Note - Press <span class="shortcut">Alt + Shift + Down Arrow </span>
            to see filter options for selected column on the grid.
          </p>
        </div>
      </div>
  </div>
    
  
  </header>
  <div class="Main-body" *ngIf="proccessedDataManager && proccessedDataManager.processedData">
    <wj-flex-grid
      #flexGrid
      class="DataGrid"
      (loadedRows)="loadRows(flexGrid, $event)"
      (rowEditEnded)="rowEditEnded(flexGrid, $event)"
      [class.list-grid]="true"
      [itemsSource]="data"
      [stickyHeaders]="true"
      [showMarquee]="true"
      [autoColHeights]="true"
      [alternatingRowStep]="0"
      [validateEdits]="false"
      [headersVisibility]="'Column'"
      [autoGenerateColumns]="false"
      (initialized)="gridInitialized(flexGrid)"
    >
      <wj-flex-grid-column [isReadOnly]="true" binding="month" [width]="'*'" header="Release month" [isRequired]="false">
      </wj-flex-grid-column>
      <ng-container *ngFor="let col of proccessedDataManager.processedColumn">
        <wj-flex-grid-column
          [editor]="theInputDate"
          [binding]="col.binding"
          format="MM/dd/yyyy"
          [header]="col.header"
          [width]="'*'"
          [isRequired]="true"
        >
        </wj-flex-grid-column>
      </ng-container>
    </wj-flex-grid>
    <wj-input-date
      #theInputDate
      format="MM/dd/yyyy"
      [isRequired]="true"
      (isDroppedDownChanged)="dropDownChanged(theInputDate, this.flexGrid.selectedItems)"
    >
    </wj-input-date>
  </div>
  <!-- <footer class="Main-footer">
    <div class="Actions justify-content-end">
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" [disabled]="true" (click)="resetData()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="!isDataModified || errorRows.length > 0"
          (click)="saveData()"
        >
          <span class="btn-text">Save</span>
        </button>
      </div>
    </div>
  </footer> -->
</main>
