<bento-alert [items]="alerts"></bento-alert>
<main *ngIf="showNewFilter" id="MainContent" tabindex="-1" class="Main" role="main">
  <div class="Main-body FilterPanel FilterPanel--toggle" [ngClass]="{'is-open': isFilterPanelOpen}">
    <aside class="FilterPanel-control" [bentoBusyLoader]="isInputPanelDataLoading" role="region">
      <form name="createChart" *ngIf="firmBasicData" #createChart="ngForm" bfmForm>
        <div class="">
          <header class="FilterPanel-header">
            <h2 class="h3 CreatView-FilterPanel-heading">
              <ng-container *ngIf="isCopyEditRequest"> Edit visualization </ng-container>
              <ng-container *ngIf="!isCopyEditRequest"> New visualization </ng-container>
            </h2>
            <button
              *ngIf="filterPanelFlag"
              type="button"
              class="btn btn-outline-secondary btn-icon btn-sm"
              [attr.aria-label]="isFilterPanelOpen ? 'Close filter panel' : 'Open filter panel'"
              [ngbTooltip]="isFilterPanelOpen ? 'Close filter panel' : 'Open filter panel'"
              placement="{{ isFilterPanelOpen ? 'left' : 'right' }}"
              (click)="onClickFilter(true)"
            >
              <i
                [ngClass]="isFilterPanelOpen ? 'bento-icon-double-arrow-left' : 'bento-icon-double-arrow-right'"
                aria-hidden="true"
              ></i>
            </button>
          </header>
          <p class="FilterPanel-description">
            Edit from the criteria below; upon selection, the data to the right will auto-populate.
          </p>
          <!-- dgpg -->
          <div class="FilterPanel-body" *ngIf="createChartModel.templateKey.toLowerCase() == visualizationTypes.DPA">
            <div class="Actions chart-req-reset-sec">
              <span class="req-note label-required-summary" role="alert" aria-live="polite">Required field</span>
              <button id="cc-resetBtn-DPA" (click)="resetSelection()" class="chart-reset-button resetButton">
                <i class="bento-icon-rotate-left mr-6"></i>
                <span>Reset</span>
              </button>
            </div>
            <h3 class="axis mb-0" id="vertax-head">Vertical Axis</h3>
            <!-- <div class="form-group Form-group">
            <label class="bento-label-required" for="chartName"> Name </label>
            <input
              bfmField
              bfmLabel="Chart name"
              class="bento-input form-control"
              id="chartName"
              name="chartName"
              [(ngModel)]="createChartModel.name"
              (ngModelChange)="verifyReadyToSave(false)"
              [disabled]="isDataLoading"
              required
              type="text"
            />
            <span class="sr-only" aria-hidden="true" id="chartName-tip" role="tooltip">Chart name</span>
          </div> -->

            <div class="form-group Form-group">
              <label class="bento-label-required" for="dateRangeType" id="dateRangeTypeLabel">Date range type</label>
              <bento-combobox
                [attr.id]="'dateRangeType'"
                name="dateRangeType"
                [(ngModel)]="createChartModel.selectedDateRangeType"
                (ngModelChange)="handleDateRangeType()"
                [itemsObservable]="dateRangeTypeStream"
                [options]="comboboxOptions"
                [rowTemplate]="dateRangeTemplate"
                [placeholder]="'Select date range type'"
                [disabled]="isDataLoading"
                aria-required="true"
                aria-labelledby="vertax-head dateRangeTypeLabel"
                required
                [attr.aria-controls]="'dateRangeType'"
              ></bento-combobox>
              <ng-template #dateRangeTemplate let-$data="data" let-$options="options" let-$index="index">
                <span>{{ $data.row.name }}</span>
              </ng-template>
            </div>

            <div *ngIf="createChartModel.selectedDateRangeType">
              <app-date-range
                #dateRange
                *ngIf="createChartModel.selectedDateRangeType.id == 1"
                [id]="'endDateRange'"
                [name]="'endDateRange'"
                [createChartModel]="createChartModel"
                [dateRangeField]="createChartModel.dateRange.startDateRange"
                [shouldShowMonth]="createChartModel.selectedDateRangeType.id == 1"
                [maxYears]="createChartSelectionRules.dateRange.maxCount"
                (userActionPerformed)="verifyReadyToSave()"
                [disabled]="isDataLoading"
                [yyyymm]="firmBasicData.yyyymm"
                [ariaFieldHeadingId]="'vertax-head'"
              >
              </app-date-range>

              <div *ngIf="createChartModel.selectedDateRangeType.id == 2">
                <app-date-range
                  #dateRange
                  [id]="'customDateRange'"
                  [name]="'customDateRange'"
                  [createChartModel]="createChartModel"
                  [isCustomRange]="true"
                  [maxYears]="createChartSelectionRules.dateRange.maxCount"
                  (userActionPerformed)="verifyReadyToSave()"
                  [disabled]="isDataLoading"
                  [yyyymm]="firmBasicData.yyyymm"
                  [ariaFieldHeadingId]="'vertax-head'"
                >
                </app-date-range>
              </div>
            </div>

            <h3 class="axis mb-0 mt-30" id="hrax-head">Horizontal Axis</h3>
            <div class="form-group Form-group" *ngIf="createChartSelectionRules.practiceGroup.displayOnUi">
              <label
                [class]="createChartSelectionRules.practiceGroup.minCount > 0 ? 'bento-label-required' : ''"
                for="practiceGroup"
                id="practiceGroupLabel"
                >Practice group</label
              >
              <div class="hint-text" id="hrax-hint-text">
                Select up to {{ createChartSelectionRules.practiceGroup.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                bfmField
                name="practiceGroup"
                #practiceGroup
                id="practiceGroup"
                class="FormMultiselect FormMultiselect--extendSmall"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPracticeGroups"
                (ngModelChange)="practiceGroupOnItemsSelected($event)"
                [itemsObservable]="practiceGroupsStream"
                [editButtonText]="createChartModel.selectedPracticeGroupsText"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.practiceGroup.maxCount"
                [disabled]="(disableDropdowns && createChartModel.templateKey != 'dgpg-v1') || isDataLoading"
                aria-labelledby="practiceGroupLabel"
                bfmLabel="minimum of two practice groups"
                [isPracticeGroup]="true"
                (fieldValidate)="isValidMultiselect($event)"
                ngDefaultControl
                [ariaLabelValue]="'practice group'"
                [ariaFieldHeadingId]="'hrax-head'"
                [ariaFieldHintTextId]="'hrax-hint-text'"
                required
                [ariaRequired]="true"
              ></app-bento-custom-multiselect-overlay>
            </div>

            <h3 class="axis mb-0 mt-30" id="bar-head">Bar Data</h3>

            <div class="form-group Form-group" *ngIf="createChartSelectionRules.peerGroup.displayOnUi">
              <label class="bento-label-required" for="peerGroup" id="peerGroupLabel">Peer group</label>
              <div class="hint-text" id="bar-head-hint-text">
                Select up to {{ createChartSelectionRules.peerGroup.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                bfmField
                name="peerGroup"
                #peerGroup
                id="peerGroup"
                class="FormMultiselect"
                bfmLabel="Peer group"
                ngDefaultControl
                required
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPeerGroups"
                (ngModelChange)="peerGroupOnItemsSelected($event)"
                [itemsObservable]="peerGroupsStream"
                [editButtonText]="createChartModel.selectedPeerGroupsText"
                [maxSelectedItems]="createChartSelectionRules.peerGroup.maxCount"
                aria-labelledby="peerGroupLabel"
                [ariaLabelValue]="'peer group'"
                [disabled]="isDataLoading"
                [ariaFieldHeadingId]="'bar-head'"
                [ariaFieldHintTextId]="'bar-head-hint-text'"
                [ariaRequired]="true"
              >
              </app-bento-custom-multiselect-overlay>
            </div>

            <h3 class="axis mb-0 mt-30" id="filter-head">Filters</h3>

            <div class="form-group Form-group" *ngIf="createChartSelectionRules.offices.displayOnUi">
              <label for="office" id="officesLabel">Office</label>
              <div *ngIf="createChartSelectionRules.offices.maxCount" class="hint-text" id="office-hint-text">
                Select up to {{ createChartSelectionRules.offices.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="office"
                #office
                id="office"
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedOffices"
                (ngModelChange)="officeOnItemsSelected($event)"
                [itemsObservable]="officesStream"
                [editButtonText]="createChartModel.selectedOfficesText"
                [disabled]="disableDropdowns || isDataLoading"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.offices.maxCount"
                aria-labelledby="officesLabel"
                [ariaLabelValue]="'office'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'office-hint-text'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div class="form-group Form-group" *ngIf="createChartSelectionRules.titles.displayOnUi">
              <label for="title" id="titlesLabel">Title</label>
              <div *ngIf="createChartSelectionRules.titles.maxCount" class="title-hint-text">
                Select up to {{ createChartSelectionRules.titles.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="title"
                id="title"
                #title
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedTitles"
                (ngModelChange)="titleOnItemsSelected($event)"
                [itemsObservable]="titlesStream"
                [editButtonText]="createChartModel.selectedTitlesText"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.titles.maxCount"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="titlesLabel"
                [ariaLabelValue]="'title'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'title-hint-text'"
              ></app-bento-custom-multiselect-overlay>
            </div>

            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isAssociateYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="associate" id="associateExpLabel">Associate experience</label>
              <app-bento-custom-multiselect-overlay
                name="associate"
                id="associate"
                #associateYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedAssociateYears"
                (ngModelChange)="associateYearOnItemsSelected($event)"
                [itemsObservable]="associateYearsStream"
                [editButtonText]="createChartModel.selectedAssociateYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="associateExpLabel"
                [ariaLabelValue]="'associate experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isSrAssociateYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="srAssociateYear" id="srAssociateExpLabel">Sr Associate experience</label>
              <app-bento-custom-multiselect-overlay
                name="srAssociateYear"
                id="srAssociateYear"
                #srAssociateYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedSrAssociateYears"
                (ngModelChange)="srAssociateYearOnItemsSelected($event)"
                [itemsObservable]="srAssociateYearsStream"
                [editButtonText]="createChartModel.selectedSrAssociateYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="srAssociateExpLabel"
                [ariaLabelValue]="'sr associate experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isPartnerYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="partnerYear" id="partnerExpLabel">Partner experience</label>
              <app-bento-custom-multiselect-overlay
                name="partnerYear"
                id="partnerYear"
                #partnerYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPartnerYears"
                (ngModelChange)="partnerYearOnItemsSelected($event)"
                [itemsObservable]="partnerYearsStream"
                [editButtonText]="createChartModel.selectedPartnerYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="partnerExpLabel"
                [ariaLabelValue]="'partner experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div class="form-group Form-group">
              <div class="bento-form-buttons">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  [disabled]="isDataLoading"
                  (click)="backToCurrentVisualization()"
                >
                  <span class="btn-text">Cancel</span>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveChart()"
                  [disabled]="isDataLoading || !isReadyToSave"
                >
                  <span class="btn-text">Save</span>
                </button>
              </div>
            </div>
          </div>
          <!-- kpm static -->
          <div
            class="FilterPanel-body"
            *ngIf="
              createChartSelectionRules.metrics.displayOnUi == false &&
              createChartModel.templateKey.toLowerCase() == visualizationTypes.KPMV1
            "
          >
            <div class="Actions chart-req-reset-sec">
              <span class="req-note label-required-summary" role="alert" aria-live="polite">Required field</span>
              <button id="cc-resetBtn-KPM" (click)="resetSelection()" class="chart-reset-button resetButton">
                <i class="bento-icon-rotate-left mr-6"></i>
                <span>Reset</span>
              </button>
            </div>
            <h3 class="axis mb-0" id="vertax-head">Vertical Axis</h3>
            <!-- <div class="form-group Form-group">
            <label class="bento-label-required" for="chartName"> Name </label>
            <input
              bfmField
              bfmLabel="Chart name"
              class="bento-input form-control"
              id="chartName"
              name="chartName"
              [(ngModel)]="createChartModel.name"
              (ngModelChange)="verifyReadyToSave(false)"
              [disabled]="isDataLoading"
              required
              type="text"
            />
            <span class="sr-only" aria-hidden="true" id="chartName-tip" role="tooltip">Chart name</span>
          </div> -->

            <div class="form-group Form-group">
              <label class="bento-label-required" for="dateRangeType" id="dateRangeTypeLabel">Date range type</label>
              <bento-combobox
                [attr.id]="'dateRangeType'"
                name="dateRangeType"
                [(ngModel)]="createChartModel.selectedDateRangeType"
                (ngModelChange)="handleDateRangeType()"
                [itemsObservable]="dateRangeTypeStream"
                [options]="comboboxOptions"
                [rowTemplate]="dateRangeTemplate"
                [placeholder]="'Select date range type'"
                [disabled]="isDataLoading"
                aria-required="true"
                aria-labelledby="vertax-head dateRangeTypeLabel"
                required
                [attr.aria-controls]="'dateRangeType'"
              ></bento-combobox>
              <ng-template #dateRangeTemplate let-$data="data" let-$options="options" let-$index="index">
                <span>{{ $data.row.name }}</span>
              </ng-template>
            </div>

            <div *ngIf="createChartModel.selectedDateRangeType">
              <app-date-range
                #dateRange
                *ngIf="createChartModel.selectedDateRangeType.id == 1"
                [id]="'endDateRange'"
                [name]="'endDateRange'"
                [createChartModel]="createChartModel"
                [dateRangeField]="createChartModel.dateRange.startDateRange"
                [shouldShowMonth]="createChartModel.selectedDateRangeType.id == 1"
                [maxYears]="createChartSelectionRules.dateRange.maxCount"
                (userActionPerformed)="verifyReadyToSave()"
                [disabled]="isDataLoading"
                [yyyymm]="firmBasicData.yyyymm"
                [ariaFieldHeadingId]="'vertax-head'"
              >
              </app-date-range>

              <div *ngIf="createChartModel.selectedDateRangeType.id == 2">
                <app-date-range
                  #dateRange
                  [id]="'customDateRange'"
                  [name]="'customDateRange'"
                  [createChartModel]="createChartModel"
                  [isCustomRange]="true"
                  [maxYears]="createChartSelectionRules.dateRange.maxCount"
                  (userActionPerformed)="verifyReadyToSave()"
                  [disabled]="isDataLoading"
                  [yyyymm]="firmBasicData.yyyymm"
                  [ariaFieldHeadingId]="'vertax-head'"
                >
                </app-date-range>
              </div>
            </div>

            <h3 class="axis mb-0 mt-30" id="bar-head">Bar Data</h3>

            <div class="form-group Form-group" *ngIf="createChartSelectionRules.peerGroup.displayOnUi">
              <label class="bento-label-required" for="peerGroup" id="peerGroupLabel">Peer group</label>
              <div class="hint-text" id="bar-head-hint-text" *ngIf="createChartSelectionRules.peerGroup.maxCount > 0">
                Select up to {{ createChartSelectionRules.peerGroup.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                bfmField
                name="peerGroup"
                #peerGroup
                id="peerGroup"
                class="FormMultiselect"
                bfmLabel="Peer group"
                ngDefaultControl
                required
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPeerGroups"
                (ngModelChange)="peerGroupOnItemsSelected($event)"
                [itemsObservable]="peerGroupsStream"
                [editButtonText]="createChartModel.selectedPeerGroupsText"
                [maxSelectedItems]="createChartSelectionRules.peerGroup.maxCount"
                aria-labelledby="peerGroupLabel"
                [ariaLabelValue]="'peer group'"
                [disabled]="isDataLoading"
                [ariaFieldHeadingId]="'bar-head'"
                [ariaFieldHintTextId]="'bar-head-hint-text'"
                [ariaRequired]="true"
              >
              </app-bento-custom-multiselect-overlay>
            </div>
            <h3 class="axis mb-0 mt-30" id="filter-head">Filters</h3>

            <div class="form-group Form-group" *ngIf="createChartSelectionRules.practiceGroup.displayOnUi">
              <label
                [class]="createChartSelectionRules.practiceGroup.minCount > 0 ? 'bento-label-required' : ''"
                for="practiceGroup"
                id="practiceGroupLabel"
                >Practice group</label
              >
              <div *ngIf="createChartSelectionRules.practiceGroup.maxCount" class="hint-text" id="practice-hint-text">
                Select up to {{ createChartSelectionRules.practiceGroup.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="practiceGroup"
                #practiceGroup
                id="practiceGroup"
                class="FormMultiselect FormMultiselect--extendSmall"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPracticeGroups"
                (ngModelChange)="practiceGroupOnItemsSelected($event)"
                [itemsObservable]="practiceGroupsStream"
                [editButtonText]="createChartModel.selectedPracticeGroupsText"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.practiceGroup.maxCount"
                [disabled]="(disableDropdowns && createChartModel.templateKey != 'dgpg-v1') || isDataLoading"
                aria-labelledby="practiceGroupLabel"
                bfmLabel="minimum of two practice groups"
                [isPracticeGroup]="true"
                (fieldValidate)="isValidMultiselect($event)"
                bfmField
                ngDefaultControl
                required
                [ariaLabelValue]="'practice group'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'practice-hint-text'"
                [ariaRequired]="createChartSelectionRules.practiceGroup.minCount > 0 ? true : false"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div class="form-group Form-group" *ngIf="createChartSelectionRules.offices.displayOnUi">
              <label for="office" id="officesLabel">Office</label>
              <div *ngIf="createChartSelectionRules.offices.maxCount" class="hint-text" id="office-hint-text">
                Select up to {{ createChartSelectionRules.offices.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="office"
                #office
                id="office"
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedOffices"
                (ngModelChange)="officeOnItemsSelected($event)"
                [itemsObservable]="officesStream"
                [editButtonText]="createChartModel.selectedOfficesText"
                [disabled]="disableDropdowns || isDataLoading"
                [isSelectAllVisible]="false"
                aria-labelledby="officesLabel"
                [maxSelectedItems]="createChartSelectionRules.offices.maxCount"
                [ariaLabelValue]="'office'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'office-hint-text'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div class="form-group Form-group" *ngIf="createChartSelectionRules.titles.displayOnUi">
              <label for="title" id="titlesLabel">Title</label>
              <div *ngIf="createChartSelectionRules.titles.maxCount" class="hint-text" id="title-hint-text">
                Select up to {{ createChartSelectionRules.titles.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="title"
                id="title"
                #title
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedTitles"
                (ngModelChange)="titleOnItemsSelected($event)"
                [itemsObservable]="titlesStream"
                [editButtonText]="createChartModel.selectedTitlesText"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.titles.maxCount"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="titlesLabel"
                [ariaLabelValue]="'title'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'title-hint-text'"
              ></app-bento-custom-multiselect-overlay>
            </div>

            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isAssociateYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="associate" id="associateExpLabel">Associate experience</label>
              <app-bento-custom-multiselect-overlay
                name="associate"
                id="associate"
                #associateYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedAssociateYears"
                (ngModelChange)="associateYearOnItemsSelected($event)"
                [itemsObservable]="associateYearsStream"
                [editButtonText]="createChartModel.selectedAssociateYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="associateExpLabel"
                [ariaLabelValue]="'associate experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isSrAssociateYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="srAssociateYear" id="srAssociateExpLabel">Sr Associate experience</label>
              <app-bento-custom-multiselect-overlay
                name="srAssociateYear"
                id="srAssociateYear"
                #srAssociateYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedSrAssociateYears"
                (ngModelChange)="srAssociateYearOnItemsSelected($event)"
                [itemsObservable]="srAssociateYearsStream"
                [editButtonText]="createChartModel.selectedSrAssociateYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="srAssociateExpLabel"
                [ariaLabelValue]="'sr associate experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isPartnerYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="partnerYear" id="partnerExpLabel">Partner experience</label>
              <app-bento-custom-multiselect-overlay
                name="partnerYear"
                id="partnerYear"
                #partnerYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPartnerYears"
                (ngModelChange)="partnerYearOnItemsSelected($event)"
                [itemsObservable]="partnerYearsStream"
                [editButtonText]="createChartModel.selectedPartnerYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="partnerExpLabel"
                [ariaLabelValue]="'partner experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div class="form-group Form-group">
              <div class="bento-form-buttons">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  [disabled]="isDataLoading"
                  (click)="backToCurrentVisualization()"
                >
                  <span class="btn-text">Cancel</span>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveChart()"
                  [disabled]="isDataLoading || !isReadyToSave"
                >
                  <span class="btn-text">Save</span>
                </button>
              </div>
            </div>
          </div>

          <!-- kpm dynamic -->
          <div
            class="FilterPanel-body"
            *ngIf="
              createChartSelectionRules.metrics.displayOnUi == true &&
              createChartModel.templateKey.toLowerCase() == visualizationTypes.KPMV1
            "
          >
            <div class="Actions chart-req-reset-sec">
              <span class="req-note label-required-summary" role="alert" aria-live="polite">Required field</span>
              <button id="cc-resetBtn-KPMD" (click)="resetSelection()" class="chart-reset-button resetButton">
                <i class="bento-icon-rotate-left mr-6"></i>
                <span>Reset</span>
              </button>
            </div>
            <h3 class="axis mb-0" id="vertax-head">Vertical Axis</h3>
            <!-- <div class="form-group Form-group">
            <label class="bento-label-required" for="chartName"> Name </label>
            <input
              bfmField
              bfmLabel="Chart name"
              class="bento-input form-control"
              id="chartName"
              name="chartName"
              [(ngModel)]="createChartModel.name"
              (ngModelChange)="verifyReadyToSave(false)"
              [disabled]="isDataLoading"
              required
              type="text"
            />
            <span class="sr-only" aria-hidden="true" id="chartName-tip" role="tooltip">Chart name</span>
          </div> -->

            <div class="form-group Form-group">
              <label class="bento-label-required" for="dateRangeType" id="dateRangeTypeLabel">Date range type</label>
              <bento-combobox
                [attr.id]="'dateRangeType'"
                name="dateRangeType"
                [(ngModel)]="createChartModel.selectedDateRangeType"
                (ngModelChange)="handleDateRangeType()"
                [itemsObservable]="dateRangeTypeStream"
                [options]="comboboxOptions"
                [rowTemplate]="dateRangeTemplate"
                [placeholder]="'Select date range type'"
                [disabled]="isDataLoading"
                aria-required="true"
                aria-labelledby="vertax-head dateRangeTypeLabel"
                required
                [attr.aria-controls]="'dateRangeType'"  
              ></bento-combobox>
              <ng-template #dateRangeTemplate let-$data="data" let-$options="options" let-$index="index">
                <span>{{ $data.row.name }}</span>
              </ng-template>
            </div>

            <div *ngIf="createChartModel.selectedDateRangeType">
              <app-date-range
                #dateRange
                *ngIf="createChartModel.selectedDateRangeType.id == 1"
                [id]="'endDateRange'"
                [name]="'endDateRange'"
                [createChartModel]="createChartModel"
                [dateRangeField]="createChartModel.dateRange.startDateRange"
                [shouldShowMonth]="createChartModel.selectedDateRangeType.id == 1"
                [maxYears]="createChartSelectionRules.dateRange.maxCount"
                (userActionPerformed)="verifyReadyToSave()"
                [disabled]="isDataLoading"
                [yyyymm]="firmBasicData.yyyymm"
                [ariaFieldHeadingId]="'vertax-head'"
              >
              </app-date-range>

              <div *ngIf="createChartModel.selectedDateRangeType.id == 2">
                <app-date-range
                  #dateRange
                  [id]="'customDateRange'"
                  [name]="'customDateRange'"
                  [createChartModel]="createChartModel"
                  [isCustomRange]="true"
                  [maxYears]="createChartSelectionRules.dateRange.maxCount"
                  (userActionPerformed)="verifyReadyToSave()"
                  [disabled]="isDataLoading"
                  [yyyymm]="firmBasicData.yyyymm"
                  [ariaFieldHeadingId]="'vertax-head'"
                >
                </app-date-range>
              </div>
            </div>

            <h3 class="axis mb-0 mt-30" id="hrax-head">Horizontal Axis</h3>
            <div class="form-group Form-group" *ngIf="createChartSelectionRules.metrics.displayOnUi">
              <label
                [class]="createChartSelectionRules.metrics.minCount > 0 ? 'bento-label-required' : ''"
                for="metrics"
                id="metricsLabel"
                >Metrics</label
              >
              <div *ngIf="createChartSelectionRules.metrics.maxCount" class="hint-text" id="hrax-hint-text">
                Select up to {{ createChartSelectionRules.metrics.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="metrics"
                #metrics
                id="metrics"
                class="FormMultiselect FormMultiselect--extend"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedMetrics"
                (ngModelChange)="metricsOnItemsSelected($event)"
                [itemsObservable]="metricsStream"
                [editButtonText]="createChartModel.selectedMetricsText"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.metrics.maxCount"
                [disabled]="isDataLoading"
                aria-labelledby="metricsLabel"
                bfmLabel="metrics"
                bfmField
                ngDefaultControl
                [required]="createChartSelectionRules.metrics.minCount > 0"
                [ariaLabelValue]="'metrics'"
                [ariaFieldHeadingId]="'hrax-head'"
                [ariaFieldHintTextId]="'hrax-hint-text'"
                [ariaRequired]="true"
              ></app-bento-custom-multiselect-overlay>
            </div>

            <h3 class="axis mb-0 mt-30" id="bar-head">Bar Data</h3>

            <div class="form-group Form-group" *ngIf="createChartSelectionRules.peerGroup.displayOnUi">
              <label class="bento-label-required" for="peerGroup" id="peerGroupLabel">Peer group</label>
              <div *ngIf="createChartSelectionRules.peerGroup.maxCount" class="hint-text" id="bar-head-hint-text">
                Select up to {{ createChartSelectionRules.peerGroup.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="peerGroup"
                #peerGroup
                id="peerGroup"
                class="FormMultiselect"
                bfmLabel="Peer group"
                bfmField
                ngDefaultControl
                required
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPeerGroups"
                (ngModelChange)="peerGroupOnItemsSelected($event)"
                [itemsObservable]="peerGroupsStream"
                [editButtonText]="createChartModel.selectedPeerGroupsText"
                [maxSelectedItems]="createChartSelectionRules.peerGroup.maxCount"
                aria-labelledby="peerGroupLabel"
                [ariaLabelValue]="'peer group'"
                [disabled]="isDataLoading"
                [ariaFieldHeadingId]="'bar-head'"
                [ariaFieldHintTextId]="'bar-head-hint-text'"
                [ariaRequired]="true"
              >
              </app-bento-custom-multiselect-overlay>
            </div>

            <h3 class="axis mb-0 mt-30" id="filter-head">Filters</h3>

            <div class="form-group Form-group" *ngIf="createChartSelectionRules.practiceGroup.displayOnUi">
              <label
                [class]="createChartSelectionRules.practiceGroup.minCount > 0 ? 'bento-label-required' : ''"
                for="practiceGroup"
                id="practiceGroupLabel"
                >Practice group</label
              >
              <div *ngIf="createChartSelectionRules.practiceGroup.maxCount" class="hint-text" id="practice-hint-text">
                Select up to {{ createChartSelectionRules.practiceGroup.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="practiceGroup"
                #practiceGroup
                id="practiceGroup"
                class="FormMultiselect FormMultiselect--extendSmall"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPracticeGroups"
                (ngModelChange)="practiceGroupOnItemsSelected($event)"
                [itemsObservable]="practiceGroupsStream"
                [editButtonText]="createChartModel.selectedPracticeGroupsText"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.practiceGroup.maxCount"
                [disabled]="(disableDropdowns && createChartModel.templateKey != 'dgpg-v1') || isDataLoading"
                aria-labelledby="practiceGroupLabel"
                bfmLabel="minimum of two practice groups"
                [isPracticeGroup]="true"
                (fieldValidate)="isValidMultiselect($event)"
                [ariaLabelValue]="'minimum of two practice groups'"
                bfmField
                ngDefaultControl
                required
                [ariaLabelValue]="'practice group'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'practice-hint-text'"
                [ariaRequired]="createChartSelectionRules.practiceGroup.minCount > 0 ? true : false"
              ></app-bento-custom-multiselect-overlay>
            </div>

            <div class="form-group Form-group" *ngIf="createChartSelectionRules.offices.displayOnUi">
              <label for="office" id="officesLabel">Office</label>
              <div *ngIf="createChartSelectionRules.offices.maxCount" class="hint-text" id="office-hint-text">
                Select up to {{ createChartSelectionRules.offices.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="office"
                #office
                id="office"
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedOffices"
                (ngModelChange)="officeOnItemsSelected($event)"
                [itemsObservable]="officesStream"
                [editButtonText]="createChartModel.selectedOfficesText"
                [disabled]="disableDropdowns || isDataLoading"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.offices.maxCount"
                aria-labelledby="officesLabel"
                [ariaLabelValue]="'office'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'office-hint-text'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div class="form-group Form-group" *ngIf="createChartSelectionRules.titles.displayOnUi">
              <label for="title" id="titlesLabel">Title</label>
              <div *ngIf="createChartSelectionRules.titles.maxCount" class="hint-text" id="title-hint-text">
                Select up to {{ createChartSelectionRules.titles.maxCount }}
              </div>
              <app-bento-custom-multiselect-overlay
                name="title"
                id="title"
                #title
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedTitles"
                (ngModelChange)="titleOnItemsSelected($event)"
                [itemsObservable]="titlesStream"
                [editButtonText]="createChartModel.selectedTitlesText"
                [isSelectAllVisible]="false"
                [maxSelectedItems]="createChartSelectionRules.titles.maxCount"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="titlesLabel"
                [ariaLabelValue]="'title'"
                [ariaFieldHeadingId]="'filter-head'"
                [ariaFieldHintTextId]="'title-hint-text'"
              ></app-bento-custom-multiselect-overlay>
            </div>

            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isAssociateYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="associate" id="associateExpLabel">Associate experience</label>
              <app-bento-custom-multiselect-overlay
                name="associate"
                id="associate"
                #associateYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedAssociateYears"
                (ngModelChange)="associateYearOnItemsSelected($event)"
                [itemsObservable]="associateYearsStream"
                [editButtonText]="createChartModel.selectedAssociateYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="associateExpLabel"
                [ariaLabelValue]="'associate experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isSrAssociateYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="srAssociateYear" id="srAssociateExpLabel">Sr Associate experience</label>
              <app-bento-custom-multiselect-overlay
                name="srAssociateYear"
                id="srAssociateYear"
                #srAssociateYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedSrAssociateYears"
                (ngModelChange)="srAssociateYearOnItemsSelected($event)"
                [itemsObservable]="srAssociateYearsStream"
                [editButtonText]="createChartModel.selectedSrAssociateYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="srAssociateExpLabel"
                [ariaLabelValue]="'sr associate experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div
              class="form-group Form-group Form-group--indent"
              *ngIf="isPartnerYear && createChartSelectionRules.titles.displayOnUi"
            >
              <label for="partnerYear" id="partnerExpLabel">Partner experience</label>
              <app-bento-custom-multiselect-overlay
                name="partnerYear"
                id="partnerYear"
                #partnerYear
                class="FormMultiselect"
                [overlayMaxHeight]="344"
                [ngModel]="createChartModel.selectedPartnerYears"
                (ngModelChange)="partnerYearOnItemsSelected($event)"
                [itemsObservable]="partnerYearsStream"
                [editButtonText]="createChartModel.selectedPartnerYearsText"
                [disabled]="disableDropdowns || isDataLoading"
                aria-labelledby="partnerExpLabel"
                [ariaLabelValue]="'partner experience'"
                [ariaFieldHeadingId]="'filter-head'"
              ></app-bento-custom-multiselect-overlay>
            </div>
            <div class="form-group Form-group">
              <div class="bento-form-buttons">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  [disabled]="isDataLoading"
                  (click)="backToCurrentVisualization()"
                >
                  <span class="btn-text">Cancel</span>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveChart()"
                  [disabled]="isDataLoading || !isReadyToSave"
                >
                  <span class="btn-text">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </aside>

    <div class="FilterPanel-data FilterPanel--visualization chart-container" [bentoBusyLoader]="isDataLoading">
      <div *ngIf="vizTemplateDetail">
        <app-global-breadcrumbs
          [isFirmPeerGroup]="true"
          (updateService)="backToCurrentVisualization()"
          [peerLabel]="vizTemplateDetail && (vizTemplateDetail.chartName || vizTemplateDetail.templateName)"
          [firmName]="peerLabel"
        ></app-global-breadcrumbs>
      </div>
      <app-chart-details
        #ChartDetails
        *ngIf="vizTemplateDetail"
        [selectedChartData]="selectedVizData"
        [createChartModel]="createChartModel"
        [calledForScreen]="'CreateVisualization'"
        [vizTemplateDetail]="vizTemplateDetail"
        [shouldShowSettingButton]="true"
        (dataLoadingStateChanged)="handleDataLoadingStateChanged($event)"
        (rendered)="componentRendered(ChartDetails)"
        (settingsChanged)="onSettingsChanged()"
      >
      </app-chart-details>
    </div>
  </div>
</main>

<main *ngIf="!showNewFilter" id="MainContent" tabindex="-1" class="Main" role="main">
  <div class="Main-body FilterPanel FilterPanel--toggle" [ngClass]="{'is-open': isFilterPanelOpen}">
    <aside class="FilterPanel-control" [bentoBusyLoader]="isInputPanelDataLoading" role="region">
      <form name="createChart" *ngIf="firmBasicData" #createChart="ngForm" bfmForm>
        <header class="FilterPanel-header">
          <h1 class="h3 CreatView-FilterPanel-heading">
            <ng-container *ngIf="isCopyEditRequest"> Edit visualization </ng-container>
            <ng-container *ngIf="!isCopyEditRequest"> New visualization </ng-container>
          </h1>

          <button
            *ngIf="filterPanelFlag"
            type="button"
            class="btn btn-outline-secondary btn-icon btn-sm"
            [attr.aria-label]="isFilterPanelOpen ? 'Close filter panel' : 'Open filter panel'"
            [ngbTooltip]="isFilterPanelOpen ? 'Close filter panel' : 'Open filter panel'"
            placement="{{ isFilterPanelOpen ? 'left' : 'right' }}"
            (click)="onClickFilter(true)"
          >
            <i
              [ngClass]="isFilterPanelOpen ? 'bento-icon-double-arrow-left' : 'bento-icon-double-arrow-right'"
              aria-hidden="true"
            ></i>
          </button>
        </header>
        <p class="FilterPanel-description">
          Edit from the criteria below; upon selection, the data to the right will auto-populate.
        </p>
        <div class="FilterPanel-body">
          <div class="form-group Form-group">
            <label class="bento-label-required" for="chartName"> Name </label>
            <input
              bfmField
              bfmLabel="Chart name"
              class="bento-input form-control"
              id="chartName"
              name="chartName"
              [(ngModel)]="createChartModel.name"
              (ngModelChange)="verifyReadyToSave(false)"
              [disabled]="isDataLoading"
              required
              bentoRequired
              type="text"
            />
            <span class="sr-only" aria-hidden="true" id="chartName-tip" role="tooltip">Chart name</span>
          </div>

          <div class="form-group Form-group">
            <label class="bento-label-required" for="dateRangeType" id="dateRangeTypeLabel">Date range type</label>
            <bento-combobox
              bfmField
              [attr.id]="'dateRangeType'"
              name="dateRangeType"
              [(ngModel)]="createChartModel.selectedDateRangeType"
              (ngModelChange)="handleDateRangeType()"
              [itemsObservable]="dateRangeTypeStream"
              [options]="comboboxOptions"
              [rowTemplate]="dateRangeTemplate"
              [placeholder]="'Select date range type'"
              [disabled]="isDataLoading"
              aria-required="true"
              aria-labelledby="dateRangeTypeLabel"
              required
              [attr.aria-controls]="'dateRangeType'"
            ></bento-combobox>
            <ng-template #dateRangeTemplate let-$data="data" let-$options="options" let-$index="index">
              <span>{{ $data.row.name }}</span>
            </ng-template>
          </div>

          <div *ngIf="createChartModel.selectedDateRangeType">
            <app-date-range
              #dateRange
              *ngIf="createChartModel.selectedDateRangeType.id == 1"
              [id]="'endDateRange'"
              [name]="'endDateRange'"
              [createChartModel]="createChartModel"
              [dateRangeField]="createChartModel.dateRange.startDateRange"
              [shouldShowMonth]="createChartModel.selectedDateRangeType.id == 1"
              [maxYears]="createChartSelectionRules.dateRange.maxCount"
              (userActionPerformed)="verifyReadyToSave()"
              [disabled]="isDataLoading"
              [yyyymm]="firmBasicData.yyyymm"
              [ariaFieldHeadingId]=""
            >
            </app-date-range>

            <div *ngIf="createChartModel.selectedDateRangeType.id == 2">
              <app-date-range
                #dateRange
                [id]="'customDateRange'"
                [name]="'customDateRange'"
                [createChartModel]="createChartModel"
                [isCustomRange]="true"
                [maxYears]="createChartSelectionRules.dateRange.maxCount"
                (userActionPerformed)="verifyReadyToSave()"
                [disabled]="isDataLoading"
                [yyyymm]="firmBasicData.yyyymm"
                [ariaFieldHeadingId]=""
              >
              </app-date-range>
            </div>
          </div>

          <div class="form-group Form-group" *ngIf="createChartSelectionRules.peerGroup.displayOnUi">
            <label class="bento-label-required" for="peerGroup" id="peerGroupLabel">Peer group</label>
            <div class="hint-text">Select up to {{ createChartSelectionRules.peerGroup.maxCount }}</div>
            <app-bento-custom-multiselect-overlay
              name="peerGroup"
              #peerGroup
              id="peerGroup"
              class="FormMultiselect"
              bfmLabel="Peer group"
              bfmField
              ngDefaultControl
              [overlayMaxHeight]="344"
              [ngModel]="createChartModel.selectedPeerGroups"
              (ngModelChange)="peerGroupOnItemsSelected($event)"
              [itemsObservable]="peerGroupsStream"
              [editButtonText]="createChartModel.selectedPeerGroupsText"
              [maxSelectedItems]="createChartSelectionRules.peerGroup.maxCount"
              aria-labelledby="peerGroupLabel"
              [ariaLabelValue]="'peer group'"
              [disabled]="isDataLoading"
              required
            >
            </app-bento-custom-multiselect-overlay>
          </div>

          <div class="form-group Form-group" *ngIf="createChartSelectionRules.practiceGroup.displayOnUi">
            <label
              [class]="createChartSelectionRules.practiceGroup.minCount > 0 ? 'bento-label-required' : ''"
              for="practiceGroup"
              id="practiceGroupLabel"
              >Practice group</label
            >
            <app-bento-custom-multiselect-overlay
              name="practiceGroup"
              #practiceGroup
              id="practiceGroup"
              class="FormMultiselect FormMultiselect--extendSmall"
              [overlayMaxHeight]="344"
              [ngModel]="createChartModel.selectedPracticeGroups"
              (ngModelChange)="practiceGroupOnItemsSelected($event)"
              [itemsObservable]="practiceGroupsStream"
              [editButtonText]="createChartModel.selectedPracticeGroupsText"
              [isSelectAllVisible]="false"
              [maxSelectedItems]="createChartSelectionRules.practiceGroup.maxCount"
              [disabled]="(disableDropdowns && createChartModel.templateKey != 'dgpg-v1') || isDataLoading"
              aria-labelledby="practiceGroupLabel"
              bfmLabel="minimum of two practice groups"
              [isPracticeGroup]="true"
              (fieldValidate)="isValidMultiselect($event)"
              bfmField
              ngDefaultControl
              required
              [ariaLabelValue]="'practice group'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div class="form-group Form-group" *ngIf="createChartSelectionRules.offices.displayOnUi">
            <label for="office" id="officesLabel">Office</label>
            <app-bento-custom-multiselect-overlay
              name="office"
              #office
              id="office"
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createChartModel.selectedOffices"
              (ngModelChange)="officeOnItemsSelected($event)"
              [itemsObservable]="officesStream"
              [editButtonText]="createChartModel.selectedOfficesText"
              [disabled]="disableDropdowns || isDataLoading"
              [isSelectAllVisible]="false"
              [maxSelectedItems]="createChartSelectionRules.offices.maxCount"
              aria-labelledby="officesLabel"
              [ariaLabelValue]="'office'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div class="form-group Form-group" *ngIf="createChartSelectionRules.titles.displayOnUi">
            <label for="title" id="titlesLabel">Title</label>
            <app-bento-custom-multiselect-overlay
              name="title"
              id="title"
              #title
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createChartModel.selectedTitles"
              (ngModelChange)="titleOnItemsSelected($event)"
              [itemsObservable]="titlesStream"
              [editButtonText]="createChartModel.selectedTitlesText"
              [isSelectAllVisible]="false"
              [maxSelectedItems]="createChartSelectionRules.titles.maxCount"
              [disabled]="disableDropdowns || isDataLoading"
              aria-labelledby="titlesLabel"
              [ariaLabelValue]="'title'"
            ></app-bento-custom-multiselect-overlay>
          </div>

          <div
            class="form-group Form-group Form-group--indent"
            *ngIf="isAssociateYear && createChartSelectionRules.titles.displayOnUi"
          >
            <label for="associate" id="associateExpLabel">Associate experience</label>
            <app-bento-custom-multiselect-overlay
              name="associate"
              id="associate"
              #associateYear
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createChartModel.selectedAssociateYears"
              (ngModelChange)="associateYearOnItemsSelected($event)"
              [itemsObservable]="associateYearsStream"
              [editButtonText]="createChartModel.selectedAssociateYearsText"
              [disabled]="disableDropdowns || isDataLoading"
              aria-labelledby="associateExpLabel"
              [ariaLabelValue]="'associate experience'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div
            class="form-group Form-group Form-group--indent"
            *ngIf="isSrAssociateYear && createChartSelectionRules.titles.displayOnUi"
          >
            <label for="srAssociateYear" id="srAssociateExpLabel">Sr Associate experience</label>
            <app-bento-custom-multiselect-overlay
              name="srAssociateYear"
              id="srAssociateYear"
              #srAssociateYear
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createChartModel.selectedSrAssociateYears"
              (ngModelChange)="srAssociateYearOnItemsSelected($event)"
              [itemsObservable]="srAssociateYearsStream"
              [editButtonText]="createChartModel.selectedSrAssociateYearsText"
              [disabled]="disableDropdowns || isDataLoading"
              aria-labelledby="srAssociateExpLabel"
              [ariaLabelValue]="'sr associate experience'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div
            class="form-group Form-group Form-group--indent"
            *ngIf="isPartnerYear && createChartSelectionRules.titles.displayOnUi"
          >
            <label for="partnerYear" id="partnerExpLabel">Partner experience</label>
            <app-bento-custom-multiselect-overlay
              name="partnerYear"
              id="partnerYear"
              #partnerYear
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createChartModel.selectedPartnerYears"
              (ngModelChange)="partnerYearOnItemsSelected($event)"
              [itemsObservable]="partnerYearsStream"
              [editButtonText]="createChartModel.selectedPartnerYearsText"
              [disabled]="disableDropdowns || isDataLoading"
              aria-labelledby="partnerExpLabel"
              [ariaLabelValue]="'partner experience'"
            ></app-bento-custom-multiselect-overlay>
          </div>

          <div class="form-group Form-group">
            <div class="bento-form-buttons">
              <button
                type="button"
                class="btn btn-outline-secondary"
                [disabled]="isDataLoading"
                (click)="backToCurrentVisualization()"
              >
                <span class="btn-text">Cancel</span>
              </button>
              <button
                type="button"
                class="btn btn-primary"
                (click)="saveChart()"
                [disabled]="
                  isDataLoading ||
                  !isReadyToSave ||
                  !createChartModel.name ||
                  (!isCopyEditRequest && createChartModel.orignalName === createChartModel.name)
                "
              >
                <span class="btn-text">Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </aside>
    <div class="FilterPanel-data FilterPanel--visualization" [bentoBusyLoader]="isDataLoading">
      <div *ngIf="vizTemplateDetail">
        <app-global-breadcrumbs
          [isFirmPeerGroup]="true"
          (updateService)="backToCurrentVisualization()"
          [peerLabel]="vizTemplateDetail && (vizTemplateDetail.chartName || vizTemplateDetail.templateName)"
          [firmName]="peerLabel"
        ></app-global-breadcrumbs>
      </div>
      <app-chart-details
        #ChartDetails
        *ngIf="vizTemplateDetail"
        [selectedChartData]="selectedVizData"
        [createChartModel]="createChartModel"
        [calledForScreen]="'CreateVisualization'"
        [vizTemplateDetail]="vizTemplateDetail"
        [shouldShowSettingButton]="true"
        (dataLoadingStateChanged)="handleDataLoadingStateChanged($event)"
        (rendered)="componentRendered(ChartDetails)"
        (settingsChanged)="onSettingsChanged()"
      >
      </app-chart-details>
    </div>
  </div>
</main>
