import {Component, OnInit, HostBinding, EventEmitter, ViewChild, Input, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthService} from '../../../services/auth/auth.service';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {TkFilterModel} from 'src/app/core/models/tk-filter.model';
import {TkEditFieldModel} from 'src/app/core/models/tk-editField.model';
import {TkHistoryRequest} from 'src/app/core/models/tk-history-request.model';
import {DataProcessing, Detail, TkHistoryResponse} from 'src/app/core/models/tk-history-response.model';
import {BentoAlertItemOptions, BentoPaginationComponent, BentoTagsInputEvent} from '@bento/bento-ng';
import {SplitAccountGridComponent} from '../shared/split-account-grid/split-account-grid.component';
import {NgbModal, NgbPagination} from '@ng-bootstrap/ng-bootstrap';
import {BentoComboboxOptions} from '@bento/bento-ng';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {FiscalPeriod} from 'src/app/core/models/fiscal-period.model';
import {
  TimekeeperDetail,
  TKExpyearOverrideRequestBody,
  TKOverrideRequestBody,
  TKPmOfficeOverrideRequestBody,
  TKPmPracticeOverrideRequestBody,
  TKPmTitleOverrideRequestBody,
  Typeahead,
} from 'src/app/core/models/typeahead.model';
import {Departments} from 'src/app/core/models/glac-override.model';
import {ActionDropdownComponent} from '../../shared/action-dropdown/action-dropdown.component';
import {TimekeeperModelComponent} from './timekeeper-model/timekeeper-model.component';
import {PeerCheckServiceService} from '../../peer-check/peer-check/peer-check-service.service';
import {FiscalPeriodComponent} from '../shared/fiscal-period/fiscal-period.component';
import {MappingFirmsDetailsComponent} from '../shared/mapping-firms-details/mapping-firms-details.component';
import {RebuildHistoryComponent} from './rebuild-histroy/rebuild-history.component';
import {transpileModule} from 'typescript';
import {ManageColumnsComponent} from './manage-columns/manage-columns.component';
import {GlobalService} from 'src/app/core/services/global/global.service';
import $ from 'jquery';

@Component({
  selector: 'app-timekeepers',
  templateUrl: './timekeepers.component.html',
  styleUrls: ['./timekeepers.component.scss'],
})
export class TimekeepersComponent implements OnInit {
  @Input() availableOverrides: any;
  @Input() isOverride: Boolean;
  isSelectedTimekeeper = false;
  @ViewChild(ActionDropdownComponent) actionDropdownComponent: ActionDropdownComponent;
  @ViewChild(FiscalPeriodComponent) fiscalPeriodComponent: FiscalPeriodComponent;
  @ViewChild(MappingFirmsDetailsComponent) mappingFirmDetailsComponent: MappingFirmsDetailsComponent;

  searching = false;
  searchFailed = false;
  //@Input() public selectedRecord: Array<any>;

  fiscalPeriodData: any;
  fiscalDateRange = [];
  isFilterButtonDisabled = false;
  isMainBusyLoaderBusy = false;
  defaultDate: Number;
  fromData: any;
  toDate: any;
  fiscalPeriodModel: FiscalPeriod = new FiscalPeriod();
  isFiscalAvail: boolean;
  prevSelectedOverride: any = null;
  isRecordSelected = false;
  validations: any;
  tkFilterFteVal = '(([0.0])|([0].*)|([0].[0-9][0-9])|([1.0]))';
  selectedOptions = [];
  isFilterOpen = false;
  isReadonly = false;
  isFilterReset = false;
  enableClearFilter = false;
  data: any[];

  isDisabled = false;
  firmId: any;
  selectedRecord = [];
  isRecordsEmpty = true;
  closeResult: string;
  titles: any[];
  offices: any[];
  departments: Departments[];
  peerOffices: Typeahead[] = [];
  peerPractices: Typeahead[] = [];
  peerTitles: Typeahead[] = [];
  names: any[];
  tkids: any[];
  officeKeyValue: any = {};
  peerOfficeKeyValue: any = {};
  peerTitleKeyValue: any = {};
  titleKeyValue: any = {};
  deptKeyValue: any = {};
  peerPracticeKeyValue: any = {};
  isDataModified = false;
  firmLocale: any;
  isDataLoading: boolean;
  errorMessage = '';
  successMsg: string;
  warningMessage: String = '';
  isColumnFreezed = true;
  columnFreezeMessage = 'Freeze Period, Last name, First name and TKINIT columns';
  columnUnfreezeMessage = 'Unfreeze Period, Last name, First name and TKINIT columns';
  numberOfCoulmnToFreeze = 5;
  tkFilterData: TkFilterModel = new TkFilterModel();
  resetTkFilterData: TkFilterModel = new TkFilterModel();
  tkEditFieldData: TkEditFieldModel = new TkEditFieldModel();
  resetTkEditFieldData: TkEditFieldModel = new TkEditFieldModel();
  tkHistoryRecordRequest: TkHistoryRequest = new TkHistoryRequest();
  tkHistoryFilterData: TkHistoryRequest = new TkHistoryRequest();
  resetTKFilterFields: TkHistoryRequest = new TkHistoryRequest();
  tkHistoryRecordResponse: TkHistoryResponse = new TkHistoryResponse();
  tkHistoryRecordResponseOriginal: TkHistoryResponse = new TkHistoryResponse();

  alerts: BentoAlertItemOptions[] = [];
  pmDataKeyValue: any = {};

  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
  };
  interval: any;
  peerOfficeCodes = [];
  actionDropdownItems: {name: string; isDisabled: boolean}[];
  timekeeper: string;
  isMappingsAvailable: boolean;
  subscriptions: any = [];
  totalCount = 0;
  sortedColumn = '';
  rebuildHistoryDropdownIndex: number;
  fmsType: any;

  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  // Grid Start
  // ComboBox Data Emitter (Used in products column cell editor)
  dataEmitter: EventEmitter<any> = new EventEmitter();
  products: string[];

  // Get reference to gridFilter via ViewChild (see #filter in flexgrid.html)
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  // Get reference to FlexGrid control (see #flexGrid in flexgrid.html)
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  // Get reference to errorModal control (see #errorModal in timekeepers.component.html)
  @ViewChild('errorModal', {static: true}) errorModal: wjcGrid.FlexGrid;

  @ViewChild('pagination', {static: true}) pagination: BentoPaginationComponent;
  // reference to FlexGrid Data Collection View
  gridData: wjcCore.CollectionView;

  /**
   * MULTI-SELECT
   */
  // Multi-Select handlers and constants
  CHECKBOX_SELECTED = 1;
  CHECKBOX_UNSELECTED = 2;
  headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
  CHECKBOX_INDETERMINATE = 3;

  // assign column binding for multi-select to variable,
  selectColumnAllowSorting = false;
  selectBinding = 'selected';
  /**
   * Pagination Setup data
   */
  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };

  editFieldData = ['FTE', 'Peer title', 'Peer practice', 'Peer office', 'Experience year'];

  /**
   * Toolbar configruation data
   */
  toolbarConfig: any = {
    filterButtonHidden: false,
    filtersHidden: true,
    groupButtonHidden: false,
    groupPanelHidden: true,
  };

  constructor(
    //public activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private service: BaseService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private _peerCheckService: PeerCheckServiceService,
    private globalservice: GlobalService
  ) {
    this.isDataLoading = true;
    this.gridData = new wjcCore.CollectionView(this.tkHistoryRecordResponse.details, {
      sortComparer: (a: any, b: any) => {
        return this.comparer(a, b);
      },
    });

    this.gridData.pageSize = this.pageInfo.pageSize;
    this.validations = {
      tkFilterFte: {
        pattern: 'Error: Value should be between 0 to 1',
      },
    };

    if (this.route.params) {
      this.route.params.subscribe((val) => {
        if (val && val.id) {
          this.firmId = val.id;
        } else {
          if (this._peerCheckService) {
            if (
              this._peerCheckService.getSelectedFirmId &&
              this._peerCheckService.getSelectedFirmId != this.getfirmId
            ) {
              this.firmId = this._peerCheckService.getSelectedFirmId;
            } else {
              this.firmId = this.getfirmId;
            }
          }
        }
        this.enableClearFilter = false;
        if (this._peerCheckService.isValidMappingFirm(this.firmId)) {
          this.getFms();
        }
      });
    }
  }

  async ngOnInit() {
    this.isDataLoading = true;
    //Filter Body scroll method call
    var filterbodyId = 'FilterPanel-body-scroll-tk';
    this._peerCheckService.filterBodyMaxheightScoll(filterbodyId);

    this._peerCheckService.setBalancingValue({selected: 'Default', value: ''});
    if (this._peerCheckService.getSelectedFirmId != this.getfirmId) {
      this.firmId = this._peerCheckService.getSelectedFirmId;
    }
    this.timekeeper = this.isSelectedTimekeeper ? 'All timekeepers' : 'Selected timekeepers';
    this.actionDropdownItems = [
      {name: 'Show current fiscal period', isDisabled: false},
      {name: 'Rebuild history (0)', isDisabled: false},
      {name: this.timekeeper, isDisabled: false},
      {name: 'Split Office Practice', isDisabled: false},
      {name: 'Manage columns', isDisabled: false},

      /* TODO : Need to Uncomment after beta release  */
      // {name: 'Import', isDisabled: false},
      // {name: 'Manage columns', isDisabled: false},
      // {name: 'Export selected', isDisabled: false},
    ];
    this.rebuildHistoryDropdownIndex = 1;
    this.updateActionDropdownItems();
    // setTimeout(() => {
    //   this.updateFlexGridRowHeight(this.flexGrid, 36, 36);
    // });
    this.enableEditFields();
    this.isDataLoading = false;
    this.filterPanelFocus();
  }
  filterPanelFocus() {
    $('.filter-common-btn').click(function () {
      setTimeout(function () {
        $('#fispfrm').focus();
      }, 0);
    });
  }
  getFms() {
    this.isDataLoading = true;
    this.service
      .get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/' + this.firmId, '')
      .subscribe((result) => {
        this.fmsType = result.financialManagementSystem.financialManagementSystem;
        if (this.fmsType == '4' || this.fmsType == '5' || this.fmsType == '3') {
          this.columnFreezeMessage = 'Freeze Period, Name and TKINIT columns';
          this.columnUnfreezeMessage = 'Unfreeze Period, Name and TKINIT columns';
          this.numberOfCoulmnToFreeze = 5;
        } else {
          this.columnFreezeMessage = 'Freeze Period, Last name, First name and TKINIT columns';
          this.columnUnfreezeMessage = 'Unfreeze Period, Last name, First name and TKINIT columns';
          this.numberOfCoulmnToFreeze = 5;
        }
        this.isDataLoading = false;
      });
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (this.firmId) return this.firmId;
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  searchCompare(item: any, search: string) {
    const name = item;
    const searchStr = search.toLowerCase();
    return name.toLowerCase().indexOf(searchStr) > -1;
  }

  updateFiscalDateRange(selection) {
    var data = JSON.parse(selection);
    var option = {
      id: 'fiscalDateRange',
      selectedOption: data,
    };
    if (!this.selectedOptions.some((item) => item.id == 'fiscalDateRange')) {
      this.selectedOptions.push(option);
    } else if (this.selectedOptions.indexOf(option)) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1, option);
    }
  }

  onFilterChange() {
    this.enableClearFilter = true;
  }

  dropDownOnSelectChange(name) {
    if (name.startsWith('Split')) {
      this.onSplitsClick(this.selectedRecord, 'action', '');
    } else if (name.startsWith('Selected timekeepers')) {
      this.isSelectedTimekeeper = true;
      this.selectedTimekeepers();
    } else if (name.startsWith('All timekeepers')) {
      this.isSelectedTimekeeper = false;
      this.selectedTimekeepers();
    } else if (name.startsWith('Manage column')) {
      const modalRef = this.modalService.open(ManageColumnsComponent);
      modalRef.componentInstance.tkFilterData = this.tkFilterData;
    } else if (name.startsWith('Rebuild history') && this.selectedRecord.length > 0) {
      const modalRef = this.modalService.open(RebuildHistoryComponent);
      modalRef.componentInstance.firmId = this.getfirmId;
      modalRef.componentInstance.rebuildHistoryModel.fiscalPeriodModel = this.fiscalPeriodModel;
      modalRef.componentInstance.rebuildHistoryModel.fiscalDateRange = this.fiscalDateRange;
      modalRef.componentInstance.rebuildHistoryModel.selectedRecord = this.selectedRecord;
      modalRef.result.then(
        (result) => {
          if (result == 'Success') {
            this.alerts.push({
              type: 'success',
              msg: 'Data saved Successfully.',
              timeout: 2500,
              closeable: true,
            });
          } else {
            this.alerts.push({
              type: 'warning',
              msg: result,
              closeable: true,
            });
          }
        },
        (reason) => {}
      );
    } else if (name.startsWith('Import')) {
      this.modalService.open(TimekeeperModelComponent);
    } else if (name.startsWith('Show current fiscal period')) {
      this.fiscalPeriodComponent.initialize();
    }
  }

  /**
   * Update the Rows Height of the grid - default is 36px for rows and header
   */
  updateFlexGridRowHeight(grid, height, headHeight) {
    height = height || 36;
    if (grid.rows) grid.rows.defaultSize = height;
    const headerHeight = 36;
    if (grid.columnHeaders) {
      for (let i = 0, total = grid.columnHeaders.rows.length; i < total; i++) {
        grid.columnHeaders.rows[i].height = headHeight && headHeight[i] ? headHeight[i] : headerHeight;
      }
    }
  }

  // Grid Methods

  //Handler for FlexGrid's Initialized method
  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 56;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    this.flexGrid = flexGrid;
  }

  sortGrid(headerName: string, curSort: any) {
    const currentSort = curSort.currentSort == '+' ? 'ASC' : 'DESC';
    this.tkHistoryRecordRequest.sortColumns = [headerName];
    this.tkHistoryRecordRequest.sortDir = currentSort;
    this.retrieveTKHistoryRecords(null, true, {headerName, curSort: curSort.currentSort == '+' ? true : false});
  }
  // Pagination Size Changed Event Handler
  onPageSizeChanged(size) {
    this.gridData.pageSize = size;
    this.tkHistoryRecordRequest.size = size;
    this.pageInfo.pageSize = size;
    this.onPageCheckBoxChange(this.flexGrid);
  }

  onPageCheckBoxChange(flexGrid) {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;
    if (flexGrid.rows) {
      for (let i = 0, ttl = flexGrid.rows.length; i < ttl; i++) {
        const isCheckBoxSelected =
          flexGrid.rows[i].dataItem &&
          this.selectedRecord.filter((record) => record.tkId == flexGrid.rows[i].dataItem.tkId).length > 0;
        if (isCheckBoxSelected) {
          count++;
          flexGrid.rows[i].dataItem['selected'] = true;
          flexGrid.rows[i].cssClass = 'row-selected';
          //this.saveSelection(this.flexGrid.rows[i].dataItem);
        } else {
          flexGrid.rows[i].dataItem[this.selectBinding] = false;
          //this.removeSelection(this.flexGrid.rows[i].dataItem);
          flexGrid.rows[i].cssClass = '';
        }
      }

      if (count === flexGrid.rows.length) {
        this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      } else if (count > 0) {
        this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
      }
    }
    this.updateActionDropdownItems();
  }
  onTagRemoved(e: BentoTagsInputEvent) {
    e.tag.selected = false;
    this.onCheckBoxChange();
  }

  // Pagination Page Changed Event Handler
  onPageChanged(page) {
    this.gridData.moveToPage(page - 1);
    if (!this.isSelectedTimekeeper) {
      this.tkHistoryRecordRequest.page = page;
      //this.onCheckBoxChange();
      this.onPageCheckBoxChange(this.flexGrid);
      // this.isRecordSelected = false;
      // this.tkEditFieldData.editField = undefined;
      // this.tkEditFieldData.newValue = undefined;
      // this.tkEditFieldData.duration = undefined;
      this.retrieveTKHistoryRecords();
    }
  }

  // Event Handler for Multi-Select Column Header Checkbox
  onHeaderCheckBoxChange() {
    let selected = false;
    if (this.headerCheckBoxMode !== this.CHECKBOX_SELECTED) {
      this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      selected = true;
    } else {
      this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
      selected = false;
    }
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        this.flexGrid.rows[i].dataItem[this.selectBinding] = selected;
        this.flexGrid.rows[i].cssClass = selected ? 'row-selected' : '';
        selected
          ? this.saveSelection(this.flexGrid.rows[i].dataItem)
          : this.removeSelection(this.flexGrid.rows[i].dataItem);
      }
    }
    this.updateActionDropdownItems();
    this.setEditFields();
  }

  //TK Edit fields
  enableEditFields() {
    this.tkEditFieldData.editFieldDataStream = new BehaviorSubject(this.editFieldData);
  }

  onCheckBoxChange() {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;

    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        if (this.flexGrid.rows[i].dataItem[this.selectBinding] === true) {
          count++;
          this.flexGrid.rows[i].cssClass = 'row-selected';
          this.saveSelection(this.flexGrid.rows[i].dataItem);
        } else {
          this.removeSelection(this.flexGrid.rows[i].dataItem);
          this.flexGrid.rows[i].cssClass = '';
        }
      }

      if (count === this.flexGrid.rows.length) {
        this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      } else if (count > 1) {
        this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
      }
    }

    this.setEditFields();
    this.updateActionDropdownItems();
  }

  isAllSelected() {
    return this.headerCheckBoxMode === this.CHECKBOX_SELECTED;
  }

  isIndeterminate() {
    return this.headerCheckBoxMode === this.CHECKBOX_INDETERMINATE;
  }

  saveSelection(selectedOption) {
    //const index = this.selectedRecord.indexOf(selectedOption);
    const isCheckBoxSelected = this.selectedRecord.filter((record) => record.tkId == selectedOption.tkId).length > 0;
    if (!isCheckBoxSelected) {
      this.selectedRecord.push(selectedOption);
    }

    this.actionDropdownItems[this.rebuildHistoryDropdownIndex].name =
      'Rebuild history (' + this.selectedRecord.length + ')';
    if (this.selectedRecord.length == 0) {
      this.actionDropdownItems[this.rebuildHistoryDropdownIndex].isDisabled = true;
    } else {
      this.actionDropdownItems[this.rebuildHistoryDropdownIndex].isDisabled = false;
    }
  }

  setEditFields() {
    if (this.selectedRecord.length > 1) {
      this.isRecordSelected = true;
      this.globalservice.skipFocusTwiceCombobox();
    } else {
      this.isRecordSelected = false;
      this.resetEditFields();
      // this.timekeeperEditField.
    }
  }
  removeSelection(selectedOption) {
    const isCheckBoxSelected = this.selectedRecord.filter((record) => record.tkId == selectedOption.tkId).length > 0;
    if (isCheckBoxSelected) {
      for (let i = 0; i < this.selectedRecord.length; i++) {
        if (this.selectedRecord[i].tkId == selectedOption.tkId) {
          this.selectedRecord.splice(i, 1);
          break;
        }
      }
    }
    this.actionDropdownItems[this.rebuildHistoryDropdownIndex].name =
      'Rebuild history (' + this.selectedRecord.length + ')';
    if (this.selectedRecord.length == 0) {
      this.actionDropdownItems[this.rebuildHistoryDropdownIndex].isDisabled = true;
    } else {
      this.actionDropdownItems[this.rebuildHistoryDropdownIndex].isDisabled = false;
    }
  }

  retrieveTKHistoryRecords(
    isFilter: boolean = false,
    isSorting: boolean = false,
    sortObj: any = {},
    resetPage: boolean = false
  ) {
    this.isDataLoading = true;
    if (isFilter) {
      this.enableClearFilter = true;
      this.isFilterOpen = false;
    }
    if (resetPage) {
      this.gridData.moveToPage(0);

      this.tkHistoryRecordRequest.page = 1;
      this.pagination.page = 1;
    }

    let initialDataLoaded = false;
    const requestBody = this.generateRequestbody();
    if (isFilter) {
      this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
      this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;
    }
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/' + this.getfirmId, requestBody)
      .subscribe(
        (result) => {
          this.tkHistoryRecordResponse = result;
          this.tkHistoryRecordResponse.details.forEach((o) => {
            o.dataProcessing = new DataProcessing();
            o.period = o.period.toString();
          });
          this.tkHistoryRecordResponseOriginal = JSON.parse(JSON.stringify(this.tkHistoryRecordResponse));
          //this.gridData = new wjcCore.CollectionView(this.tkHistoryRecordResponse.details);
          this.gridData = new wjcCore.CollectionView(this.tkHistoryRecordResponse.details, {
            sortComparer: (a: any, b: any) => {
              return this.comparer(a, b);
            },
          });
          if (isSorting) {
            const sortDir = new wjcCore.SortDescription(sortObj.headerName, sortObj.curSort);
            this.gridData.sortDescriptions.push(sortDir);
          }

          this.gridData.trackChanges = true;
          this.gridData.pageSize = this.pageInfo.pageSize;
          this.totalCount = this.tkHistoryRecordResponse.totalCount;

          if (this.isDataModified) {
            this.successMsg = 'Data modified successfully.';
            this.isDataModified = false;
            this.alerts.push({
              type: 'success',
              msg: this.successMsg,
              timeout: 2500,
              closeable: true,
            });
          }
          //this.selectedRecord = [];
          this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
          this.isRecordsEmpty = true;
          this.isSelectedTimekeeper = false;
          this.updateActionDropdownItems();

          // if initial data load is not complete, update data values and UI components
          if (!initialDataLoaded) {
            this.firmLocale = this.localStorageService.get('firmLocale');
            this.getPeerOfficeCodes();
            this.getTimekeeperFilterPanelNames();
            this.getTimekeeperFilterPanelTkinits();
            this.getTimekeeperFilterPanelTitles();
            this.getTimekeeperFilterPanelOffice();
            this.getTimekeeperFilterPanelDept();
            this.getTimekeeperFilterPeerOffice();
            this.getTimekeeperFilterPeerPractice();
            this.getTimekeeperFilterPeerTitle();
            initialDataLoaded = true;
          }
          this.isDataLoading = false;
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }
  generateRequestbody() {
    return this.tkHistoryRecordRequest;
  }

  onFiscalPeriodChange(fiscalData: any, shouldRetrieveData: boolean = false) {
    this.fiscalPeriodModel = JSON.parse(fiscalData);

    if (
      Number(this.fiscalPeriodModel.from_date) > Number(this.fiscalPeriodModel.to_date) &&
      this.fiscalPeriodModel.to_date != null
    ) {
      this.isFilterButtonDisabled = true;
    } else {
      this.isFilterButtonDisabled = false;
    }
    if (shouldRetrieveData) {
      this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.to_date;
      this.fromData = this.fiscalPeriodModel.to_date;
      this.toDate = this.fiscalPeriodModel.to_date;
    } else {
      this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
    }

    this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;

    if (shouldRetrieveData) {
      this.retrieveTKHistoryRecords();
    }
  }

  onTitleSelected(titles: any[]) {
    this.tkHistoryRecordRequest.firmTitle = [];
    if (titles.length == 1) {
      this.tkFilterData.selectedFirmTitleText = '1 title selected';
    } else if (titles.length == this.tkFilterData.titles.length) {
      this.tkFilterData.selectedFirmTitleText = 'All';
    } else this.tkFilterData.selectedFirmTitleText = titles.length.toString() + ' titles selected';
    for (var title of titles) {
      let firmTitle = Object.keys(this.tkFilterData.titleKeyValue).find(
        (key) => this.tkFilterData.titleKeyValue[key] === title
      );
      this.tkHistoryRecordRequest.firmTitle.push(firmTitle);
    }
  }
  onOfficeSelected(offices: any[]) {
    this.tkHistoryRecordRequest.firmOffice = [];
    if (offices.length == 1) {
      this.tkFilterData.selectedFirmOfficeText = '1 office selected';
    } else if (offices.length == this.tkFilterData.offices.length) {
      this.tkFilterData.selectedFirmOfficeText = 'All';
    } else this.tkFilterData.selectedFirmOfficeText = offices.length.toString() + ' offices selected';
    for (var office of offices) {
      let firmOffice = Object.keys(this.tkFilterData.officeKeyValue).find(
        (key) => this.tkFilterData.officeKeyValue[key] === office
      );
      this.tkHistoryRecordRequest.firmOffice.push(firmOffice);
    }
  }
  onDeptSelected(departments: any[]) {
    this.tkHistoryRecordRequest.department = [];
    if (departments.length == 1) {
      this.tkFilterData.selectedDeptText = '1 department selected';
    } else if (departments.length == this.tkFilterData.departments.length) {
      this.tkFilterData.selectedDeptText = 'All';
    } else this.tkFilterData.selectedDeptText = departments.length.toString() + ' departments selected';
    for (var department of departments) {
      let dept = Object.keys(this.tkFilterData.deptKeyValue).find(
        (key) => this.tkFilterData.deptKeyValue[key] === department
      );
      this.tkHistoryRecordRequest.department.push(dept);
    }
  }
  onPeerOfficeSelected(peerOffices: any[]) {
    this.tkHistoryRecordRequest.peerOffice = [];
    if (peerOffices.length == 1) {
      this.tkFilterData.selectedPeerOfficeText = '1 peer office selected';
    } else if (peerOffices.length == this.tkFilterData.peerOffices.length) {
      this.tkFilterData.selectedPeerOfficeText = 'All';
    } else this.tkFilterData.selectedPeerOfficeText = peerOffices.length.toString() + ' peer offices selected';
    for (var office of peerOffices) {
      let peerOffice = Object.keys(this.tkFilterData.peerOfficeKeyValue).find(
        (key) => this.tkFilterData.peerOfficeKeyValue[key] === office
      );
      this.tkHistoryRecordRequest.peerOffice.push(peerOffice);
    }
  }
  onPeerPracticeSelected(peerPractices: any[]) {
    this.tkHistoryRecordRequest.peerPractice = [];
    if (peerPractices.length == 1) {
      this.tkFilterData.selectedPeerPracticeText = '1 peer practice selected';
    } else if (peerPractices.length == this.tkFilterData.peerPractices.length) {
      this.tkFilterData.selectedPeerPracticeText = 'All';
    } else this.tkFilterData.selectedPeerPracticeText = peerPractices.length.toString() + ' peer practices selected';
    for (var practice of peerPractices) {
      let peerPractice = Object.keys(this.tkFilterData.peerPracticeKeyValue).find(
        (key) => this.tkFilterData.peerPracticeKeyValue[key] === practice
      );
      this.tkHistoryRecordRequest.peerPractice.push(peerPractice);
    }
  }
  onPeerTitleSelected(peerTitles: any[]) {
    this.tkHistoryRecordRequest.peerTitle = [];
    if (peerTitles.length == 1) {
      this.tkFilterData.selectedPeerTitleText = '1 peer title selected';
    } else if (peerTitles.length == this.tkFilterData.peerTitles.length) {
      this.tkFilterData.selectedPeerTitleText = 'All';
    } else this.tkFilterData.selectedPeerTitleText = peerTitles.length.toString() + ' peer titles selected';
    for (var title of peerTitles) {
      let peerTitle = Object.keys(this.tkFilterData.peerTitleKeyValue).find(
        (key) => this.tkFilterData.peerTitleKeyValue[key] === title
      );
      this.tkHistoryRecordRequest.peerTitle.push(peerTitle);
    }
  }
  onExpYearSelected(expYear: number) {
    this.tkHistoryRecordRequest.experienceYear = [];
    if (expYear != null) this.tkHistoryRecordRequest.experienceYear.push(expYear);
  }
  onNamesSelected(names: any[]) {
    this.tkHistoryRecordRequest.name = [];
    if (names.length == 1) {
      this.tkFilterData.selectedNamesText = '1 name selected';
    } else if (names.length == this.tkFilterData.names.length) {
      this.tkFilterData.selectedNamesText = 'All';
    } else this.tkFilterData.selectedNamesText = names.length.toString() + ' names selected';
    for (var name of names) {
      this.tkHistoryRecordRequest.name.push(name);
    }
  }
  onTkinitItemsSelected(tkIds: any[]) {
    this.tkHistoryRecordRequest.tkId = [];
    if (tkIds.length == 1) {
      this.tkFilterData.selectedTkinitsText = '1 TKINIT selected';
    } else if (tkIds.length == this.tkFilterData.tkids.length) {
      this.tkFilterData.selectedTkinitsText = 'All';
    } else this.tkFilterData.selectedTkinitsText = tkIds.length.toString() + ' TKINITs selected';
    for (var tkid of tkIds) {
      this.tkHistoryRecordRequest.tkId.push(tkid);
    }
  }

  applyTKEdit() {
    var editFieldRequestBody = {};
    var tkDetails = [];
    for (let record of this.selectedRecord) {
      let option = {
        tkId: String(record.tkId),
        startPeriod: Number(record.period),
      };
      tkDetails.push(option);
    }
    if (this.tkEditFieldData.editField == 'FTE') {
      editFieldRequestBody = {
        timekeeperDetails: tkDetails,
        fte: this.tkEditFieldData.newValue,
        duration: this.tkEditFieldData.duration,
      };
      this.updateTKFieldOverride('fte', editFieldRequestBody);
    } else if (this.tkEditFieldData.editField == 'Peer title') {
      let peerTitle = Object.keys(this.tkFilterData.peerTitleKeyValue).find(
        (key) => this.tkFilterData.peerTitleKeyValue[key] === this.tkEditFieldData.newValue
      );
      //this.tkHistoryRecordRequest.peerTitle.push(peerTitle);

      editFieldRequestBody = {
        timekeeperDetails: tkDetails,
        title: peerTitle,
        duration: this.tkEditFieldData.duration,
      };
      this.updateTKFieldOverride('title', editFieldRequestBody);
    } else if (this.tkEditFieldData.editField == 'Peer practice') {
      let peerPractice = Object.keys(this.tkFilterData.peerPracticeKeyValue).find(
        (key) => this.tkFilterData.peerPracticeKeyValue[key] === this.tkEditFieldData.newValue
      );
      editFieldRequestBody = {
        timekeeperDetails: tkDetails,
        practice: peerPractice,
        duration: this.tkEditFieldData.duration,
      };
      this.updateTKFieldOverride('practice', editFieldRequestBody);
    } else if (this.tkEditFieldData.editField == 'Peer office') {
      let peerOffice = Object.keys(this.tkFilterData.peerOfficeKeyValue).find(
        (key) => this.tkFilterData.peerOfficeKeyValue[key] === this.tkEditFieldData.newValue
      );
      editFieldRequestBody = {
        timekeeperDetails: tkDetails,
        office: peerOffice,
        duration: this.tkEditFieldData.duration,
      };
      this.updateTKFieldOverride('office', editFieldRequestBody);
    } else if (this.tkEditFieldData.editField == 'Experience year') {
      editFieldRequestBody = {
        timekeeperDetails: tkDetails,
        expYear: this.tkEditFieldData.newValue,
        duration: this.tkEditFieldData.duration,
      };
      this.updateTKFieldOverride('expyear', editFieldRequestBody);
    }
    this.resetEditFields();
    this.selectedRecord = [];
    this.isSelectedTimekeeper = false;
    this.updateActionDropdownItems();
    this.enableClearFilter = false;
  }
  resetEditFields() {
    this.tkEditFieldData = JSON.parse(JSON.stringify(this.resetTkEditFieldData));
    this.tkEditFieldData.editFieldNewValueStream = new BehaviorSubject([]);
    this.tkEditFieldData.editFieldDurationStream = new BehaviorSubject([]);
    this.isRecordSelected = false;
  }

  updateTKFieldOverride(field, body: any) {
    this.isDataLoading = true;
    this.isDataModified = true;
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/override/' + field + '/' + this.getfirmId, body)
      .subscribe(
        (result) => {
          this.retrieveTKHistoryRecords();
          this.isDataLoading = false;
        },
        (error) => {
          this.errorMessage = error.error;
          this.isDataModified = false;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }

  resetTKFilter(val: boolean = false) {
    if (val) {
      if (typeof this.resetTKFilterFields !== 'undefined') {
        this.tkHistoryRecordRequest = JSON.parse(JSON.stringify(this.resetTKFilterFields));
        this.tkHistoryFilterData = JSON.parse(JSON.stringify(this.resetTKFilterFields));
        //this.tkFilterData = this.resetTkFilterData;
        //this.fiscalPeriodComponent.initialize();

        this.fiscalPeriodModel.to_date = this.toDate;
        this.fiscalPeriodModel.from_date = this.toDate;

        this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
        this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;
      }
    }
    this.isFilterReset = true;
    this.retrieveTKHistoryRecords(false, false, {}, true);
    if (this.isDataModified == false) {
      this.successMsg = 'Data reset successfully.';
      this.alerts.push({
        type: 'success',
        msg: this.successMsg,
        timeout: 2500,
        closeable: true,
      });
    }

    this.isFilterReset = false;
    this.flexGrid.refresh();
    this.enableClearFilter = false;
  }

  initFilter() {
    this.gridFilter.showSortButtons = false;
  }

  onClickFilter(newValue: boolean) {
    if (this.isFilterOpen === newValue) {
      this.isFilterOpen = false;
    } else {
      this.isFilterOpen = newValue;
    }
  }

  onChangeEditField(field: any) {
    this.tkEditFieldData.newValue = null;
    if (field == 'Peer title') {
      this.tkEditFieldData.editFieldNewValueStream = this.tkFilterData.peerTitleStream;
    } else if (field == 'Peer practice') {
      this.tkEditFieldData.editFieldNewValueStream = this.tkFilterData.peerPracticeStream;
    } else if (field == 'Peer office') {
      this.tkEditFieldData.editFieldNewValueStream = this.tkFilterData.peerOfficeStream;
    }
    this.tkEditFieldData.editFieldDurationStream = new BehaviorSubject(this.generateDurationStream());
  }

  generateDurationStream() {
    let a = [];
    for (let i = 1; i < 100; i++) {
      a.push(String(i));
    }
    return a;
  }

  getTimekeeperFilterPanelNames() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/timekeeper/name/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.names = result;
        this.tkFilterData.nameStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (let i = 0; i < this.tkFilterData.names.length; i++) {
              a.push(this.tkFilterData.names[i]);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getTimekeeperFilterPanelTkinits() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/timekeeper/id/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.tkids = result;
        this.tkFilterData.tkinitStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var tkid of this.tkFilterData.tkids) {
              a.push(tkid);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getTimekeeperFilterPanelTitles() {
    var titleDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/title/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.titles = result;
        for (var title of this.tkFilterData.titles) {
          this.tkFilterData.titleKeyValue[title['firmCode']] = title['firmDescription'];
          titleDescription.push(title['firmDescription']);
        }
        this.tkFilterData.firmTitleStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var title of titleDescription) {
              a.push(title);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getTimekeeperFilterPanelOffice() {
    var officeDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/office/pm/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.offices = result;
        for (var office of this.tkFilterData.offices) {
          this.tkFilterData.officeKeyValue[office['firmCode']] = office['firmDescription'];
          officeDescription.push(office['firmDescription']);
        }
        this.tkFilterData.firmOfficeStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var office of officeDescription) {
              a.push(office);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getTimekeeperFilterPanelDept() {
    var deptDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/department/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.departments = result;
        for (var dept of this.tkFilterData.departments) {
          this.tkFilterData.deptKeyValue[dept['code']] = dept['label'];
          deptDescription.push(dept['label']);
        }
        this.tkFilterData.departmentStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var dept of deptDescription) {
              a.push(dept);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  getTimekeeperFilterPeerOffice() {
    var peerOfficeDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/office/pm', '').subscribe(
      (result) => {
        this.tkFilterData.peerOffices = result;
        this.peerOffices = result;
        this.tkHistoryRecordResponse.details.forEach((o) => {
          let peerOffice = this.peerOffices.find((obj) => {
            return obj.code == o.peerOffice;
          });
          o.peerOffice = peerOffice != undefined ? peerOffice.description : null;
        });
        this.tkHistoryRecordResponseOriginal = JSON.parse(JSON.stringify(this.tkHistoryRecordResponse));
        for (var office of this.tkFilterData.peerOffices) {
          this.tkFilterData.peerOfficeKeyValue[office['code']] = office['description'];
          peerOfficeDescription.push(office['description']);
        }
        this.tkFilterData.peerOfficeStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var office of peerOfficeDescription) {
              a.push(office);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getTimekeeperFilterPeerPractice() {
    var peerPracticeDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/practice/pm/', this.firmLocale).subscribe(
      (result) => {
        this.tkFilterData.peerPractices = result;
        this.peerPractices = result;
        this.tkHistoryRecordResponse.details.forEach((o) => {
          let peerPractice = this.peerPractices.find((obj) => {
            return obj.code == o.peerPractice;
          });
          o.peerPractice = peerPractice != undefined ? peerPractice.description : null;
        });
        this.tkHistoryRecordResponseOriginal = JSON.parse(JSON.stringify(this.tkHistoryRecordResponse));
        for (var practice of this.tkFilterData.peerPractices) {
          this.tkFilterData.peerPracticeKeyValue[practice['code']] = practice['description'];
          peerPracticeDescription.push(practice['description']);
        }
        this.tkFilterData.peerPracticeStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var practice of peerPracticeDescription) {
              a.push(practice);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getTimekeeperFilterPeerTitle() {
    const peerTitleDescription: string[] = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/title/pm/', this.firmLocale).subscribe(
      (result) => {
        this.tkFilterData.peerTitles = result;
        this.peerTitles = result;
        this.tkHistoryRecordResponse.details.forEach((o) => {
          let peerTitle = this.peerTitles.find((obj) => {
            return obj.code == o.peerTitle;
          });
          o.peerTitle = peerTitle != undefined ? peerTitle.description : null;
        });
        this.tkHistoryRecordResponseOriginal = JSON.parse(JSON.stringify(this.tkHistoryRecordResponse));
        for (var title of this.tkFilterData.peerTitles) {
          this.tkFilterData.peerTitleKeyValue[title['code']] = title['description'];
          peerTitleDescription.push(title['description']);
        }
        this.tkFilterData.peerTitleStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var title of peerTitleDescription) {
              a.push(title);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  /*getFiscalDateRange() {
    if (this.prevSelectedOverride != null && this.isOverride) {
      this.fiscalPeriodModel = new FiscalPeriod();
      let selectedOverride = this.availableOverrides.find((obj) => {
        return obj.id == this.prevSelectedOverride;
      });
      this.fiscalPeriodModel.from_date =
        selectedOverride != undefined && selectedOverride.startPeriod != null ? selectedOverride.startPeriod : null;
      this.fiscalPeriodModel.to_date =
        selectedOverride != undefined && selectedOverride.endPeriod != null ? selectedOverride.endPeriod : null;
      this.generateDateRange(this.fiscalPeriodModel.from_date, this.fiscalPeriodModel.to_date);
      this.isFiscalAvail = true;
      this.retrieveTKHistoryRecords();
      return;
    }
    this.isMainBusyLoaderBusy = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/firm/' + this.getfirmId, '').subscribe(
      (result) => {
        this.isMainBusyLoaderBusy = false;
        if (Object.keys(result).length === 0) {
          this.alerts.push({
            type: 'warning',
            msg: 'Fiscal Period data not found',
            closeable: true,
          });
        } else {
          this.fiscalPeriodModel = result;
          this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
          this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;
          this.fiscalDateRange = [];
          this.generateDateRange(this.fiscalPeriodModel.from_date, this.fiscalPeriodModel.to_date);
          this.isFiscalAvail = true;
          this.retrieveTKHistoryRecords();
        }
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }*/

  // generateDateRange Used to Generate Complete Date range option.
  /*generateDateRange(startDate, endDate) {
    let dtStart = Number(startDate);
    let dtEnd = Number(endDate);
    this.defaultDate = dtEnd;
    if (this.prevSelectedOverride == null) {
      this.fiscalPeriodModel.from_date = null;
      this.fiscalPeriodModel.to_date = null;
    }
    for (var date = dtStart; date <= dtEnd; date++) {
      let str = date.toString();
      let months = Number(str.substring(4, str.length));
      if (months >= 1 && months <= 12) {
        this.fiscalDateRange.push({value: date, name: str});
      }
    }
  }*/

  onComboboxReady() {
    this.dataEmitter.emit(this.peerTitles);
  }

  handleFteChange(selectedOption: Detail, field: String = 'FTE') {
    let indx = this.tkHistoryRecordResponse.details.indexOf(selectedOption);
    if (
      indx != -1 &&
      JSON.stringify(selectedOption) != JSON.stringify(this.tkHistoryRecordResponseOriginal.details[indx])
    ) {
      selectedOption.dataProcessing.fte = field == 'FTE' ? true : false;
      selectedOption.dataProcessing.fteDuration = field == 'OD' ? true : false;

      if (selectedOption.fte == null) {
        this.warningMessage = 'Make sure the value of FTE should not be blank.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.fte = false;
        selectedOption.dataProcessing.fteDuration = false;
        selectedOption.fte = this.tkHistoryRecordResponseOriginal.details[indx].fte;
        return;
      }

      if (selectedOption.fte < 0 || selectedOption.fte > 1) {
        this.warningMessage = 'Make sure the value of FTE should be between 0 to 1.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.fte = false;
        selectedOption.dataProcessing.fteDuration = false;
        selectedOption.fte = this.tkHistoryRecordResponseOriginal.details[indx].fte;
        return;
      }

      if (
        (selectedOption.fteDuration == null || selectedOption.fteDuration <= 0 || selectedOption.fteDuration > 99) &&
        field == 'FTE'
      ) {
        this.warningMessage =
          'Before modifying the value of FTE make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.fte = false;
        selectedOption.dataProcessing.fteDuration = false;
        selectedOption.fte = this.tkHistoryRecordResponseOriginal.details[indx].fte;
        return;
      }

      if (
        (selectedOption.fteDuration == null || selectedOption.fteDuration <= 0 || selectedOption.fteDuration > 99) &&
        field == 'OD'
      ) {
        this.warningMessage = 'Make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.fte = false;
        selectedOption.dataProcessing.fteDuration = false;
        selectedOption.fteDuration = this.tkHistoryRecordResponseOriginal.details[indx].fteDuration;
        return;
      }

      let requestBody = new TKOverrideRequestBody();
      let option = new TimekeeperDetail();
      option.startPeriod = selectedOption.period;
      option.tkId = selectedOption.tkId;
      requestBody.timekeeperDetails.push(option);
      requestBody.fte = selectedOption.fte;
      requestBody.duration = selectedOption.fteDuration;

      this.service
        .post(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/override/fte/' + this.getfirmId, requestBody)
        .subscribe(
          (result) => {
            selectedOption.dataProcessing.fte = false;
            selectedOption.dataProcessing.fteDuration = false;
            this.tkHistoryRecordResponseOriginal.details[indx] = JSON.parse(JSON.stringify(selectedOption));
          },

          (error) => {
            selectedOption.dataProcessing.fte = false;
            selectedOption.dataProcessing.fteDuration = false;
            selectedOption.fte = this.tkHistoryRecordResponseOriginal.details[indx].fte;
            selectedOption.fteDuration = this.tkHistoryRecordResponseOriginal.details[indx].fteDuration;
            this.errorMessage = error.error;
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        );
    }
  }

  handlePeerTitleChange(selectedOption: Detail, field: String = 'TITLE') {
    let indx = this.tkHistoryRecordResponse.details.indexOf(selectedOption);
    let originalOption = this.tkHistoryRecordResponseOriginal.details[indx];
    if (
      indx != -1 &&
      ((field == 'OD' && originalOption.peerTitleDuration != selectedOption.peerTitleDuration) ||
        (field == 'TITLE' && originalOption.peerTitle != selectedOption.peerTitle))
    ) {
      selectedOption.dataProcessing.peerTitle = field == 'TITLE' ? true : false;
      selectedOption.dataProcessing.peerTitleDuration = field == 'OD' ? true : false;
      let getPeerTitleCode = this.peerTitles.find((obj) => {
        return selectedOption.peerTitle != null && obj.description == selectedOption.peerTitle.toString();
      });

      if (
        (selectedOption.peerTitleDuration == null ||
          selectedOption.peerTitleDuration <= 0 ||
          selectedOption.peerTitleDuration > 99) &&
        field == 'TITLE'
      ) {
        this.warningMessage =
          'Before modifying the value of Peer title make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.peerTitle = false;
        selectedOption.dataProcessing.peerTitleDuration = false;
        selectedOption.peerTitle = this.tkHistoryRecordResponseOriginal.details[indx].peerTitle;
        return;
      }

      if (
        (selectedOption.peerTitleDuration == null ||
          selectedOption.peerTitleDuration <= 0 ||
          selectedOption.peerTitleDuration > 99) &&
        field == 'OD'
      ) {
        this.warningMessage = 'Make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.peerTitle = false;
        selectedOption.dataProcessing.peerTitleDuration = false;
        selectedOption.peerTitleDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerTitleDuration;
        return;
      }

      if (selectedOption.peerTitle == null || getPeerTitleCode == undefined) {
        this.warningMessage = 'Make sure the peer title should not be blank and selected from the list.';
        this.modalService.open(this.errorModal);
        selectedOption.peerTitle = this.tkHistoryRecordResponseOriginal.details[indx].peerTitle;
        selectedOption.peerTitleDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerTitleDuration;
        selectedOption.dataProcessing.peerTitle = false;
        selectedOption.dataProcessing.peerTitleDuration = false;
        return;
      }

      let requestBody = new TKPmTitleOverrideRequestBody();
      let option = new TimekeeperDetail();
      option.startPeriod = selectedOption.period;
      option.tkId = selectedOption.tkId;
      requestBody.timekeeperDetails.push(option);
      requestBody.title = getPeerTitleCode.code;
      requestBody.duration = selectedOption.peerTitleDuration;

      this.service
        .post(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/override/title/' + this.getfirmId, requestBody)
        .subscribe(
          (result) => {
            selectedOption.dataProcessing.peerTitle = false;
            selectedOption.dataProcessing.peerTitleDuration = false;
            this.tkHistoryRecordResponseOriginal.details[indx] = JSON.parse(JSON.stringify(selectedOption));
          },

          (error) => {
            selectedOption.dataProcessing.peerTitle = false;
            selectedOption.dataProcessing.peerTitleDuration = false;
            selectedOption.peerTitle = this.tkHistoryRecordResponseOriginal.details[indx].peerTitle;
            selectedOption.peerTitleDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerTitleDuration;
            this.errorMessage = error.error;
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        );
    }
  }

  handlePeerPracticeChange(selectedOption: Detail, field: String = 'PRACTICE') {
    let indx = this.tkHistoryRecordResponse.details.indexOf(selectedOption);
    let originalOption = this.tkHistoryRecordResponseOriginal.details[indx];
    if (
      indx != -1 &&
      ((field == 'OD' && originalOption.peerPracticeDuration != selectedOption.peerPracticeDuration) ||
        (field == 'PRACTICE' && originalOption.peerPractice != selectedOption.peerPractice))
    ) {
      selectedOption.dataProcessing.peerPractice = field == 'PRACTICE' ? true : false;
      selectedOption.dataProcessing.peerPracticeDuration = field == 'OD' ? true : false;

      if (
        (selectedOption.peerPracticeDuration == null ||
          selectedOption.peerPracticeDuration <= 0 ||
          selectedOption.peerPracticeDuration > 99) &&
        field == 'PRACTICE'
      ) {
        this.warningMessage =
          'Before modifying the value of peer practice make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.peerPractice = false;
        selectedOption.dataProcessing.peerPracticeDuration = false;
        selectedOption.peerPractice = this.tkHistoryRecordResponseOriginal.details[indx].peerPractice;
        return;
      }

      if (
        (selectedOption.peerPracticeDuration == null ||
          selectedOption.peerPracticeDuration <= 0 ||
          selectedOption.peerPracticeDuration > 99) &&
        field == 'OD'
      ) {
        this.warningMessage = 'Make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.peerPractice = false;
        selectedOption.dataProcessing.peerPracticeDuration = false;
        selectedOption.peerPracticeDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerPracticeDuration;
        return;
      }

      let getpeerPracticeCode = this.peerPractices.find((obj) => {
        return selectedOption.peerPractice != null && obj.description == selectedOption.peerPractice.toString();
      });

      if (selectedOption.peerPractice == null || getpeerPracticeCode == undefined) {
        this.warningMessage = 'Make sure the peer practice should not blank and selected from the list.';
        this.modalService.open(this.errorModal);
        selectedOption.peerPractice = this.tkHistoryRecordResponseOriginal.details[indx].peerPractice;
        selectedOption.peerPracticeDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerPracticeDuration;
        selectedOption.dataProcessing.peerPractice = false;
        selectedOption.dataProcessing.peerPracticeDuration = false;
        return;
      }

      let requestBody = new TKPmPracticeOverrideRequestBody();
      let option = new TimekeeperDetail();
      option.startPeriod = selectedOption.period;
      option.tkId = selectedOption.tkId;
      requestBody.timekeeperDetails.push(option);
      requestBody.practice =
        getpeerPracticeCode != undefined ? getpeerPracticeCode.code : parseInt(selectedOption.peerPractice.toString());
      requestBody.duration = selectedOption.peerPracticeDuration;

      this.service
        .post(
          environment.FIClientBaseEndpoint + 'v1/timekeeper/history/override/practice/' + this.getfirmId,
          requestBody
        )
        .subscribe(
          (result) => {
            selectedOption.dataProcessing.peerPractice = false;
            selectedOption.dataProcessing.peerPracticeDuration = false;
            this.tkHistoryRecordResponseOriginal.details[indx] = JSON.parse(JSON.stringify(selectedOption));
          },

          (error) => {
            selectedOption.dataProcessing.peerPractice = false;
            selectedOption.dataProcessing.peerPracticeDuration = false;
            selectedOption.peerPractice = this.tkHistoryRecordResponseOriginal.details[indx].peerPractice;
            selectedOption.peerPracticeDuration =
              this.tkHistoryRecordResponseOriginal.details[indx].peerPracticeDuration;
            this.errorMessage = error.error;
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        );
    }
  }

  handlePeerOfficeChange(selectedOption: Detail, field: String = 'OFFICE') {
    let indx = this.tkHistoryRecordResponse.details.indexOf(selectedOption);
    let originalOption = this.tkHistoryRecordResponseOriginal.details[indx];
    if (
      indx != -1 &&
      ((field == 'OD' && originalOption.peerOfficeDuration != selectedOption.peerOfficeDuration) ||
        (field == 'OFFICE' && originalOption.peerOffice != selectedOption.peerOffice))
    ) {
      selectedOption.dataProcessing.peerOffice = field == 'OFFICE' ? true : false;
      selectedOption.dataProcessing.peerOfficeDuration = field == 'OD' ? true : false;

      if (
        (selectedOption.peerOfficeDuration == null ||
          selectedOption.peerOfficeDuration <= 0 ||
          selectedOption.peerOfficeDuration > 99) &&
        field == 'OFFICE'
      ) {
        this.warningMessage =
          'Before modifying the value of peer office make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.peerOffice = false;
        selectedOption.dataProcessing.peerOfficeDuration = false;
        selectedOption.peerOffice = this.tkHistoryRecordResponseOriginal.details[indx].peerOffice;
        return;
      }

      if (
        (selectedOption.peerOfficeDuration == null ||
          selectedOption.peerOfficeDuration <= 0 ||
          selectedOption.peerOfficeDuration > 99) &&
        field == 'OD'
      ) {
        this.warningMessage = 'Make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.peerOffice = false;
        selectedOption.dataProcessing.peerOfficeDuration = false;
        selectedOption.peerOfficeDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerOfficeDuration;
        return;
      }

      let getpeerOfficeCode = this.peerOffices.find((obj) => {
        return selectedOption.peerOffice != null && obj.description == selectedOption.peerOffice.toString();
      });

      if (selectedOption.peerOffice == null || getpeerOfficeCode == undefined) {
        this.warningMessage = 'Make sure the peer office should not be blank and selected from the list.';
        this.modalService.open(this.errorModal);
        selectedOption.peerOffice = this.tkHistoryRecordResponseOriginal.details[indx].peerOffice;
        selectedOption.peerOfficeDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerOfficeDuration;
        selectedOption.dataProcessing.peerOffice = false;
        selectedOption.dataProcessing.peerOfficeDuration = false;
        return;
      }

      let requestBody = new TKPmOfficeOverrideRequestBody();
      let option = new TimekeeperDetail();
      option.startPeriod = selectedOption.period;
      option.tkId = selectedOption.tkId;
      requestBody.timekeeperDetails.push(option);
      requestBody.office =
        getpeerOfficeCode != undefined ? getpeerOfficeCode.code : parseInt(selectedOption.peerOffice.toString());
      requestBody.duration = selectedOption.peerOfficeDuration;

      this.service
        .post(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/override/office/' + this.getfirmId, requestBody)
        .subscribe(
          (result) => {
            selectedOption.dataProcessing.peerOffice = false;
            selectedOption.dataProcessing.peerOfficeDuration = false;
            this.tkHistoryRecordResponseOriginal.details[indx] = JSON.parse(JSON.stringify(selectedOption));
          },

          (error) => {
            selectedOption.dataProcessing.peerOffice = false;
            selectedOption.dataProcessing.peerOfficeDuration = false;
            selectedOption.peerOffice = this.tkHistoryRecordResponseOriginal.details[indx].peerOffice;
            selectedOption.peerOfficeDuration = this.tkHistoryRecordResponseOriginal.details[indx].peerOfficeDuration;
            this.errorMessage = error.error;
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        );
    }
  }

  handleExperienceYearChange(selectedOption: Detail, field: String = 'EXPYR') {
    let indx = this.tkHistoryRecordResponse.details.indexOf(selectedOption);
    if (
      indx != -1 &&
      JSON.stringify(selectedOption) != JSON.stringify(this.tkHistoryRecordResponseOriginal.details[indx])
    ) {
      selectedOption.dataProcessing.experienceYear = field == 'EXPYR' ? true : false;
      selectedOption.dataProcessing.experienceYearDuration = field == 'OD' ? true : false;

      if (selectedOption.experienceYear == null) {
        this.warningMessage = 'Make sure the value of Experience year should not be blank.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.experienceYear = false;
        selectedOption.dataProcessing.experienceYearDuration = false;
        selectedOption.experienceYear = this.tkHistoryRecordResponseOriginal.details[indx].experienceYear;
        return;
      }

      if (
        (selectedOption.experienceYearDuration == null ||
          selectedOption.experienceYearDuration <= 0 ||
          selectedOption.experienceYearDuration > 99) &&
        field == 'EXPYR'
      ) {
        this.warningMessage =
          'Before modifying the value of Experience year make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.experienceYear = false;
        selectedOption.dataProcessing.experienceYearDuration = false;
        selectedOption.experienceYear = this.tkHistoryRecordResponseOriginal.details[indx].experienceYear;
        return;
      }

      if (
        (selectedOption.experienceYearDuration == null ||
          selectedOption.experienceYearDuration <= 0 ||
          selectedOption.experienceYearDuration > 99) &&
        field == 'OD'
      ) {
        this.warningMessage = 'Make sure the override duration should be in the range of 1 to 99.';
        this.modalService.open(this.errorModal);
        selectedOption.dataProcessing.experienceYear = false;
        selectedOption.dataProcessing.experienceYearDuration = false;
        selectedOption.experienceYearDuration =
          this.tkHistoryRecordResponseOriginal.details[indx].experienceYearDuration;
        return;
      }

      let requestBody = new TKExpyearOverrideRequestBody();
      let option = new TimekeeperDetail();
      option.startPeriod = selectedOption.period;
      option.tkId = selectedOption.tkId;
      requestBody.timekeeperDetails.push(option);
      requestBody.expYear = selectedOption.experienceYear;
      requestBody.duration = selectedOption.experienceYearDuration;

      this.service
        .post(
          environment.FIClientBaseEndpoint + 'v1/timekeeper/history/override/expyear/' + this.getfirmId,
          requestBody
        )
        .subscribe(
          (result) => {
            selectedOption.dataProcessing.experienceYear = false;
            selectedOption.dataProcessing.experienceYearDuration = false;
            this.tkHistoryRecordResponseOriginal.details[indx] = JSON.parse(JSON.stringify(selectedOption));
          },

          (error) => {
            selectedOption.dataProcessing.experienceYear = false;
            selectedOption.dataProcessing.experienceYearDuration = false;
            selectedOption.experienceYear = this.tkHistoryRecordResponseOriginal.details[indx].experienceYear;
            selectedOption.experienceYearDuration =
              this.tkHistoryRecordResponseOriginal.details[indx].experienceYearDuration;
            this.errorMessage = error.error;
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        );
    }
  }

  onSplitsClick(item, source, column) {
    const modalRef = this.modalService.open(SplitAccountGridComponent, {ariaLabelledBy: 'modal-header', size: 'lg'});
    modalRef.componentInstance.sourceOfClick = source;
    modalRef.componentInstance.firmId = this.getfirmId;

    if (source === 'grid') {
      let result = [];
      result.push(item);
      modalRef.componentInstance.selectedTimeKeeper = JSON.parse(JSON.stringify(result));
    } else {
      modalRef.componentInstance.selectedTimeKeeper = JSON.parse(JSON.stringify(item));
    }

    modalRef.componentInstance.header = 'Split Office Practice';

    modalRef.componentInstance.peerPracticeDropDownValues = this.tkFilterData.peerPractices;
    modalRef.componentInstance.peerOfficeDropDownValues = this.tkFilterData.peerOffices;
    modalRef.componentInstance.headers = [
      {
        name: 'peerOffice',
        value: 'Peer Office',
      },
      {
        name: 'peerPractice',
        value: 'Peer Practice',
      },
      {
        name: 'Percent',
        value: '%',
      },
      {
        name: 'Od',
        value: 'O/D',
      },
      {
        name: 'Action',
        value: '',
      },
    ];
    this.globalservice.skipFocusTwiceCombobox();
    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
          this.isRecordSelected = false;
          this.selectedRecord = [];
          this.updateActionDropdownItems();
          this.retrieveTKHistoryRecords();
          this.alerts.push({
            type: 'success',
            msg: 'Data saved Successfully.',
            timeout: 2500,
            closeable: true,
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  validateNumber(v: any) {
    if (v.value == '') v.value = v.value.slice(0, -1);
  }

  validateNumberWith(e) {
    if (e.keyCode == 43 || e.keyCode == 45 || e.keyCode == 101) {
      return false;
    }
  }

  selectedTimekeepers() {
    if (this.isSelectedTimekeeper) {
      this.gridData = new wjcCore.CollectionView(this.selectedRecord);
      this.gridData.pageSize = this.pageInfo.pageSize;
      this.totalCount = this.selectedRecord.length;
      this.gridData.moveToPage(0);
      this.pagination.page = 1;
    } else {
      this.retrieveTKHistoryRecords(false, false, {}, true);
    }
    this.updateActionDropdownItems();
  }
  getPeerOfficeCodes() {
    var sortable = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/office/pm', '').subscribe(
      (result) => {
        for (let obj of result) {
          this.pmDataKeyValue[obj['code']] = obj['description'];
          this.peerOfficeCodes.push(obj['description']);
        }
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  updateActionDropdownItems() {
    this.timekeeper = this.isSelectedTimekeeper ? 'All timekeepers' : 'Selected timekeepers';
    this.actionDropdownItems.forEach((element) => {
      if (
        (element.name == 'Split Office Practice' ||
          element.name == 'Selected timekeepers' ||
          element.name.startsWith('Rebuild')) &&
        this.selectedRecord.length <= 0
      ) {
        if (element.name.startsWith('Rebuild')) {
          this.actionDropdownItems[this.rebuildHistoryDropdownIndex].name = 'Rebuild history (0)';
        }
        element.isDisabled = true;
      } else {
        if (element.name == 'Selected timekeepers' || element.name == 'All timekeepers') element.name = this.timekeeper;
        element.isDisabled = false;
      }
    });
  }

  getMappingsFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/MAPPINGS_MENU', '')
      .toPromise()
      .then((result) => {
        return result['MAPPINGS_MENU'];
      });
  }

  onBentoAlertOccured(alerts: BentoAlertItemOptions[]) {
    this.alerts = [...this.alerts, ...alerts];
  }

  isString(s: any) {
    return typeof s === 'string' || s instanceof String ? true : false;
  }
  comparer(obj1, obj2) {
    var intObj1 = parseInt(obj1);
    var intObj2 = parseInt(obj2);
    const isANum = !isNaN(intObj1);
    const isBNum = !isNaN(intObj2);
    if (isANum && isBNum) {
      if (intObj1 > intObj2) return 1;
      else if (intObj1 < intObj2) return 0;
      else return 1;
    } else if ((!isANum && isBNum) || (isANum && !isBNum)) {
      return 1;
    } else {
      return null;
    }
  }

  updatedView(grid) {
    this.globalservice.skipFocusTwiceCombobox();
    this.globalservice.addPaginationAdditionalInfo();
  }
}
