import {Component, OnInit, ViewChild} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {GlobalPeerGroupList} from 'src/app/core/models/globalPeerGroupList.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-global-peer-groups',
  templateUrl: './global-peer-groups.component.html',
})
export class GlobalPeerGroupsComponent implements OnInit {
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  searchText = '';
  isDataLoading = false;
  globalPeerGroupsGridData: GlobalPeerGroupList[] = [];
  globalPeerGroupsData: GlobalPeerGroupList[] = [];
  isGlobalPeerGroupsFirmSelected = false;
  selectedGlobalPeerGroup: GlobalPeerGroupList;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  currentRecords: number = 0;
  totalRecords: number = 0;
  gridsSelectedRow = 0;
  gridsSelectedCol = 0;
  lastPressedKey: any;
  templatePeergroupLink = CellMaker.makeLink({
    text: '${item.peerLabel}',
    label: 'Link: ${item.peerLabel}',
    click: (e, ctx) => this.editGlobalPeerGroups(ctx.item),
    attributes: {
      tabIndex: -1,
      href: this.sanitizeUrl('#')
    },
  });

  constructor(private service: BaseService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.getGlobalPeerGroups();
    setTimeout(() => {
      let element = document.getElementById('Administrator');
      if (element) {
        element.classList.add('is-active');
      }
    }, 0);
    this.isGlobalPeerGroupsFirmSelected = false;
    this.globalPeerGroupsGridData = this.globalPeerGroupsData;
  }

  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    // flexGrid.select(-1,-1);
    flexGrid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
  }
  focusOnGrid(flexGrid, event) {
    flexGrid.select(0, 0);
  }

  searchGlobalPeerGroups() {
    this.globalPeerGroupsGridData = this.globalPeerGroupsData.filter(
      (element) => element.peerLabel.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
    );
    this.currentRecords = this.globalPeerGroupsGridData.length;
  }

  editGlobalPeerGroups(item: GlobalPeerGroupList) {
    this.isGlobalPeerGroupsFirmSelected = true;
    this.selectedGlobalPeerGroup = item;
  }
  backToGlobalPeerGroups() {
    this.isGlobalPeerGroupsFirmSelected = false;
  }

  getGlobalPeerGroups() {
    this.isDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/allglobal', '').subscribe(
      (result) => {
        this.isDataLoading = false;
        this.globalPeerGroupsGridData = result;
        this.globalPeerGroupsData = this.globalPeerGroupsGridData;
        this.currentRecords = this.globalPeerGroupsGridData.length;
        this.totalRecords = this.globalPeerGroupsGridData.length;
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col;
    this.gridsSelectedRow = args.row;
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }

  handleKeyDown(flexGrid, event) {
    if (event.code === 'Space') {
      let cell = flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
      let input = cell.querySelector('a');
      input.click();
      event.preventDefault();
    }
  }
  sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
