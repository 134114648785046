<div class="GlobalLayout GlobalLayout--twoColumn" *ngIf="!isCurrentVisualizationSelected" role="main">
  <main id="MainContent" tabindex="-1" class="Main">
    <header class="Main-header">
      <h1 class="Main-heading">Visualizations</h1>
    </header>
    <div role="presentation" class="Main-body Main-body--light">
      <div role="presentation" class="GlobalLayout-group">
        <div id="vt-nav" role="presentation" class="GlobalLayout-item GlobalLayout-item--small">
          <bento-side-nav
            role="presentation"
            class="NavSide"
            aria-label="Visualizations"
            [items]="items"
            [itemTemplate]="itemTemplate"
          >
            <ng-template #itemTemplate let-$item="item">
              <span>
                {{ $item.label }}
              </span>
            </ng-template>
          </bento-side-nav>
        </div>
        <div class="GlobalLayout-item GlobalLayout-item--large" *ngIf="items">
          <ng-container *ngIf="items[0].data.isVisible">
            <app-current-charts-list
              [isChartList]="isChartList"
              (currentVisualizationAction)="currentVisualizationActionHandler($event)"
            ></app-current-charts-list>
          </ng-container>
          <ng-container *ngIf="showChartTemplate && items[1].data.isVisible">
            <app-current-charts-list
              [isChartList]="isChartList"
              (currentVisualizationAction)="currentVisualizationActionHandler($event)"
              [isCalledForTemplates]="true"
            ></app-current-charts-list>
          </ng-container>
        </div>
      </div>
    </div>
  </main>
</div>
<div class="GlobalLayout" *ngIf="isCurrentVisualizationSelected">
  <main id="MainContent" tabindex="-1" class="Main" [bentoBusyLoader]="isDataLoading">
    <header class="Main-header">
      <div class="row">
        <div class="col">
          <app-global-breadcrumbs
            [isFirmPeerGroup]="true"
            (updateService)="backToCurrentVisualization()"
            [peerLabel]="isChartList ? selectedChartData.chartName : selectedChartData.templateName"
            [firmName]="'Visualizations'"
          ></app-global-breadcrumbs>
        </div>
      </div>
    </header>
    <div class="Main-body Main-body--NoPadding">
      <app-chart-details
        #ChartDetails
        [selectedChartData]="selectedChartData"
        [shouldShowActionButton]="true"
        [calledForScreen]="calledForScreen"
        (dataLoadingStateChanged)="handleDataLoadingStateChanged($event)"
      >
      </app-chart-details>
    </div>
  </main>
</div>
