import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {BentoLogService, BentoUidService, Keyboard} from '@bento/bento-ng';
import {Observable} from 'rxjs';
import {BentoListSubscribable} from './list/list-data-subscribable';
import {BentoListComponent} from './list/list.component';

export enum Selection {
  all = 0,
  selected = 1,
}

@Component({
  selector: 'app-bento-multiselect-list',
  templateUrl: 'multiselect-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BentoMultiselectListComponent extends BentoListSubscribable {
  /**
   * Replace "Show All" tab text other than default
   */
  @Input() showAllText: string;

  @Input() isRatesReport: boolean;
  /**
   * Replace "Show Selected" tab text other than default
   */
  @Input() showSelectedText: string;
  /**
   * Replace "Select All" text other than default
   */
  @Input() selectAllText: string;
  /**
   * Replace search input field label default
   */
  @Input() searchLabelText: string;
  /**
   * Replace search input field help text default
   */
  @Input() searchLabelHelpText?: string = '';

  /**
   * Aria labelledBy text override
   */
  @Input() ariaLabelValue: string;
  /**
   * Main Data seter
   */
  @Input()
  set itemsObservable(observable: Observable<any>) {
    this._setDataObservable(observable);
  }

  /**
   * Set the height of the list
   */
  @Input()
  set height(v: number) {
    this._listHeight = v - this.topPanel.nativeElement.offsetHeight;
  }

  /**
   * Width setting to this component
   */
  @Input()
  set width(v: number) {
    this._listWidth = v;
  }

  /**
   * Custom tempalte for list items except the checkbox elements
   */
  @Input() listItemTemplate: TemplateRef<any>;

  /**
   * An custom search and compare function to determine if a given item is a match to the search string
   * when an user starts to filter the list using the search input
   */
  @Input() searchCompare: (item: any, search: string) => boolean;

  /**
   * The maximum number of items that can be selected by the user.  0 is the default, indicates no restrictions.
   */
  @Input() maxSelectedItems = 0;

  /**
   * The minimum number of items that can be selected by the user.  0 is the default, indicates no restrictions.
   */

  @Input() minSelectedItems = 0;
  /**
   * The option can be selected by the user whether to show select all option on top of the list.
   */
  @Input() isSelectAllVisible = true;

  /**
   * Notify when the length of the main items data is changed
   */
  @Output() lengthChange: EventEmitter<number> = new EventEmitter();

  /**
   * Notify when a row is clicked with an OS pointer
   */
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Reference to the top panel
   */
  @ViewChild('topPanel', {static: true}) topPanel: ElementRef;

  @ViewChild('list', {static: true}) list: BentoListComponent;
  filterAriaLabelValue: string;
  /**
   * List Height
   */
  _listHeight = 0;

  /**
   * List Width
   */
  _listWidth = 0;

  /**
   * List for filtered array
   */
  _filteredList: any[];

  /**
 
  /**
   * Search Term
   */
  _searchTerm: string = '';

  /**
   * List options
   */
  _listOptions: any;

  /**
   * Emit Data change
   */
  _dataChange: EventEmitter<any> = new EventEmitter();

  /**
   * Refernce to current datasize
   */
  _dataSize = 0;
  _searchResultInfo = '';

  /**
   * Index to determine which item is active
   */
  _activeIndex = -1;

  /**
   * If the list is disabled, don't allow selections via keyboard.
   */
  _isListDisabled = false;

  childCount = 0;
  parentItem: any;

  /**
   * selected list
   */
  get selectedList(): any[] {
    const list = [];

    for (const item of this._data) {
      if (this.minSelectedItems == 0) {
        if (this.checkStatusRef.get(item)) {
          list.push(item);
        }
      } else {
        if (this.checkStatusRef.get(item.key)) {
          list.push(item);
        }
      }
    }

    return list;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedList')
  set _selectedList(v: any[]) {
    if (this._data) {
      let notIncluded = false;

      // Check if ALL objects are in the main dataset
      for (const row of v) {
        if (this.minSelectedItems > 0 && this._data.filter((item) => item.key == row.key).length == 0) {
          notIncluded = true;
          this.logService.warn('Bento Multiselect Overlay:', 'Object is not in the original dataset ->', row);
        }
        if (this.maxSelectedItems > 0 && this._data.indexOf(row) === -1) {
          notIncluded = true;
          this.logService.warn('Bento Multiselect Overlay:', 'Object is not in the original dataset ->', row);
        }
      }

      // Do not proceed when selected list does not qualify
      if (notIncluded) {
        return;
      }

      // clear all checked status
      this.clearCheckStatus();
      // update selected list

      for (const listItem of v) {
        if (this.minSelectedItems > 0) {
          this.checkStatusRef.set(listItem.key, true);
        } else {
          this.checkStatusRef.set(listItem, true);
        }
      }

      // update select all row checkbox
      this.checkAllSelected();
    }
  }

  @Output() selectedListChange: EventEmitter<any> = new EventEmitter();

  /**
   * Selection to defines filtering of the list
   */
  _selection: Selection = Selection.all;

  /**
   * Select all row
   */
  _selectAllRow: {name: string; isSelectAll: boolean} = {
    name: 'BENTO_UI_MULTISELECT_OVERLAY_SELECT_ALL',
    isSelectAll: true,
  };

  /**
   * Unique ID
   */
  _uid: string;

  @ViewChild('searchField', {static: true}) searchFieldRef: ElementRef;
  @ViewChild('showAll', {static: true}) showAllRef: ElementRef;

  /**
   * A status list to represent whether an item is checked or not
   */
  private checkStatusRef = new Map();

  constructor(bentoUid: BentoUidService, private logService: BentoLogService, private elementRef: ElementRef) {
    super();
    this._listOptions = {checkStatusRef: this.checkStatusRef};
    this._uid = bentoUid.generate();
  }

  /**
   * Gain focus on the search field
   */
  focusOnSearchField() {
    // Focus back to search field
    setTimeout(() => {
      this.searchFieldRef.nativeElement.focus();
    });
  }

  /**
   * Gain focus on the show all button
   */
  focusOnShowAllButton() {
    // Focus back to show all button
    setTimeout(() => {
      this.showAllRef.nativeElement.focus();
    });
  }

  /**
   * Set list selection
   */
  show(s: Selection) {
    this._activeIndex = -1;
    this._selection = s;
    this.onSearch(this._searchTerm);
  }

  /**
   * Clear search input
   */
  clearSearchItem() {
    this._searchTerm = '';
    this.searchFieldRef.nativeElement.value = '';
    this.focusOnSearchField();
    this.onSearch(this._searchTerm);
  }
  /**
   * Re-render the list
   */
  refreshView() {
    this.list.checkViewportSize();
  }

  /**
   * Search and display the list based on search string `s`
   */
  onSearch(s: string, isQuietUpdate?) {
    if (!s || s.length === 0) {
      if (this._selection === Selection.all) {
        this._filteredList = this.processDefaultFilteredList(this._data);
      } else {
        this._filteredList = this.selectedList;
      }
    } else {
      if (this._selection === Selection.all) {
        this._filteredList = this.processDefaultFilteredList(this.searchFromList(this._data, s));
      } else {
        this._filteredList = this.searchFromList(this.selectedList, s);
      }
    }

    this._dataSize = this._filteredList.length;
    if (this._dataSize > 0) this._searchResultInfo = this._dataSize + ' results available.';
    else this._searchResultInfo = 'Zero results. Try another query';

    // update select all row checkbox
    this.checkAllSelected();

    this._searchTerm = s;

    // Notify Bento List
    this._dataChange.emit(this._filteredList);
  }

  /**
   * Toggle the row that is clicked
   */
  toggleRowChecked(row: any) {
    // Flip checked state

    const checked = this.minSelectedItems == 0 ? !this.checkStatusRef.get(row) : !this.checkStatusRef.get(row.key);

    if (this.minSelectedItems == 0) {
      if (!(this._isListDisabled && checked)) {
        this.checkStatusRef.set(row, checked);
      }
    } else {
      if (!(this._isListDisabled && !checked)) {
        this.checkStatusRef.set(row.key, checked);
      }
    }

    if (row.isSelectAll) {
      // Select all
      for (const r of this._filteredList) {
        // Mark selected only if object is not disabled
        if (!r.disabled) {
          if (this.minSelectedItems == 0) {
            this.checkStatusRef.set(r, checked);
          } else {
            this.checkStatusRef.set(r.key, checked);
          }
        }
      }
    }

    //checked-unchecked parent category, set itdeterminate status
    if (this.minSelectedItems == 0) {
      this.parentItemChecked(row, checked);
    }

    this.checkAllSelected();

    // Emit changes
    this.selectedListChange.emit(this.selectedList);
  }

  parentItemChecked(row, checked) {
    //to select all child if parent selected
    let selCount;
    let parentItem = this._data.filter((item) => item.id == row.id)[0];

    if (row.groupId === null) {
      let selCountWithParent = this._data.filter((item) => this.checkStatusRef.has(item));

      if (selCountWithParent.length > 0) {
        if ('groupId' in selCountWithParent[0])
          selCount = selCountWithParent.filter((item) => item.groupId != null).length;
        else selCount = selCountWithParent.length;
      }
      for (const r of this._data) {
        //To check and allow selection of items till maximum allowed
        if (r.groupId == row.id && selCount < this.maxSelectedItems) {
          //won't increase selcount if element already exist's
          if (r.id != row.id && !this.checkStatusRef.has(r)) selCount++;
          this.checkStatusRef.set(r, checked);
        } else if (r.groupId == row.id) {
          if (r.id != row.id && !this.checkStatusRef.has(r)) selCount++;
          if (!checked) this.checkStatusRef.set(r, checked);
        }
      }
    }

    if (this.maxSelectedItems > 0 && selCount > this.maxSelectedItems) this.checkStatusRef.set(parentItem, false); //to uncheck header
    //if (this.minSelectedItems > 0 && selCount < this.minSelectedItems) this.checkStatusRef.set(parentItem, true);
    //select parent if all children selected
    if (row.groupId != null && checked) {
      this.checkStatusRef.set(row, checked);
      //get all children of the selected item
      const childArr = this._data.filter((item) => item.groupId == row.groupId);

      //get parent of the selected item
      this.parentItem = this._data.filter((item) => item.id == row.groupId)[0];
      this.childCount = 0;
      for (let index = 0; index < childArr.length; index++) {
        if (this.checkStatusRef.get(childArr[index])) {
          this.childCount++;
        }
      }
      //compare children and selected children if match then select parent item
      if (childArr.length == this.childCount) {
        this.checkStatusRef.set(this.parentItem, true);
      }
    } else {
      //unchecked parent if childrean unchecked
      this.parentItem = this._data.filter((item) => item.id == row.groupId)[0];
      if (this.parentItem != null) {
        this.checkStatusRef.set(this.parentItem, false);

        let childArr = this._data.filter((item) => item.groupId == row.groupId);

        this.childCount = 0;
        for (let index = 0; index < childArr.length; index++) {
          if (this.checkStatusRef.get(childArr[index])) {
            this.childCount++;
          }
        }
      }
    }
  }

  /**
   * Search a string from an array
   */
  searchFromList(a: any[], s: string): any[] {
    const l = [];
    const searchCompare =
      this.searchCompare ||
      ((item: any, search: string) => {
        // Default search and compare function and only searches the name property //to do
        return item.name.toLowerCase().indexOf(search) > -1;
      });

    s = s.toLowerCase();
    for (const row of a) {
      if (searchCompare(row, s)) {
        l.push(row);
      }
    }
    return l;
  }

  /**
   * When a row is clicked
   * ~@private
   */
  _onRowClick($event, $row, $index) {
    if ($row.disabled) return;

    this._activeIndex = $index;
    this.toggleRowChecked($row);

    // Emit row click
    this.rowClick.emit($event);

    if (this._searchTerm != '') this.focusOnSearchField();

    $event.preventDefault();
    $event.stopPropagation();
  }

  _onTabsKeydown(e: KeyboardEvent) {
    if ([Keyboard.SPACE, Keyboard.ENTER].includes(e.keyCode)) {
      this._selection === Selection.selected ? this.show(Selection.all) : this.show(Selection.selected);
    }
    if ([Keyboard.RIGHT, Keyboard.LEFT].includes(e.keyCode)) {
      const navButtons: HTMLElement[] = Array.from(this.elementRef.nativeElement.querySelectorAll('.nav-pills button'));
      const currentFocusedNdx = navButtons.findIndex((el) => el === document.activeElement);
      const nextFocusedNdx = (currentFocusedNdx + 1) % navButtons.length;
      const elToFocus = navButtons[nextFocusedNdx];
      elToFocus?.focus();
    }
  }
  /**
   * When a keydown event is executed on the row
   */

  _onRowKeydown(e: KeyboardEvent, $row) {
    switch (e.keyCode) {
      case Keyboard.ENTER:
      case Keyboard.SPACE:
        this.toggleRowChecked($row);
        // Emit row click
        this.rowClick.emit(e);
        e.preventDefault();
        break;
    }
  }

  /**
   * Callback when BentoList gains focus
   */
  _onListFocus(e: FocusEvent) {
    if (this._activeIndex < 0) {
      this._activeIndex = 0;
    }
  }

  _onListKeydown(e: KeyboardEvent) {
    const dataSize = this._filteredList.length;

    switch (e.keyCode) {
      case Keyboard.DOWN:
        //Prevent default behavior when the last item is selected and down key is pressed
        if(this._activeIndex < dataSize - 1){
          this._activeIndex ++ ;              
          }
          this.list.showRowIndex(this._activeIndex);        
        e.preventDefault();
        break;

      case Keyboard.UP:
        //Prevent default behavior when the first item is selected and up key is pressed
        if(this._activeIndex > 0){
          this._activeIndex --;          
        }
        this.list.showRowIndex(this._activeIndex);        
        e.preventDefault();
        break;

      case Keyboard.ENTER:
      case Keyboard.SPACE:
        const currentRow = this._filteredList[this._activeIndex];
        //When max item selected is set, and the selected item is disabled, do not allow selection
        if (!currentRow.disabled) {
          this.toggleRowChecked(currentRow);
          this.rowClick.emit(e); // Emit row click
        }
        e.preventDefault();
        break;
    }
  }

  /**
   * Override `BentoListSubscribable` method
   */
  _onDataUpdate(data: any[]) {
    this.updateCheckedStatusRef();
    this.lengthChange.emit(this._data.length);
    this.onSearch(this._searchTerm, true);
  }

  /**
   * Update checked status ref everytime when base data set is updated
   */
  private updateCheckedStatusRef() {
    const newStatusRef = new Map();
    for (const listItem of this._data) {
      if (this.minSelectedItems == 0) {
        newStatusRef.set(listItem, this.checkStatusRef.get(listItem));
      } else {
        newStatusRef.set(listItem, this.checkStatusRef.get(listItem.key));
      }
    }

    delete this.checkStatusRef;

    // Assign the new status reference
    this.checkStatusRef = newStatusRef;
    // Make sure to update the list options reference as well
    this._listOptions.checkStatusRef = newStatusRef;
  }

  /**
   * Check if all in the filtered list are selected
   */
  private checkAllSelected() {
    let dataListToCheck;
    let allChecked = true;
    // There are selections
    if (Array.isArray(this._filteredList) && this._filteredList.length) {
      // only check where there is a filtered list
      dataListToCheck = this._filteredList;
    } else {
      dataListToCheck = this._data;
    }

    for (const item of dataListToCheck) {
      // Check and see if all items in the current list are checked
      if (this.minSelectedItems == 0) {
        if (!this.checkStatusRef.get(item) && item.name !== this._selectAllRow.name) {
          // There is at least one that is not checked
          allChecked = false;
          break;
        }
      } else {
        if (!this.checkStatusRef.get(item.key) && item.name !== this._selectAllRow.name) {
          // There is at least one that is not checked
          allChecked = false;
          break;
        }
      }
    }

    if (this.maxSelectedItems > 0) {
      let selCountWithParent = this._data.filter((item) => this.checkStatusRef.has(item));
      let selCount;
      if (selCountWithParent.length > 0) {
        if ('groupId' in selCountWithParent[0])
          selCount = selCountWithParent.filter((item) => item.groupId != null).length;
        else selCount = selCountWithParent.length;
      }
      if (selCount >= this.maxSelectedItems) {
        for (const item of this._data) {
          if (this.minSelectedItems == 0) {
            if (!this.checkStatusRef.has(item)) {
              item.disabled = 1;
            }
          } else {
            if (!this.checkStatusRef.has(item.key)) {
              item.disabled = 1;
            }
          }
        }
        this._isListDisabled = true;
      } else {
        for (const item of this._data) {
          item.disabled = 0;
        }
        this._isListDisabled = false;
      }

      this._dataChange.emit(dataListToCheck);
    }

    if (this.minSelectedItems > 0) {
      let selCountWithParent = this._data.filter((item) => this.checkStatusRef.has(item.key));
      let selCount = selCountWithParent.length;

      // if (selCountWithParent.length > 0) {
      //   if ('groupId' in selCountWithParent[0])
      //     selCount = selCountWithParent.filter((item) => item.groupId != null).length;
      //   else selCount = selCountWithParent.length;
      // }
      if (selCount <= this.minSelectedItems) {
        for (const item of this._data) {
          if (this.checkStatusRef.has(item.key)) {
            item.disabled = 1;
          }
        }
        this._isListDisabled = true;
      } else {
        for (const item of this._data) {
          item.disabled = 0;
        }
        this._isListDisabled = false;
      }

      this._dataChange.emit(dataListToCheck);
    }

    this.checkStatusRef.set(this._selectAllRow, allChecked);
  }

  /**
   * Format the list structure based on a filtered list
   */
  private processDefaultFilteredList(list: any[]): any[] {
    return list.length > 1 &&
      this.maxSelectedItems === 0 &&
      this.minSelectedItems === 0 &&
      this.isSelectAllVisible != false
      ? [this._selectAllRow].concat(list)
      : list;
  }

  /**
   * Clear all checked status
   */
  private clearCheckStatus() {
    this.checkStatusRef = new Map();
    delete this._listOptions.checkStatusRef;
    this._listOptions.checkStatusRef = this.checkStatusRef;
  }
  ngOnInit(): void {
    this.filterAriaLabelValue = this.ariaLabelValue + ' filter';
  }
}
