<div class="modal-content" [bentoBusyLoader]="isDataLoading">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalExport" class="modal-header h2">Export</h3>
  <div class="modal-body">
    <bento-alert [inline]="true" [items]="warningAlerts"></bento-alert>
    <p>Select export criteria below.</p>
    <table class="table table-striped">
      <thead>
        <tr>
          <td id="blank"></td>
          <th class="table-header" id="co1" scope="col" headers="blank">
            <bento-checkbox
              ngDefaultControl
              class="checbox-display"
              (change)="onChangeHeader('detailed')"
              [(ngModel)]="detailed"
              id="detailed"
              name="detailed"
            >
            </bento-checkbox>
            <label class="label-display" for="detailed">Detailed</label>
          </th>
          <th class="table-header" id="co1" scope="col" headers="blank">
            <bento-checkbox
              (change)="onChangeHeader('monthly')"
              ngDefaultControl
              class="checbox-display"
              [(ngModel)]="monthly"
              id="monthly"
              name="monthly"
            >
            </bento-checkbox>
            <label class="label-display" for="monthly">Monthly</label>
          </th>
          <th class="table-header" id="co1" scope="col" headers="blank">
            <bento-checkbox
              (change)="onChangeHeader('quarter')"
              class="checbox-display"
              ngDefaultControl
              [(ngModel)]="quarter"
              id="quarter"
              name="quarter"
            >
            </bento-checkbox>
            <label class="label-display" for="quarter">Quarter</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let metric of metrics">
          <td id="c1" headers="blank">{{ metric.name }}</td>
          <td class="text-center">
            <bento-checkbox
              [(ngModel)]="metric.detailed"
              id="detailed {{ metric.name }}"
              [disabled]="metric.isDisabled"
              (change)="onCheckChange()"
              [attr.aria-label]="'detailed ' + metric.name"
            ></bento-checkbox>
          </td>
          <td class="text-center">
            <bento-checkbox
              [(ngModel)]="metric.monthly"
              id="'monthly' + {{ metric.name }}"
              [disabled]="metric.isDisabled"
              (change)="onCheckChange()"
              [attr.aria-label]="'monthly ' + metric.name"
            ></bento-checkbox>
          </td>
          <td class="text-center">
            <bento-checkbox
              [(ngModel)]="metric.quarter"
              id="'quarter' + {{ metric.name }}"
              [disabled]="metric.isDisabled"
              (change)="onCheckChange()"
              [attr.aria-label]="'quarter ' + metric.name"
            ></bento-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="totalMetricSelected == 0" (click)="onExportClick()">
      Export
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('OK')">Cancel</button>
  </div>
</div>
