import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {firmTypeValues} from 'src/app/shared/constants';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {store, eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {Customer3EMigration} from 'src/app/core/models/3e-cloud-credential.model';
import {BentoComboboxOptions} from '@bento/bento-ng';
import {EncryptionDecryptionService} from 'src/app/core/services/encryption-decryption/encryption-decryption.service';
import {BehaviorSubject} from 'rxjs';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-fms',
  templateUrl: './fms.component.html',
  styleUrls: ['./fms.component.scss']
})
export class FmsComponent implements OnInit {
  @Input() isNewFirm;
  @Input() firmId;
  @Output() refreshSideNavigation = new EventEmitter<boolean>();
  @ViewChild('fms', {static: true}) fms: any;
  fmsTypes: BehaviorSubject<any>;
  fmsData = firmTypeValues;
  alerts: BentoAlertItemOptions[] = [];
  fmsType: any;
  fmsTypeObjSelected: any;
  resetFMSModel: number;
  isGetSettings: boolean;
  errorMessage = '';
  successMsg: string;
  fmsModel: any;
  fmsValidations = '^.{1,128}';
  validations: any;
  isGenericFMSEnabled  = true;
  isFMSUpdated = false;
  isCustomerMigration = false;
  customerMigration: any;
  customerMigrateDb: any;
  customerMigrateMaxLengthError: any;
  showCustomerMigration: any;
  customer3EMigration: Customer3EMigration = new Customer3EMigration();
  resetCustomer3EMigration: Customer3EMigration;
  resetIsCustomerMigration: boolean = false;
  comboboxOptions: BentoComboboxOptions = {
    searchable: false,
    labelFormatter: (row) => {
      return row.label;
    },
  };
  constructor(
    private service: BaseService,
    private localStorageService: LocalStorageService,
    private encryptionService: EncryptionDecryptionService,
    private globalservice: GlobalService
  ) {
    this.validations = {
      fmsValidations: {
        pattern: 'Error: Pmm database name is too long',
      },
    };
  }
  async ngOnInit() {
    this.isGenericFMSEnabled = await this.getAnyFeatureFlag('GENERIC-FMS');
    if(!this.isGenericFMSEnabled){
      this.fmsData = this.fmsData.filter(item => item.value !== '9');
    }
    this.fmsTypes = new BehaviorSubject(this.fmsData);
    if (!this.isNewFirm) this.getFirmSettings(this.firmId);
    else this.isGetSettings = true;
    this.showCustomerMigration = await this.getCustomeMigrationFeatureFlag();
  }

  isCustomerMigrationChange() {
    if (!this.isCustomerMigration) {
      this.customerMigrateDb = '';
    }
  }

  onChangeFmsField(fmsTypeObj: any) {
    this.fmsType = fmsTypeObj.value;
  }

  getCustomeMigrationFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/CUSTOMER_MIGRATION', '')
      .toPromise()
      .then((result) => {
        return result['CUSTOMER_MIGRATION'];
      });
  }

  getFirmSettings(firmId) {
    this.isFMSUpdated = true;
    this.isGetSettings = false;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/' + firmId, '').subscribe(
      (result) => {
        this.fmsType = result.financialManagementSystem.financialManagementSystem;
        this.globalservice.skipFocusTwiceCombobox();
        this.fmsTypeObjSelected = this.fmsData.find((x) => x.value == this.fmsType);
        this.customerMigrateDb = result.financialManagementSystem.pmmDatabase;
        this.isCustomerMigration = result.financialManagementSystem.runCustomerMigration;
        this.localStorageService.set('FIRM_TYPE', this.fmsType);
        eventDispatcher.next({type: ActionTypes.SetFirmParameterSetting, payload: result});
        this.resetFMSModel = JSON.parse(JSON.stringify(result.financialManagementSystem.financialManagementSystem));
        this.resetIsCustomerMigration = this.isCustomerMigration ? true : false;
        if (this.fmsType == '5' && this.isCustomerMigration) this.getCustomerMigratioForCloud();
        this.isGetSettings = true;
        this.isFMSUpdated = false;
      },
      (error) => {
        this.isFMSUpdated = false;
        this.isGetSettings = true;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  // checkCustomerMigration() {
  //   if (this.isCustomerMigration) {
  //     this.customerMigration = 'Y';
  //   } else {
  //     this.customerMigration = 'N';
  //   }
  // }
  saveFMS() {
    this.isFMSUpdated = true;
    this.alerts = [];
    if (this.showCustomerMigration && this.isCustomerMigration) {
      let pmmDatabase = '';
      if (this.fmsType == '5') {
        pmmDatabase = this.customer3EMigration.DBName;
      } else {
        pmmDatabase = this.customerMigrateDb;
      }
      this.fmsModel = {
        financialManagementSystem: this.fmsType,
        runCustomerMigration: this.isCustomerMigration,
        pmmDatabase: pmmDatabase,
      };
    } else {
      this.fmsModel = {
        financialManagementSystem: this.fmsType,
        runCustomerMigration: false,
        pmmDatabase: '',
      };
    }

    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/fms/' + this.firmId, this.fmsModel)
      .subscribe(
        (result) => {
          this.resetFMSModel = this.fmsType;
          this.resetIsCustomerMigration = this.isCustomerMigration ? true : false;
          this.localStorageService.set('FIRM_TYPE', this.fmsType);
          if (this.fmsType == '5') {
            this.refreshSideNavigation.emit(true);
            if (this.isCustomerMigration) {
              this.saveCustomerMigratioForCloud();
            } else {
              this.successMsg = 'Data modified successfully.';
              this.isFMSUpdated = false;
              this.alerts.push({
                type: 'success',
                msg: this.successMsg,
                timeout: 2500,
                closeable: true,
              });
            }

            this.isFMSUpdated = false;
          } else {
            this.successMsg = 'Data modified successfully.';
            this.isFMSUpdated = false;
            this.alerts.push({
              type: 'success',
              msg: this.successMsg,
              timeout: 2500,
              closeable: true,
            });
            this.refreshSideNavigation.emit(false);
          }
        },
        (error) => {
          this.isFMSUpdated = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  resetFMS() {
    this.isFMSUpdated = true;
    if (typeof this.resetFMSModel !== 'undefined') {
      this.fmsType = JSON.parse(JSON.stringify(this.resetFMSModel));
      this.isCustomerMigration = this.resetIsCustomerMigration ? true : false;
      if (this.fmsType == 5) {
        this.customer3EMigration = JSON.parse(JSON.stringify(this.resetCustomer3EMigration));
      }
    }
    this.successMsg = 'Data reset successfully.';
    this.alerts.push({
      type: 'success',
      msg: this.successMsg,
      timeout: 2500,
      closeable: true,
    });
    this.isFMSUpdated = false;
  }
  getCustomerMigratioForCloud() {
    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/key/firm/' +
          this.firmId +
          '/secretkeytype/3e-customer-migration-secret-value',
        ''
      )
      .toPromise()
      .then(
        (result) => {
          let decryptedValue;
          console.log(result);
          decryptedValue = this.encryptionService.decrypt(result.value);
          let values = decryptedValue.split(',');
          console.log(values);
          if (values.length == 4) {
            this.customer3EMigration.host = values[0];
            this.customer3EMigration.DBName = values[1];
            this.customer3EMigration.userName = values[2];
            this.customer3EMigration.password = values[3];
            this.resetCustomer3EMigration = JSON.parse(JSON.stringify(this.customer3EMigration));
          } else {
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          if (this.errorMessage != 'Error while retrieving the values') {
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        }
      );
  }

  saveCustomerMigratioForCloud() {
    let encryptedValue;
    let valuesText =
      this.customer3EMigration.host +
      ',' +
      this.customer3EMigration.DBName +
      ',' +
      this.customer3EMigration.userName +
      ',' +
      this.customer3EMigration.password;
    encryptedValue = this.encryptionService.encrypt(valuesText);
    this.service
      .post(
        environment.FIClientBaseEndpoint +
          'v1/key/firm/' +
          this.firmId +
          '/secretkeytype/3e-customer-migration-secret-value',
        {value: encryptedValue}
      )
      .subscribe(
        (result) => {
          this.successMsg = 'Data modified successfully.';
          this.isFMSUpdated = false;
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
          this.resetCustomer3EMigration = JSON.parse(JSON.stringify(this.customer3EMigration));
        },
        (error) => {
          this.isFMSUpdated = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
  getAnyFeatureFlag(flagName: string): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - STAFFING-RATIO ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/' + flagName, '')
      .toPromise()
      .then((result) => {
        return result[flagName];
      });
  }
}
