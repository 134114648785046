<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="is3EUpdated">
  <form name="cloudCredential3E" #cloudCredntialSetting="ngForm" class="Section-form Form" bfmForm>
    <header class="Section-header">
      <h2 class="Section-heading">3E cloud client credential  </h2>
    </header>
    <div class="Section-body">
      <div class="form-group Form-input Form-input--medium">
        <label for="tenantId" class="bento-label-required">Tenant id</label>
        <input
          style ="width:120% !important"
          bfmField
          bfmLabel="tenant Id"
          id="tenantId"
          name="tenantId"
          type="text"
          class="form-control"
          [(ngModel)]="cloudCredential3E.tenantId"
          required
        />
      </div>
      <div  class="form-group Form-input Form-input--medium" >
        <label for="clientId" class="bento-label-required">Client id</label>
        <input
          style ="width:120% !important"
          bfmField
          bfmLabel="Client id"
          id="clientId"
          name="clientId"
          type="text"
          class="form-control"
          [(ngModel)]="cloudCredential3E.clientId"
          required
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="clientSecret" class="bento-label-required">Client secret</label>
        <input
          style ="width:120% !important"
          bfmField
          bfmLabel="Client secret"
          id="clientSecret"
          name="clientSecret"
          type="text"
          class="form-control"
          [(ngModel)]="cloudCredential3E.clientSecret"
          required
        />
      </div>

     
      <div class="form-group Form-input Form-input--medium">
        <label for="grantType" class="bento-label-required">Grant type</label>
        <input
          style ="width:120% !important"
          bfmField
          bfmLabel="Grant type"
          id="grantType"
          name="grantType"
          type="text"
          class="form-control"
          required
          [(ngModel)]="cloudCredential3E.grantType"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="scope" class="bento-label-required">Scope</label>
        <input
          style ="width:120% !important"
          bfmField
          bfmLabel="scope"
          id="scope"
          name="scope"
          type="text"
          class="form-control"
          required
          [(ngModel)]="cloudCredential3E.scope"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="instanceId" class="bento-label-required">Instance id</label>
        <input
          style ="width:120% !important"
          bfmField
          bfmLabel="instanceId"
          id="instanceId"
          name="instanceId"
          type="text"
          class="form-control"
          required
          [(ngModel)]="cloudCredential3E.instanceId"
        />
      </div>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetInformation()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="cloudCredntialSetting.invalid"
            (click)="save3ECloudCredential()"
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
      </div>
    </footer>
  </form>
</section>
