import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  BentoAlertItemOptions,
  BentoComboboxOptions,
  BentoModalConfirmationCloseReason,
  BentoModalConfirmationService,
} from '@bento/bento-ng';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject} from 'rxjs';
import {
  CreateChart,
  DateRange,
  FixedRange,
  Option,
  SelectionFieldRules,
  VisualizationOptions,
  VisualizationTemplate,
  VisualizationTypes,
} from 'src/app/core/models/create-chart.model';
import {FixedTimeRange} from 'src/app/core/models/create-view.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {SaveViewComponent} from '../../create-view/save-view/save-view.component';
import {PeerCheckServiceService} from '../../peer-check/peer-check-service.service';
import {ChartDetailsComponent} from '../chart-details/chart-details.component';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {NgForm} from '@angular/forms';
import {DateRangeComponent} from '../shared/date-range/date-range.component';
import $ from 'jquery';
import { BfmFieldEvent } from '@bento/bfm-ng';
declare let TRAAC: any;

@Component({
  selector: 'app-create-chart',
  templateUrl: './create-chart.component.html',
})
export class CreateChartComponent implements OnInit, OnDestroy {
  alerts: BentoAlertItemOptions[] = [];
  openPPG = false;
  isCopyEditRequest: boolean;
  isReadyToSave = false;
  showNewFilter = false;
  isInputPanelDataLoading: boolean;
  isCollapsed = false;
  disableDropdowns = true;
  isDataLoading: boolean;
  errorMessage = '';
  filterPanelFlag = true;
  isFilterPanelOpen = true;
  createChartModel: CreateChart = new CreateChart();
  defaultPeerGroup: any;
  defaultPractices: any;
  defaultOffices: any;
  defaultTitles: any;
  defaultAssociateYears: any;
  defaultSrAssociateYears: any;
  defaultPartnerYears: any;

  defaultRange: any;
  defaultCurrentStartDate: any;
  defaultCurrentEndDate: any;

  createChartSelectionRules: SelectionFieldRules;

  // chartTemplateData should filled from service API
  chartTemplateData;

  // Combobox
  dataStream: BehaviorSubject<any>;
  dateRangeStream: BehaviorSubject<any>;
  dateRangeTypeStream: BehaviorSubject<any>;
  dateRangeYearsStream: BehaviorSubject<any>;
  dateRangeQuarterStream: BehaviorSubject<any>;
  dateRangeMonthStream: BehaviorSubject<any>;

  peerGroupsStream: BehaviorSubject<Option[]>;
  practiceGroupsStream: BehaviorSubject<Option[]>;
  metricsStream: BehaviorSubject<Option[]>;
  officesStream: BehaviorSubject<Option[]>;
  titlesStream: BehaviorSubject<Option[]>;
  associateYearsStream: BehaviorSubject<Option[]>;
  srAssociateYearsStream: BehaviorSubject<Option[]>;
  partnerYearsStream: BehaviorSubject<Option[]>;
  visualizationTypes: any = VisualizationTypes;
  openCancelPopup: any = false;
  defaultMetrics: any;

  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    labelFormatter: (row) => {
      return row.name;
    },
  };

  isPartnerYear: boolean;
  isAssociateYear: boolean;
  isSrAssociateYear: boolean;
  allowedPartnerYearTitles = [-10, 1, 2];
  allowedAssociateYearTitles = [4];
  allowedSrAssociateYearTitles = [3];
  readonly selectedVizData: any;
  firmBasicData: any;
  pcViewCols: any;
  isGetBasicAPICalled: boolean;
  isFirmSelected: boolean;
  vizTemplateDetail: VisualizationTemplate;
  isQuickSightEnabled = false;
  peerLabel: any;
  @ViewChild('createChart') createChart: NgForm;
  @ViewChild('dateRange') dateRangeComponent: DateRangeComponent;
  @ViewChild('ChartDetails') chartDetailsComponent: ChartDetailsComponent;
  defaultSelectionChartModel: CreateChart;

  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  constructor(
    private _peercheckMsgHandlerService: PeercheckMessageHandlerService,
    private router: Router,
    private _peercheckService: PeerCheckServiceService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public modalService: NgbModal,
    private service: BaseService,
    public modalServiceConfirm: BentoModalConfirmationService,
    private featureToggleService: FeatureToggleService
  ) {
    this.selectedVizData = _peercheckMsgHandlerService.getSelectedVisualizationData;

    this.createChartSelectionRules = new SelectionFieldRules();
  }

  async ngOnInit() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService
      .getFeatureValue('VISUALIZATION_QUICKSIGHT')
      .then((val) => (this.isQuickSightEnabled = val));
    /*************************************************/
    if (this.selectedVizData) {
      if (this._peercheckMsgHandlerService.getSelectedVisualizationType == 'My visualizations') {
        this.peerLabel = 'My visualizations';
      } else {
        this.peerLabel = 'Visualization templates';
      }

      if (this._peercheckMsgHandlerService.getSelectedVisualizationType) {
        this.isCopyEditRequest =
          this._peercheckMsgHandlerService.getSelectedVisualizationType == VisualizationOptions.MyVisualizations;
      }
      await this.getBasicDetails();

      if (this.isQuickSightEnabled) this.logQuickSightEvent(this.selectedVizData);
    } else {
      if ((this.route.snapshot, this.route.snapshot.params, this.route.snapshot.params.id)) {
        this.router.navigate(['firm/' + this.getDefaultOrSelectedFirmId + '/visualizations']);
      } else {
        this.router.navigate(['visualizations']);
      }
      //this.router.navigate(['visualizations']);
    }
    this.showNewFilter = await this.getNewFilterVisualizationFeatureFlag();
  }

  ngOnDestroy(): void {
    this._peercheckMsgHandlerService.setSelectedVisualizationData = null;
    //this._peercheckMsgHandlerService.setSelectedVisualizationType = null;
    this._peercheckService.resetVisualizationTemplateDetails();
  }
  backToCurrentVisualization() {
    let metrics = JSON.parse(this.defaultMetrics);
    let practices = JSON.parse(this.defaultPractices);
    let offices = JSON.parse(this.defaultOffices);
    let titles = JSON.parse(this.defaultTitles);
    let peerGroups = JSON.parse(this.defaultPeerGroup);
    let associateYears = JSON.parse(this.defaultAssociateYears);
    let srAssociateYears = JSON.parse(this.defaultSrAssociateYears);
    let partnerYears = JSON.parse(this.defaultPartnerYears);
    let dateRange = JSON.parse(this.defaultRange);
    const selectedMetrics = metrics.values.filter((met) => met.selected);
    const selectedPractices = practices.values.filter((prac) => prac.selected);
    const selectedPeerGroups = peerGroups.values.filter((peerGroup) => peerGroup.selected);
    const selectedOffices = offices.values.filter((office) => office.selected);
    const selectedTitles = titles.values.filter((title) => title.selected);
    const selectedAssociateYears = associateYears.filter((as) => as.selected);
    const selectedSrAssociateYears = srAssociateYears.filter((sr) => sr.selected);
    const selectedPartnerYears = partnerYears.filter((py) => py.selected);
    const selectedDateRange = dateRange.filter((dr) => dr.selected);

    let formDirty = false;
    if (!formDirty) {
      if (this.createChartModel.selectedMetrics.length == selectedMetrics.length) {
        for (let i = 0; i < selectedMetrics.length; i++) {
          if (selectedMetrics[i].id != this.createChartModel.selectedMetrics[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }

    if (!formDirty) {
      if (this.createChartModel.selectedPeerGroups.length == selectedPeerGroups.length) {
        for (let i = 0; i < selectedPeerGroups.length; i++) {
          if (selectedPeerGroups[i].id != this.createChartModel.selectedPeerGroups[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }

    if (!formDirty) {
      if (this.createChartModel.selectedPracticeGroups.length == selectedPractices.length) {
        for (let i = 0; i < selectedPractices.length; i++) {
          if (selectedPractices[i].id != this.createChartModel.selectedPracticeGroups[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }
    if (!formDirty) {
      if (selectedOffices.length == this.createChartModel.selectedOffices.length) {
        for (let i = 0; i < selectedOffices.length; i++) {
          if (selectedOffices[i].id != this.createChartModel.selectedOffices[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }

    if (!formDirty) {
      if (this.createChartModel.selectedTitles.length == selectedTitles.length) {
        for (let i = 0; i < selectedTitles.length; i++) {
          if (selectedTitles[i].id != this.createChartModel.selectedTitles[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }

    if (!formDirty) {
      if (this.createChartModel.selectedAssociateYears.length == selectedAssociateYears.length) {
        for (let i = 0; i < selectedAssociateYears.length; i++) {
          if (selectedAssociateYears[i].id != this.createChartModel.selectedAssociateYears[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }
    if (!formDirty) {
      if (this.createChartModel.selectedSrAssociateYears.length == selectedSrAssociateYears.length) {
        for (let i = 0; i < selectedSrAssociateYears.length; i++) {
          if (selectedSrAssociateYears[i].id != this.createChartModel.selectedSrAssociateYears[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }

    if (!formDirty) {
      if (this.createChartModel.selectedPartnerYears.length == selectedPartnerYears.length) {
        for (let i = 0; i < selectedPartnerYears.length; i++) {
          if (selectedPartnerYears[i].id != this.createChartModel.selectedPartnerYears[i].id) {
            formDirty = true;
            break;
          }
        }
      } else {
        formDirty = true;
      }
    }
    if (!formDirty) {
      if (selectedDateRange.length > 0) {
        if (selectedDateRange[0].id != this.createChartModel.selectedDateRangeType.id) {
          formDirty = true;
        }
      }
    }

    if (!formDirty) {
      const defaultStartDateArr = this.defaultCurrentStartDate.split('-');
      const currentStartDateArr = this.createChartModel.currentPeriodStartDate.toString().split('-');
      if (defaultStartDateArr[0] != currentStartDateArr[0] || defaultStartDateArr[1] != currentStartDateArr[1]) {
        formDirty = true;
      }
    }
    if (!formDirty) {
      const defaultEndDateArr = this.defaultCurrentEndDate.split('-');
      const currentEndDateArr = this.createChartModel.currentPeriodEndDate.toString().split('-');
      if (defaultEndDateArr[0] != currentEndDateArr[0] || defaultEndDateArr[1] != currentEndDateArr[1]) {
        formDirty = true;
      }
    }

    if (formDirty && (this.createChart.dirty || this.dateRangeComponent.dateRangeForm.dirty || this.openCancelPopup)) {
      let message =
        'There are unsaved changes.Click Continue to proceed and discard your changes, or Cancel to return to the previous page.';
      this.displayError(message);
      let modalCheck=document.querySelector('.modal-footer');
      modalCheck.classList.add('modal-footer-visualization');
    } else {
      if ((this.route.snapshot, this.route.snapshot.params, this.route.snapshot.params.id)) {
        this.router.navigate(['firm/' + this.getDefaultOrSelectedFirmId + '/visualizations']);
      } else {
        this.router.navigate(['visualizations']);
      }
    }
  }

  displayError(message, confirmMessage = 'OK', cancelMessage = 'CANCEL') {
    this.modalServiceConfirm
      .open(message, {
        titleText: 'Visualizations',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        closeButtonText: 'Close',
        buttonType: 'primary',
      })
      .result.then(
        (result: BentoModalConfirmationCloseReason) => {
          if (result == 'Cancel') {
            //this.router.navigate(['mappings/gl-account-codes/firms/' + this.getfirmId]);
            //this.modalServiceConfirm.;
          } else {
            if ((this.route.snapshot, this.route.snapshot.params, this.route.snapshot.params.id)) {
              this.router.navigate(['firm/' + this.getDefaultOrSelectedFirmId + '/visualizations']);
            } else {
              this.router.navigate(['visualizations']);
            }
          }
        },
        (reason) => {}
      );
  }

  getNewFilterVisualizationFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - FILTER_NEW_VISUALIZATION ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/FILTER_NEW_VISUALIZATION', '')
      .toPromise()
      .then((result) => {
        return result['FILTER_NEW_VISUALIZATION'];
      });
  }

  async getBasicDetails() {
    //Service will return getBasic details and firm level rules
    this.firmBasicData = await this._peercheckService.getFirmBasicsData(this.getDefaultOrSelectedFirmId);
    this.pcViewCols = this.firmBasicData.firmLevelRules;
    this.isGetBasicAPICalled = true;
    this.createChartModel.isCanadianFirm = this.firmBasicData.defaultAccess == 'CAN';
    // this.isDataLoading = false;
    await this.getTemplateDetails();
  }

  async getTemplateDetails() {
    // this.isDataLoading = true;
    this.isInputPanelDataLoading = true;
    let response = await this._peercheckService.getVisualizationDetailsWithType(
      this.isCopyEditRequest ? this.selectedVizData.chartId : this.selectedVizData.chartTemplateId,
      this.getDefaultOrSelectedFirmId,
      !this.isCopyEditRequest
    );
    this.isInputPanelDataLoading = false;
    // this.isDataLoading = false;
    if (response && response.response && !response.hasError) {
      this.vizTemplateDetail = response.response;
      this.defaultMetrics = JSON.stringify(response.response.metrics);
      this.defaultPeerGroup = JSON.stringify(response.response.peerGroups);
      this.defaultPractices = JSON.stringify(response.response.practices);
      this.defaultOffices = JSON.stringify(response.response.offices);
      this.defaultTitles = JSON.stringify(response.response.titles);
      this.defaultAssociateYears = JSON.stringify(response.response.associateYears);
      this.defaultSrAssociateYears = JSON.stringify(response.response.srAssociateYears);
      this.defaultPartnerYears = JSON.stringify(response.response.partnerYears);
      this.defaultRange = JSON.stringify(response.response.dateRange);
      this.defaultCurrentStartDate = response.response.currentPeriodStartDate;
      this.defaultCurrentEndDate = response.response.currentPeriodEndDate;
      this.createChartModel.templateId = this.vizTemplateDetail.chartTemplateId;
      this.createChartModel.favorite = this.selectedVizData.favorite;
      // populate selected data for chart
      this.populatePreSelectedValues();
    } else {
      this.alerts = response.alerts;
    }
  }

  populatePreSelectedValues() {
    this.vizTemplateDetail.metrics = JSON.parse(this.defaultMetrics);
    this.vizTemplateDetail.practices = JSON.parse(this.defaultPractices);
    this.vizTemplateDetail.peerGroups = JSON.parse(this.defaultPeerGroup);
    this.vizTemplateDetail.offices = JSON.parse(this.defaultOffices);
    this.vizTemplateDetail.titles = JSON.parse(this.defaultTitles);
    this.vizTemplateDetail.partnerYears = JSON.parse(this.defaultPartnerYears);
    this.vizTemplateDetail.associateYears = JSON.parse(this.defaultAssociateYears);
    this.vizTemplateDetail.srAssociateYears = JSON.parse(this.defaultSrAssociateYears);
    this._peercheckMsgHandlerService.populatePreSelectedVisualizationDetails(
      this.createChartModel,
      this.vizTemplateDetail,
      !this.isCopyEditRequest
    );
    this.dateRangeTypeStream = new BehaviorSubject(this.vizTemplateDetail.dateRange);
    this.peerGroupsStream = new BehaviorSubject(this.vizTemplateDetail.peerGroups.values);
    this.practiceGroupsStream = new BehaviorSubject(this.vizTemplateDetail.practices.values);
    this.metricsStream = new BehaviorSubject(this.vizTemplateDetail.metrics.values);
    this.officesStream = new BehaviorSubject(this.vizTemplateDetail.offices.values);
    this.titlesStream = new BehaviorSubject(this.vizTemplateDetail.titles.values);
    this.associateYearsStream = new BehaviorSubject(this.vizTemplateDetail.associateYears);
    this.srAssociateYearsStream = new BehaviorSubject(this.vizTemplateDetail.srAssociateYears);
    this.partnerYearsStream = new BehaviorSubject(this.vizTemplateDetail.partnerYears);
    this.isPartnerYear = false;
    this.isAssociateYear = false;
    this.isSrAssociateYear = false;
    this.createChartModel.selectedTitles.forEach((title) => {
      if (this.allowedPartnerYearTitles.indexOf(title.id) > -1) this.isPartnerYear = true;
      if (this.allowedAssociateYearTitles.indexOf(title.id) > -1) this.isAssociateYear = true;
      if (this.allowedSrAssociateYearTitles.indexOf(title.id) > -1) this.isSrAssociateYear = true;
    });
    if (!this.isPartnerYear) this.createChartModel.selectedPartnerYears = [];
    if (!this.isAssociateYear) this.createChartModel.selectedAssociateYears = [];
    if (!this.isSrAssociateYear) this.createChartModel.selectedSrAssociateYears = [];
    this.updateSelectedFieldText();
    this.updateSelectionFieldRules();
    this.defaultSelectionChartModel = JSON.parse(JSON.stringify(this.createChartModel));
    this.verifyReadyToSave(true, true);
  }

  updateSelectionFieldRules() {
    this.createChartSelectionRules.metrics = this.vizTemplateDetail.metrics;
    this.createChartSelectionRules.peerGroup = this.vizTemplateDetail.peerGroups;
    this.createChartSelectionRules.practiceGroup = this.vizTemplateDetail.practices;
    this.createChartSelectionRules.offices = this.vizTemplateDetail.offices;
    this.createChartSelectionRules.titles = this.vizTemplateDetail.titles;
    this.createChartSelectionRules.dateRange.displayOnUi = true;
    this.createChartSelectionRules.dateRange.maxCount = this.createChartSelectionRules.dateRange.minCount = 3;
  }

  updateUserChanges() {
    if (this.chartDetailsComponent) {
      this.chartDetailsComponent.updateChartData();
    }
  }

  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.isFirmSelected = false;
    if (this.route.snapshot && this.route.snapshot.params && this.route.snapshot.params.id) {
      this.isFirmSelected = true;
      return parseInt(this.route.snapshot.paramMap.get('id'));
    } else {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  saveChart() {
    let requestBody = this._peercheckMsgHandlerService.createRequestBodyForVisualization(
      this.createChartModel,
      this.vizTemplateDetail,
      this.firmBasicData,
      this.createChartModel.templateKey.toUpperCase(),
      'save'
    );

    const modalRef = this.modalService.open(SaveViewComponent, {ariaLabelledBy: 'modalSaveVisualization'});
    modalRef.componentInstance.settingFrom = 'Create new visualization';
    modalRef.componentInstance.visualizationOption = this.isCopyEditRequest
      ? VisualizationOptions.MyVisualizations
      : VisualizationOptions.VisualizationTemplates;
    modalRef.componentInstance.isCalledForVizOrView = true;
    modalRef.componentInstance.firmID = this.getDefaultOrSelectedFirmId;
    modalRef.componentInstance.visualizationRequestBody = requestBody;
    modalRef.componentInstance.visualizationModel = this.createChartModel;

    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.alerts.push({
            type: 'success',
            msg: 'Visualization saved successfully.',
            timeout: 2500,
            closeable: true,
          });
          this.isDataLoading = true;
          setTimeout(() => {
            if ((this.route.snapshot, this.route.snapshot.params, this.route.snapshot.params.id)) {
              this.router.navigate(['firm/' + this.getDefaultOrSelectedFirmId + '/visualizations']);
            } else {
              this.router.navigate(['visualizations']);
            }
          }, 500);
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  handleDateRangeType() {
    this.createChartModel.dateRange = new DateRange();
    this.createChartModel.dateRange.startDateRange = new FixedRange();
    this.createChartModel.dateRange.endDateRange = new FixedRange();
    this.createChartModel.customRange = new FixedTimeRange();
    this.verifyReadyToSave();
  }

  resetSelection() {
    this.populatePreSelectedValues();
  }

  peerGroupOnItemsSelected(v: any[]) {
    if (!v) {
      return;
    }
    let hasChanges = false;
    if (
      ((this.createChartSelectionRules.peerGroup.maxCount &&
        v.length <= this.createChartSelectionRules.peerGroup.maxCount) ||
        !this.createChartSelectionRules.peerGroup.maxCount) &&
      ((this.createChartSelectionRules.peerGroup.minCount &&
        v.length >= this.createChartSelectionRules.peerGroup.minCount) ||
        !this.createChartSelectionRules.peerGroup.minCount)
    ) {
      hasChanges = JSON.stringify(this.createChartModel.selectedPeerGroups) != JSON.stringify(v);
      this.createChartModel.selectedPeerGroups = v;
      this.updateSelectedFieldText();
    } else {
      this.createChartModel.selectedPeerGroups = [];
    }
    this.verifyReadyToSave(hasChanges);
  }

  practiceGroupOnItemsSelected(v: any[]) {
    if (!v) {
      return;
    }
    let hasChanges = false;
    if (
      this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.DPA ||
      (this.createChartSelectionRules.metrics.displayOnUi &&
        this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.KPMV1)
    ) {
      // if (
      //   v.length >= this.createChartSelectionRules.practiceGroup.minCount &&
      //   v.length <= this.createChartSelectionRules.practiceGroup.maxCount
      // ) {
      if (
        ((this.createChartSelectionRules.practiceGroup.maxCount &&
          v.length <= this.createChartSelectionRules.practiceGroup.maxCount) ||
          !this.createChartSelectionRules.practiceGroup.maxCount) 
      ) {
        hasChanges = JSON.stringify(this.createChartModel.selectedPracticeGroups) != JSON.stringify(v);
        this.createChartModel.selectedPracticeGroups = v;
        this.updateSelectedFieldText(); 
       } else {
        this.createChartModel.selectedPracticeGroups = [];
      }
    } else {
      hasChanges = JSON.stringify(this.createChartModel.selectedPracticeGroups) != JSON.stringify(v);
      this.createChartModel.selectedPracticeGroups = v;
      this.updateSelectedFieldText();
    }
    this.verifyReadyToSave(hasChanges);
  }

  metricsOnItemsSelected(v: any[]) {
    if (!v) {
      return;
    }
    for (let i = 0; i < v.length; i++) {
      v[i].selected = true;
    }
    let hasChanges = false;
    if (
      this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.DPA ||
      (this.createChartSelectionRules.metrics.displayOnUi &&
        this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.KPMV1)
    ) {
      if (
        ((this.createChartSelectionRules.metrics.maxCount &&
          v.length <= this.createChartSelectionRules.metrics.maxCount) ||
          !this.createChartSelectionRules.metrics.maxCount) &&
        ((this.createChartSelectionRules.metrics.minCount &&
          v.length >= this.createChartSelectionRules.metrics.minCount) ||
          !this.createChartSelectionRules.metrics.minCount)
      ) {
        hasChanges = JSON.stringify(this.createChartModel.selectedMetrics) != JSON.stringify(v);
        this.createChartModel.selectedMetrics = v;
        this.updateSelectedFieldText();
      } else {
        this.createChartModel.selectedMetrics = [];
      }
    } else {
      hasChanges = JSON.stringify(this.createChartModel.selectedMetrics) != JSON.stringify(v);
      this.createChartModel.selectedMetrics = v;
      this.updateSelectedFieldText();
    }
    this.verifyReadyToSave(hasChanges);
  }

  officeOnItemsSelected(v: any[]) {
    if (!v) {
      return;
    }
    let hasChanges = false;
    if (
      this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.DPA ||
      (this.createChartSelectionRules.metrics.displayOnUi &&
        this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.KPMV1)
    ) {
      if (
        ((this.createChartSelectionRules.offices.maxCount &&
          v.length <= this.createChartSelectionRules.offices.maxCount) ||
          !this.createChartSelectionRules.offices.maxCount) &&
        ((this.createChartSelectionRules.offices.minCount &&
          v.length >= this.createChartSelectionRules.offices.minCount) ||
          !this.createChartSelectionRules.offices.minCount)
      ) {
        hasChanges = JSON.stringify(this.createChartModel.selectedOffices) != JSON.stringify(v);
        this.createChartModel.selectedOffices = v;
        this.updateSelectedFieldText();
      } else {
        hasChanges = JSON.stringify(this.createChartModel.selectedAssociateYears) != JSON.stringify(v);
        this.createChartModel.selectedOffices = [];
      }
    } else {
      hasChanges = JSON.stringify(this.createChartModel.selectedOffices) != JSON.stringify(v);
      this.createChartModel.selectedOffices = v;
      this.updateSelectedFieldText();
    }
    this.verifyReadyToSave(hasChanges);
  }

  titleOnItemsSelected(v: any[]) {
    if (!v) {
      return;
    }
    let hasChanges = false;
    if (
      this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.DPA ||
      (this.createChartSelectionRules.metrics.displayOnUi &&
        this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.KPMV1)
    ) {
      if (
        ((this.createChartSelectionRules.titles.maxCount &&
          v.length <= this.createChartSelectionRules.titles.maxCount) ||
          !this.createChartSelectionRules.titles.maxCount) &&
        ((this.createChartSelectionRules.titles.minCount &&
          v.length >= this.createChartSelectionRules.titles.minCount) ||
          !this.createChartSelectionRules.titles.minCount)
      ) {
        hasChanges = JSON.stringify(this.createChartModel.selectedTitles) != JSON.stringify(v);
        this.createChartModel.selectedTitles = v;
        this.isPartnerYear = false;
        this.isAssociateYear = false;
        this.isSrAssociateYear = false;
        this.createChartModel.selectedTitles.forEach((title) => {
          if (this.allowedPartnerYearTitles.indexOf(title.id) > -1) this.isPartnerYear = true;
          if (this.allowedAssociateYearTitles.indexOf(title.id) > -1) this.isAssociateYear = true;
          if (this.allowedSrAssociateYearTitles.indexOf(title.id) > -1) this.isSrAssociateYear = true;
        });
        if (!this.isPartnerYear) this.createChartModel.selectedPartnerYears = [];
        if (!this.isAssociateYear) this.createChartModel.selectedAssociateYears = [];
        if (!this.isSrAssociateYear) this.createChartModel.selectedSrAssociateYears = [];
      } else {
        hasChanges = JSON.stringify(this.createChartModel.selectedTitles) != JSON.stringify(v);
        this.isPartnerYear = false;
        this.createChartModel.selectedPartnerYears = [];
        this.isAssociateYear = false;
        this.createChartModel.selectedAssociateYears = [];
        this.isSrAssociateYear = false;
        this.createChartModel.selectedSrAssociateYears = [];
        this.createChartModel.selectedTitles = [];
      }
    } else {
      hasChanges = JSON.stringify(this.createChartModel.selectedTitles) != JSON.stringify(v);
      this.createChartModel.selectedTitles = v;
      this.isPartnerYear = false;
      this.isAssociateYear = false;
      this.isSrAssociateYear = false;
      this.createChartModel.selectedTitles.forEach((title) => {
        if (this.allowedPartnerYearTitles.indexOf(title.id) > -1) this.isPartnerYear = true;
        if (this.allowedAssociateYearTitles.indexOf(title.id) > -1) this.isAssociateYear = true;
        if (this.allowedSrAssociateYearTitles.indexOf(title.id) > -1) this.isSrAssociateYear = true;
      });
      if (!this.isPartnerYear) this.createChartModel.selectedPartnerYears = [];
      if (!this.isAssociateYear) this.createChartModel.selectedAssociateYears = [];
      if (!this.isSrAssociateYear) this.createChartModel.selectedSrAssociateYears = [];
    }
    this.updateSelectedFieldText();
    this.verifyReadyToSave(hasChanges);
  }

  associateYearOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'associateYearOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createChartModel.selectedAssociateYears) != JSON.stringify(v);
      this.createChartModel.selectedAssociateYears = v;
      this.updateSelectedFieldText();
    } else {
      hasChanges = JSON.stringify(this.createChartModel.selectedAssociateYears) != JSON.stringify(v);
      this.createChartModel.selectedAssociateYears = [];
    }
    this.verifyReadyToSave(hasChanges);
  }

  srAssociateYearOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'srAssociateYearOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createChartModel.selectedSrAssociateYears) != JSON.stringify(v);
      this.createChartModel.selectedSrAssociateYears = v;
      this.updateSelectedFieldText();
    } else {
      hasChanges = JSON.stringify(this.createChartModel.selectedSrAssociateYears) != JSON.stringify(v);
      this.createChartModel.selectedSrAssociateYears = [];
    }
    this.verifyReadyToSave(hasChanges);
  }

  partnerYearOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'partnerYearOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createChartModel.selectedPartnerYears) != JSON.stringify(v);
      this.createChartModel.selectedPartnerYears = v;
    } else {
      hasChanges = JSON.stringify(this.createChartModel.selectedPartnerYears) != JSON.stringify(v);
      this.createChartModel.selectedPartnerYears = [];
    }
    this.updateSelectedFieldText();
    this.verifyReadyToSave(hasChanges);
  }

  verifyReadyToSave(hasChanges: boolean = true, skipNameValidation: boolean = false) {
    this.isReadyToSave = true;

    if (
      this.createChartModel.selectedDateRangeType &&
      this.createChartModel.selectedDateRangeType.id == 2 &&
      !(
        this.createChartModel.customRange.startPeriod1 &&
        this.createChartModel.customRange.startPeriod2 &&
        this.createChartModel.customRange.endPeriod1 &&
        this.createChartModel.customRange.endPeriod2
      )
    ) {
      this.isReadyToSave = false;
    }

    if (
      this.createChartModel.selectedDateRangeType &&
      this.createChartModel.selectedDateRangeType.id == 1 &&
      !(
        this.createChartModel.dateRange.startDateRange.years &&
        this.createChartModel.dateRange.startDateRange.years.value &&
        this.createChartModel.dateRange.startDateRange.month &&
        this.createChartModel.dateRange.startDateRange.month.value
      )
    ) {
      this.isReadyToSave = false;
    }

    if (
      !(
        this.createChartModel.selectedPeerGroups &&
        this.createChartModel.selectedPeerGroups.length >= this.createChartSelectionRules.peerGroup.minCount &&
        this.createChartModel.selectedPeerGroups.length <= this.createChartSelectionRules.peerGroup.maxCount
      )
    ) {
      this.isReadyToSave = false;
    }

    if (
      this.createChartSelectionRules.metrics.displayOnUi &&
      this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.KPMV1 &&
      !(
        this.createChartModel.selectedMetrics &&
        this.createChartModel.selectedMetrics.length >= this.createChartSelectionRules.metrics.minCount &&
        this.createChartModel.selectedMetrics.length <= this.createChartSelectionRules.metrics.maxCount
      )
    ) {
      this.isReadyToSave = false;
    }

    if (
      this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.DPA &&
      !(
        this.createChartModel.selectedPracticeGroups &&
        this.createChartModel.selectedPracticeGroups.length >= this.createChartSelectionRules.practiceGroup.minCount &&
        this.createChartModel.selectedPracticeGroups.length <= this.createChartSelectionRules.practiceGroup.maxCount
      )
    ) {
      this.isReadyToSave = false;
    }

    // if(skipNameValidation && JSON.stringify(this.defaultSelectionChartModel.viewsSettings)
    // == JSON.stringify(this.createChartModel.viewsSettings)) {
    //   this.isReadyToSave = false;
    // }

    this.disableDropdowns = !this.isReadyToSave;
    if (this.isReadyToSave && hasChanges) {
      this.updateTimeRange();
      this.updateUserChanges();
    }
  }

  onSettingsChanged() {
    this.openCancelPopup = true;
  }

  updateTimeRange() {
    // Updating the selected text for Time range.
    if (this.createChartModel.selectedDateRangeType.id == 1) {
      let month = this.createChartModel.dateRange.startDateRange.month.value;
      let year = this.createChartModel.dateRange.startDateRange.years.value;

      let currentStartDate = new Date(year, month - 1, 1);
      let currentEndDate = new Date(year, month, 0);

      let priorStartDate = new Date(year - 1, month - 1, 1);
      let priorEndDate = new Date(year - 1, month, 0);

      this.createChartModel.currentPeriodStartDate =
        currentStartDate.getFullYear() +
        '-' +
        ('0' + (currentStartDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + currentStartDate.getDate()).toString().slice(-2);
      this.createChartModel.currentPeriodEndDate =
        currentEndDate.getFullYear() +
        '-' +
        ('0' + (currentEndDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + currentEndDate.getDate()).toString().slice(-2);
      this.createChartModel.priorPeriodStartDate =
        priorStartDate.getFullYear() +
        '-' +
        ('0' + (priorStartDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + priorStartDate.getDate()).toString().slice(-2);
      this.createChartModel.priorPeriodEndDate =
        priorEndDate.getFullYear() +
        '-' +
        ('0' + (priorEndDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + priorEndDate.getDate()).toString().slice(-2);
    } else if (this.createChartModel.selectedDateRangeType.id == 2) {
      let startDateArray = this.createChartModel.customRange.startPeriod1
        .toString()
        .replace(/(.{4})/g, '$1-')
        .split('-');
      let endDateArray = this.createChartModel.customRange.endPeriod1
        .toString()
        .replace(/(.{4})/g, '$1-')
        .split('-');

      let currentStartDate = new Date(parseInt(startDateArray[0]), parseInt(startDateArray[1]) - 1, 1);
      let currentEndDate = new Date(parseInt(endDateArray[0]), parseInt(endDateArray[1]), 0);

      let priorStartDateArray = this.createChartModel.customRange.startPeriod2
        .toString()
        .replace(/(.{4})/g, '$1-')
        .split('-');
      let priorEndDateArray = this.createChartModel.customRange.endPeriod2
        .toString()
        .replace(/(.{4})/g, '$1-')
        .split('-');

      let priorStartDate = new Date(parseInt(priorStartDateArray[0]), parseInt(priorStartDateArray[1]) - 1, 1);
      let priorEndDate = new Date(parseInt(priorEndDateArray[0]), parseInt(priorEndDateArray[1]), 0);

      this.createChartModel.currentPeriodStartDate =
        currentStartDate.getFullYear() +
        '-' +
        ('0' + (currentStartDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + currentStartDate.getDate()).toString().slice(-2);
      this.createChartModel.currentPeriodEndDate =
        currentEndDate.getFullYear() +
        '-' +
        ('0' + (currentEndDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + currentEndDate.getDate()).toString().slice(-2);
      this.createChartModel.priorPeriodStartDate =
        priorStartDate.getFullYear() +
        '-' +
        ('0' + (priorStartDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + priorStartDate.getDate()).toString().slice(-2);
      this.createChartModel.priorPeriodEndDate =
        priorEndDate.getFullYear() +
        '-' +
        ('0' + (priorEndDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + priorEndDate.getDate()).toString().slice(-2);
    }
  }

  updateSelectedFieldText() {
    // Updating the text for peerGroups, practice group and etc..
    if (this.createChartModel.selectedPeerGroups && this.createChartModel.selectedPeerGroups.length)
      this.createChartModel.selectedPeerGroupsText = this.createChartModel.selectedPeerGroups.length + ' Selected';
    else this.createChartModel.selectedPeerGroupsText = 'Select';

    if (this.createChartModel.selectedPracticeGroups && this.createChartModel.selectedPracticeGroups.length)
      this.createChartModel.selectedPracticeGroupsText =
        this.createChartModel.selectedPracticeGroups.length + ' Selected';
    else this.createChartModel.selectedPracticeGroupsText = 'Select';

    if (this.createChartModel.selectedOffices && this.createChartModel.selectedOffices.length)
      this.createChartModel.selectedOfficesText = this.createChartModel.selectedOffices.length + ' Selected';
    else this.createChartModel.selectedOfficesText = 'Select';

    if (this.createChartModel.selectedTitles && this.createChartModel.selectedTitles.length)
      this.createChartModel.selectedTitlesText = this.createChartModel.selectedTitles.length + ' Selected';
    else this.createChartModel.selectedTitlesText = 'Select';

    if (this.createChartModel.selectedAssociateYears && this.createChartModel.selectedAssociateYears.length)
      this.createChartModel.selectedAssociateYearsText =
        this.createChartModel.selectedAssociateYears.length + ' Selected';
    else this.createChartModel.selectedAssociateYearsText = 'Select';

    if (this.createChartModel.selectedSrAssociateYears && this.createChartModel.selectedSrAssociateYears.length)
      this.createChartModel.selectedSrAssociateYearsText =
        this.createChartModel.selectedSrAssociateYears.length + ' Selected';
    else this.createChartModel.selectedSrAssociateYearsText = 'Select';

    if (this.createChartModel.selectedPartnerYears && this.createChartModel.selectedPartnerYears.length)
      this.createChartModel.selectedPartnerYearsText = this.createChartModel.selectedPartnerYears.length + ' Selected';
    else this.createChartModel.selectedPartnerYearsText = 'Select';

    if (this.createChartModel.selectedMetrics && this.createChartModel.selectedMetrics.length)
      this.createChartModel.selectedMetricsText = this.createChartModel.selectedMetrics.length + ' Selected';
    else this.createChartModel.selectedMetricsText = 'Select';
  }

  componentRendered(chartDetails) {
    this.chartDetailsComponent = chartDetails;
  }

  onClickFilter(newValue: boolean) {
    if (this.isFilterPanelOpen === newValue) {
      this.isFilterPanelOpen = false;
      setTimeout(function () {
        $('.FilterPanel-body').hide();
      }, 500);
    } else {
      this.isFilterPanelOpen = newValue;
      $('.FilterPanel-body').show();
    }
  }

  handleDataLoadingStateChanged(dataLoading) {
    this.isDataLoading = dataLoading;
  }

  logQuickSightEvent(selectedVisualization) {
    if (!this.isCopyEditRequest) {
      let metadata = {
        templateKey: selectedVisualization.templateKey,
        startDate: selectedVisualization.currentPeriodStartDate,
        endDate: selectedVisualization.currentPeriodEndDate,
        email: this.authService.getUserEmail(),
      };
      TRAAC.track(TRAAC.keyValue('New Visualization click', metadata).build());
    } else {
      let metadata = {
        visualizationName: selectedVisualization.chartName,
        startDate: selectedVisualization.currentPeriodStartDate,
        endDate: selectedVisualization.currentPeriodEndDate,
        templateKey: selectedVisualization.templateKey,
        email: this.authService.getUserEmail(),
      };
      TRAAC.track(TRAAC.keyValue('Edit/Copy Visualization click', metadata).build());
    }
  }

   isValidMultiselect(evt: BfmFieldEvent): void {
    if (!evt) {
      return;
    }
    const setValidity = evt.setValidity;
	if(this.createChartModel.selectedPracticeGroups.length >= this.createChartSelectionRules.practiceGroup.minCount){
		setValidity({valid: true});
		}
	else
	{
		setTimeout(() => {
			  return setValidity({valid: false, message: 'Error: minimum of two practice groups is required'});
			}, 0);
	}
}

}
