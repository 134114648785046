import {
  Component,
  OnInit,
  HostBinding,
  EventEmitter,
  ViewChild,
  Injectable,
  Inject,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {Role} from 'src/app/shared/enums';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService} from '../../../services/auth/auth.service';
import {environment} from 'src/environments/environment';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import MappingPracticeModel from 'src/app/core/models/mappingPractice.model';
import KeyValueModel from 'src/app/core/models/keyValue.model';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import MappingPostModel from 'src/app/core/models/mappingPost.model';
import {DownloadService} from 'src/app/core/services/download/download.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BulkMappingComponent} from '../shared/bulk-mapping/bulk-mapping.component';
import {BulkMapping} from 'src/app/core/models/bulkMapping.model';
import {PeerCheckServiceService} from '../../peer-check/peer-check/peer-check-service.service';

@Component({
  selector: 'app-practices',
  templateUrl: './practices.component.html',
})
export class PracticesComponent implements OnInit {
  successMsg = '';
  errorMessage = '';
  isListGrid = false;
  firmId: number;
  alerts: BentoAlertItemOptions[] = [];
  isGetDataLoading = false;
  practicesDataGrid: any;
  firmLocale: any;
  mappingPracticeModel: MappingPracticeModel = new MappingPracticeModel();
  keyValue: KeyValueModel = new KeyValueModel();
  // reference to FlexGrid Data Collection View
  data: wjcCore.CollectionView;
  // ComboBox Data Emitter (Used in products column cell editor)
  dataEmitter: EventEmitter<any> = new EventEmitter();
  resetPracticesGrid = [];
  mappingPostModel: MappingPostModel[] = [];
  practices: string[];
  bulkMappingModel: [BulkMapping];
  bulkMappingselect = {
    title: null,
  };
  selectedRecord = [];
  isDisabled = true;
  actionDropdownItems: {name: string; isDisabled: boolean}[];

  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  // Get reference to gridFilter via ViewChild (see #filter in flexgrid.html)
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  // Get reference to FlexGrid control (see #flexGrid in flexgrid.html)
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;

  pmDataKeyValue: any = {};
  productMap: any;

  /**
   * Store Grid's column definition in array
   */
  columnDefinitions: any[] = [
    {
      binding: 'selected',
      header: '',
      filterType: 0,
      minWidth: 40,
      width: 40,
      cssClass: 'col-selected',
      allowSorting: false,
      allowDragging: false,
      isReadOnly: true,
    },
    {
      binding: 'firmCode',
      header: 'Code',
      filterType: 0,
      visible: true,
      minWidth: 200,
      width: '*',
      isReadOnly: true,
    },
    {
      binding: 'firmDescription',
      header: 'Name',
      filterType: 3,
      visible: true,
      minWidth: 300,
      width: '2*',
      isReadOnly: true,
    },
    {
      binding: 'pmCode',
      header: 'Mapped practice name',
      filterType: 3,
      visible: true,
      minWidth: 200,
      width: '*',
    },
  ];

  /**
   * convert columnDefinition array to object with binding as key
   * for use in template
   */
  columnDefinitionsByBinding: any = this.columnDefinitions.reduce((result, item) => {
    result[item.binding] = item;
    return result;
  }, {});

  /**
   * COMBOBOX CELL EDITOR SETUP
   */
  comboboxOptions = {
    searchable: true,
  };

  /**
   * Toolbar configruation data
   */
  toolbarConfig: any = {
    filterButtonHidden: false,
    filtersHidden: true,
    groupButtonHidden: false,
    groupPanelHidden: false,
  };

  /**
   * MULTI-SELECT
   */
  // Multi-Select handlers and constants
  CHECKBOX_SELECTED = 1;
  CHECKBOX_UNSELECTED = 2;
  headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
  CHECKBOX_INDETERMINATE = 3;

  // assign column binding for multi-select to variable,
  selectColumnAllowSorting = false;
  selectBinding = 'selected';

  // Pagination Setup data
  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };

  constructor(
    private route: ActivatedRoute,
    private service: BaseService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private downloadFile: DownloadService,
    public modalService: NgbModal,
    private _peerCheckService: PeerCheckServiceService
  ) {
    this.data = new wjcCore.CollectionView(this.mappingPracticeModel.practices);
    this.data.pageSize = this.pageInfo.pageSize;
  }

  ngOnInit(): void {
    this.actionDropdownItems = [
      {name: 'Export all', isDisabled: false},
      {name: 'Clear filters', isDisabled: false},
      {name: 'Bulk mapping', isDisabled: false},
    ];
    this.firmId = this.getfirmId;
    this._peerCheckService.setBalancingValue({selected: 'Default', value: ''});
    this.route.params.subscribe((val) => {
      if (val && val.id) {
        this.firmId = val.id;
      } else {
        if (this._peerCheckService.getSelectedFirmId && this._peerCheckService.getSelectedFirmId != this.getfirmId) {
          this.firmId = this._peerCheckService.getSelectedFirmId;
        } else {
          this.firmId = this.getfirmId;
        }
      }
      if (this._peerCheckService.isValidMappingFirm(this.firmId)) {
        this.getMappingPracticeData(this.firmId);
      }
    });
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  getMappingPracticeData(firmId) {
    this.isGetDataLoading = true;
    this.practicesDataGrid = [];
    this.selectedRecord = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/practice/', firmId).subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.practicesDataGrid = result;
        this.updateActionDropdownItems();
        this.getMappingPracticeFieldData();
      },

      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getMappingPracticeFieldData() {
    this.isGetDataLoading = true;
    this.firmLocale = this.localStorageService.get('firmLocale');
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/practice/pm/', this.firmLocale).subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.bulkMappingModel = result;
        for (let obj of result) {
          this.pmDataKeyValue[obj['code']] = obj['description'];
        }
        this.practicesDataGrid.forEach((item) => (item.pmCode = this.pmDataKeyValue[item.pmCode]));
        this.resetPracticesGrid = JSON.parse(JSON.stringify(this.practicesDataGrid));
        // assign data to collection view
        this.data = new wjcCore.CollectionView(this.practicesDataGrid);
        //    this.data.pageSize = this.pageInfo.pageSize;
        this.practices = Object.values(this.pmDataKeyValue);
        this.dataEmitter.emit(Object.values(this.pmDataKeyValue));
      },

      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  //Handler for FlexGrid's Initialized method
  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.flexGrid = flexGrid;
  }

  // Emit data into Combobox when it is ready
  onComboboxReady() {
    this.dataEmitter.emit(this.practices);
  }

  // Pagination Size Changed Event Handler
  onPageSizeChanged(size) {
    this.data.pageSize = size;
    this.onCheckBoxChange();
  }

  // Pagination Page Changed Event Handler
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
    this.onCheckBoxChange();
  }

  // Event Handler for Multi-Select Column Header Checkbox
  onHeaderCheckBoxChange() {
    let selected = false;
    if (this.headerCheckBoxMode !== this.CHECKBOX_SELECTED) {
      this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      selected = true;
    } else {
      this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
      selected = false;
    }
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        this.flexGrid.rows[i].dataItem[this.selectBinding] = selected;
        this.flexGrid.rows[i].cssClass = selected ? 'row-selected' : '';
        selected
          ? this.saveSelection(this.flexGrid.rows[i].dataItem)
          : this.removeSelection(this.flexGrid.rows[i].dataItem);
      }
    }
    this.updateActionDropdownItems();
  }

  // Event Handler for Multi-Select Column Cell Checkbox
  onCheckBoxChange() {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        if (this.flexGrid.rows[i].dataItem[this.selectBinding] === true) {
          count++;
          this.flexGrid.rows[i].cssClass = 'row-selected';
          this.saveSelection(this.flexGrid.rows[i].dataItem);
        } else {
          this.removeSelection(this.flexGrid.rows[i].dataItem);
          this.flexGrid.rows[i].cssClass = '';
        }
      }
      if (count === this.flexGrid.rows.length) {
        this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      } else if (count > 0) {
        this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
      }
    }
    this.updateActionDropdownItems();
  }

  isAllSelected() {
    return this.headerCheckBoxMode === this.CHECKBOX_SELECTED;
  }
  isIndeterminate() {
    return this.headerCheckBoxMode === this.CHECKBOX_INDETERMINATE;
  }
  exportAll(firmId) {
    this.service.downloadExcel(environment.FIClientBaseEndpoint + 'v1/mapping/practice/report/' + firmId).subscribe(
      (response) => {
        this.downloadFile.download(response.body, response.headers.get('content-disposition').split('=')[1]);
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  dropDownOnSelectChange(name) {
    if (name == 'Export all') {
      this.exportAll(this.firmId);
    } else if (name == 'Clear filters') {
      this.clearFilters();
    } else if (name == 'Bulk mapping') {
      const modalRef = this.modalService.open(BulkMappingComponent);
      modalRef.componentInstance.messageFrom = 'Practices';
      modalRef.componentInstance.bulkMappingData = this.bulkMappingModel;
      modalRef.componentInstance.selectedRecord = this.selectedRecord;
      modalRef.componentInstance.firmId = this.firmId;

      modalRef.result.then(
        (result) => {
          if (result == 'Success') {
            this.getMappingPracticeData(this.firmId);
            this.alerts.push({
              type: 'success',
              msg: 'Data saved Successfully.',
              timeout: 2500,
              closeable: true,
            });

            this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
          } else {
            this.alerts.push({
              type: 'warning',
              msg: result,
              closeable: true,
            });
          }
        },
        (reason) => {}
      );
    }
  }
  clearFilters() {
    this.flexGrid.collectionView.sortDescriptions.clear();
    this.gridFilter.clear();
  }

  savePractices() {
    this.isGetDataLoading = true;
    this.practicesDataGrid.forEach((element, index) => {
      if (element.pmCode != this.resetPracticesGrid[index].pmCode) {
        let objTitlesModel = new MappingPostModel();
        objTitlesModel.firmCode = element.firmCode;
        // eslint-disable-next-line max-len
        objTitlesModel.pmCode = parseInt(
          Object.keys(this.pmDataKeyValue).find((key) => this.pmDataKeyValue[key] === element.pmCode)
        );
        this.mappingPostModel.push(objTitlesModel);
      }
    });
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/mapping/practice/' + this.firmId, this.mappingPostModel)
      .subscribe(
        (response) => {
          this.isGetDataLoading = false;
          this.resetPracticesGrid = JSON.parse(JSON.stringify(this.practicesDataGrid));
          this.successMsg = 'Data modified successfully.';
          this.mappingPostModel = [];
          this.isDisabled = true;
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.isGetDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
  resetPractices() {
    this.isGetDataLoading = true;
    this.practicesDataGrid = JSON.parse(JSON.stringify(this.resetPracticesGrid));
    this.data = new wjcCore.CollectionView(this.practicesDataGrid);
    //  this.data.pageSize = this.pageInfo.pageSize;
    this.isDisabled = true;
    window.scroll(0, 0);
    this.isGetDataLoading = false;
    this.successMsg = 'Data reset successfully.';
    this.alerts.push({
      type: 'success',
      msg: this.successMsg,
      timeout: 2500,
      closeable: true,
    });
  }
  saveSelection(selectedOption) {
    const index = this.selectedRecord.indexOf(selectedOption);
    if (index != -1) {
      this.selectedRecord.splice(index, 1, selectedOption);
    } else {
      this.selectedRecord.push(selectedOption);
    }
  }

  removeSelection(selectedOption) {
    const index = this.selectedRecord.indexOf(selectedOption);
    if (index != -1) {
      this.selectedRecord.splice(index, 1);
    }
  }
  onChangeDropdownValue(e, cell) {
    const index = this.practicesDataGrid.findIndex((item) => item.firmCode == cell.item.firmCode);
    //var index = cell.row._idx + (this.data.pageIndex + 1) * this.data.pageSize - this.data.pageSize;
    this.practicesDataGrid[index].pmCode = e;
    if (JSON.stringify(this.practicesDataGrid) !== JSON.stringify(this.resetPracticesGrid)) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  initFilter() {
    this.gridFilter.showSortButtons = false;
  }

  updateActionDropdownItems() {
    this.actionDropdownItems.forEach((element) => {
      if (element.name == 'Bulk mapping' && this.selectedRecord.length <= 0) {
        element.isDisabled = true;
      } else if (element.name == 'Export all' && this.practicesDataGrid.length <= 0) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
    });
  }
}
