import {Injectable} from '@angular/core';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ArrowNavigationService {
  private preventTabDefaultBehaviour = true;
  handleKeyDown(event: KeyboardEvent): void {
    const currentTab = document.activeElement as HTMLElement;
    const tabItems = Array.from(document.querySelectorAll('ul[ngbNav] a[ngbNavLink]'));
   
    // Check if the current element is a tab item or a link within tab content
    if (!tabItems.includes(currentTab) && !currentTab.closest('.tab-content')) {
      return; // Exit if false
    }

    const currentIndex = tabItems.indexOf(currentTab);
    let newTabIndex;

    switch (event.key) {
      case 'ArrowRight':
        event.preventDefault();
        newTabIndex = (currentIndex + 1) % tabItems.length; // Move to next tab
        break;

      case 'ArrowLeft':
        event.preventDefault();
        newTabIndex = (currentIndex - 1 + tabItems.length) % tabItems.length; // Move to previous tab
        break;

      case 'Home':
        event.preventDefault();
        newTabIndex = 0; // Focus the first tab
        break;

      case 'End':
        event.preventDefault();
        newTabIndex = tabItems.length - 1; // Focus the last tab
        break;

      case 'Tab':
        if (event.shiftKey) {
          // Handle Shift + Tab
          event.preventDefault();
          // Check if the current element is one of the tab items
          if (tabItems.includes(currentTab)) {
            // Find the last item on the global sub-navigation
            let lastGlobalSubnavItem=null;
            const globalSubnavItems = document.querySelectorAll('.global-subnav a');
            const pivotSubnavItems = document.querySelectorAll('.PivotTable div.bento-select select, #metricActionsButton-details');
           if(pivotSubnavItems){
            lastGlobalSubnavItem = pivotSubnavItems[pivotSubnavItems.length - 1] as HTMLElement;
           }
           else{
              lastGlobalSubnavItem = globalSubnavItems[globalSubnavItems.length - 1] as HTMLElement;
           }
            if (lastGlobalSubnavItem) {
              lastGlobalSubnavItem.focus(); // Focus the last global subnav item
            }
          } else {
            this.focusFirstContentElement(); // Move focus to the first focusable element in the active tab's content
          }
        } else {
          if(this.preventTabDefaultBehaviour){
            event.preventDefault();
          }    
          this.focusFirstContentElement(); // Move focus to the first focusable element in the active tab's content
        }
        break;
    }

    // Set focus on the new tab's a element
    if (newTabIndex !== undefined) {
      (tabItems[newTabIndex] as HTMLElement).focus();
    }
  }

  // Function to focus the first content interactive element
  private focusFirstContentElement(): void {
    let activeTabContent = document.querySelector('.tab-content') as HTMLElement;
    if (activeTabContent) {
      // Select all focusable elements inside .tab-content
      let focusableElements = activeTabContent.querySelectorAll('a, button, input, select, [tabindex="0"]');
      let noFocusableElements = true;
      for (let i = 0; i < focusableElements.length; i++) {
        let element = focusableElements[i] as HTMLElement;
        // Check if the element is disabled (either via 'disabled' attribute or 'tabindex' -1)
        const isDisabled = element.hasAttribute('disabled') || element.getAttribute('tabindex') === '-1';
        if (!isDisabled) {
          // Focus on the first non-disabled element
          noFocusableElements = false;       
          element.focus();
          break;
        }
          
      } 
      if(noFocusableElements){
        this.preventTabDefaultBehaviour=false; 
      }
      else{
        this.preventTabDefaultBehaviour=true;
      }    
    }
  }
}
