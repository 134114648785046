<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <h1 class="Main-heading">G/L Extract Inquiry</h1>
        <app-mapping-firms-details></app-mapping-firms-details>
      </div>
      <div class="col">
        <app-action-dropdown
          #actionButton
          [actionDropdownItems]="actionDropdownItems"
          (dropDownSelectChange)="dropDownOnSelectChange($event)"
          [disable]="true"
        >
        </app-action-dropdown>
      </div>
    </div>
  </header>
  <div class="Main-body FilterPanel FilterPanel--toggle FilterPanel--toggle--Modal">
    <aside class="FilterPanel-control FilterPanel-control-scroll FilterPanel-control--Modal" [ngClass]="{'is-open': isFilterOpen == true}">
      <form class="FilterPanel-control--Timekeeper" name="timekeeperFilter" #timekeeperFilter="ngForm" bfmForm>
        <header class="FilterPanel-header">
          <h2 class="h3 FilterPanel-heading">Filter</h2>
          <button
            type="button"
            class="btn btn-icon close"
            name="close"
            id="close"
            [attr.aria-label]="isFilterOpen ? 'Close filter panel' : 'Open filter panel'"
            [ngbTooltip]="isFilterOpen ? 'Close filter panel' : 'Open filter panel'"
            placement="right"
            (click)="onClickFilter(true)"
          >
            <i [ngClass]="'bento-icon-close-x'" aria-hidden="true"></i>
          </button>
        </header>

        <div class="FilterPanel-body" id="FilterPanel-body-scroll-gl" [bentoBusyLoader]="isMainBusyLoaderBusy">
          <ul class="FilterPanel-list">
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPeriodVisible"
                  id="isPeriodVisible"
                  name="isPeriodVisible"
                  (ngModelChange)="onPeriodChange()"
                >
                </bento-checkbox>
              </div>
              <div class="FilterPanel-input">
                <app-fiscal-period
                  #fiscalPeriodComponent
                  [fiscalPeriodModel]="fiscalPeriodModel"
                  [fiscalDateRange]="fiscalDateRange"
                  (selectionChanged)="onFiscalPeriodChange($event)"
                  (fiscalPeriodLoaded)="onFiscalPeriodChange($event, true)"
                  (bentoAlertOccured)="onBentoAlertOccured($event)"
                  [requestFrom]="'glExtract'"
                ></app-fiscal-period>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPmAccountVisible"
                  id="isPmAccountVisible"
                  name="isPmAccountVisible"
                  (ngModelChange)="onPmAccountChange()"
                >
                </bento-checkbox>
              </div>
              <div class="FilterPanel-input">
                <label for="pmAccount">PM ACCOUNT</label>
                <!-- <bento-multiselect-overlay
                  name="pmAccount"
                  id="pmAccount"
                  class="FormMultiselect"
                  [(ngModel)]="tkHistoryFilterData.pmAccount"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPmAccountText"
                  [itemsObservable]="tkFilterData.pmAccountStream"
                  [listItemTemplate]="customListPmAccountListItemTemplate"
                  [searchCompare]="searchCompare"
                  (ngModelChange)="onPmAccountSelected($event)"
                  ngDefaultControl
                >
                </bento-multiselect-overlay> -->
                <select
                  name="pmAccount"
                  [(ngModel)]="tkHistoryFilterData.pmAccount"
                  (ngModelChange)="onPmAccountSelected($event)"
                  id="pmAccount"
                  bentoSelect
                >
                  <option *ngFor="let glAccount of glAccounts">{{ glAccount }}</option>
                </select>
                <!-- Custom list item definition -->
              </div>
            </li>

            <!-- <li class="FilterPanel-listItem">
              <div class="FilterPanel-display-checkbox">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPmAcctVisible"
                  id="isPmAcctVisible"
                  name="isPmAcctVisible"
                >
                </bento-checkbox>

                <label for="isPmAcctVisible" class="sr-only">Display pm acct column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="isPmAcctVisible">PM ACCT</label>
              </div>
            </li> -->

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display-checkbox">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isFirmActVisible"
                  (change)="firmActVisibleChange()"
                  id="isFirmActVisible"
                  name="isFirmActVisible"
                  (ngModelChange)="onFirmAcctChange()"
                >
                </bento-checkbox>

                <label for="isFirmActVisible" class="sr-only">Display firm act column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="isFirmActVisible">Firm acct</label>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display-checkbox">
                <bento-checkbox
                  ngDefaultControl
                  [disabled]="!tkFilterData.isFirmActVisible || !tkFilterData.isDeptVisible"
                  [(ngModel)]="tkFilterData.isOverRideVisible"
                  id="isOverRideVisible"
                  name="isOverRideVisible"
                  (ngModelChange)="onOverrideChange()"
                >
                </bento-checkbox>

                <label for="isOverRideVisible" class="sr-only">Display Override column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="isOverRideVisible">Override</label>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display-checkbox">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isOfficeVisible"
                  id="isOfficeVisible"
                  name="isOfficeVisible"
                  (ngModelChange)="onOfficeChange()"
                >
                </bento-checkbox>

                <label for="isOfficeVisible" class="sr-only">Display Override column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="isOfficeVisible">Office</label>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display-checkbox">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isDeptVisible"
                  id="isDeptVisible"
                  name="isDeptVisible"
                  (change)="OnDeptVisibleChange()"
                  (ngModelChange)="onDeptChange()"
                >
                </bento-checkbox>

                <label for="isDeptVisible" class="sr-only">Display Dept column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="isDeptVisible">Department</label>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display-checkbox">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isReallocVisible"
                  id="isReallocVisible"
                  name="isReallocVisible"
                  (ngModelChange)="onReallocChange()"
                >
                </bento-checkbox>

                <label for="isReallocVisible" class="sr-only">Display Realloc column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="isReallocVisible">Reallocation ID</label>
              </div>
            </li>
          </ul>
        </div>
        <footer class="FilterPanel-footer--Modal">
          <button type="button" role="button" (click)="resetTKFilter(true)" class="btn btn-outline-secondary">
            <span class="btn-text">Clear</span></button
          ><button
            type="submit"
            role="button"
            [disabled]="isFilterButtonDisabled"
            (click)="getGlExtractData(true)"
            class="btn btn-primary filter-popup"
          >
            <span class="btn-text">Filter</span>
          </button>
        </footer>
        <!-- <div class="form-group Form-group footer">
          <div class="bento-form-buttons divClass">
            <button type="button" class="btn btn-outline-secondary" (click)="resetTKFilter(true)">
              <span class="btn-text">Clear</span>
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="isFilterButtonDisabled"
              (click)="getGlExtractData(true)"
            >
              <span class="btn-text">Filter</span>
            </button>
          </div>
        </div> -->
      </form>
    </aside>

    <div class="FilterPanel-data">
      <div class="row">
        <div class="col Filter-buttons">
          <button class="btn btn-outline-secondary filter-common-btn-open filter-common-btn-loop" role="button" (click)="isFilterOpen = !isFilterOpen" aria-pressed="true" id="Filter-button">
            Filter
          </button>
          <button
            class="btn-clear-filter"
            role="button"
            [disabled]="!enableClearFilter"
            (click)="resetTKFilter(true)"
            aria-pressed="mixed"
          >
            Clear filters
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-icon btn-sm"
            [attr.aria-label]="
              isColumnFreezed
                ? 'Unfreeze PM ID, Name, Period and Firm acct columns'
                : 'Freeze PM ID, Name, Period and Firm acct columns'
            "
            [ngbTooltip]="
              isColumnFreezed
                ? 'Unfreeze PM ID, Name, Period and Firm acct columns'
                : 'Freeze PM ID, Name, Period and Firm acct columns'
            "
            placement="right"
            (click)="isColumnFreezed = !isColumnFreezed"
          >
            <i [ngClass]="isColumnFreezed ? 'bento-icon-lock' : 'bento-icon-unlocked'" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="FilterPanel-dataGrid">
        <wj-flex-grid
          #flexGrid
          [bentoBusyLoader]="isDataLoading"
          class="DataGrid ExtractFlexWidth"
          [frozenColumns]="isColumnFreezed ? 4 : 0"
          [itemsSource]="data"
          [stickyHeaders]="true"
          [showSort]="true"
          [showMarquee]="true"
          [readOnly]="true"
          [allowDragging]="false"
          [autoRowHeights]="true"
          [virtualizationThreshold]="data.totalItemCount"
          (initialized)="gridInitialized(flexGrid)"
          (sortedColumn)="sortGrid($event.getColumn().binding, $event.getColumn())"
        >
          <wj-flex-grid-filter
            (initialized)="initFilter()"
            (filterChanged)="onFilterChange()"
            #filter
          ></wj-flex-grid-filter>
          <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'" let-col="col">
            <ng-container *ngIf="col.index === 0">
              <bento-checkbox
                id="selectAll"
                [ngModel]="isAllSelected()"
                (change)="onHeaderCheckBoxChange()"
                [indeterminate]="isIndeterminate()"
              >
              </bento-checkbox>
              <label for="selectAll" class="sr-only">Select all</label>
            </ng-container>
          </ng-template>
          <ng-template wjFlexGridCellTemplate [cellType]="'RowHeader'" let-col="col" let-row="row" let-item="item">
            <ng-container *ngIf="col.index === 0">
              <bento-checkbox
                id="selectRow-{{ row.index }}"
                [ngModel]="item.selected"
                (change)="item.disabled || (item.selected = !item.selected); onCheckBoxChange()"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}" class="sr-only">{{ item.name }}</label>
            </ng-container>
          </ng-template>
          <wj-flex-grid-column
            [header]="'PM ID'"
            [binding]="'pmId'"
            [visible]="tkFilterData.isPmAccountVisible"
            [minWidth]="40"
            [width]="100"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Name'"
            [binding]="'name'"
            [minWidth]="50"
            [visible]="tkFilterData.isPmAccountVisible"
            [width]="300"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Period'"
            [binding]="'period'"
            [visible]="tkFilterData.isPeriodVisible"
            [minWidth]="40"
            [width]="100"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          >
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Firm acct'"
            [binding]="'account'"
            [visible]="tkFilterData.isFirmActVisible"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Description'"
            [binding]="'description'"
            [visible]="tkFilterData.isDeptVisible"
            [minWidth]="30"
            [width]="300"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Office'"
            [binding]="'office'"
            [visible]="tkFilterData.isOfficeVisible"
            [minWidth]="30"
            [width]="100"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Department'"
            [binding]="'department'"
            [visible]="tkFilterData.isDeptVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Override'"
            [binding]="'override'"
            [visible]="tkFilterData.isOverRideVisible"
            [minWidth]="60"
            [width]="100"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Reallocation ID'"
            [binding]="'readId'"
            [visible]="tkFilterData.isReallocVisible"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Reallocation period'"
            [binding]="'realPer'"
            [visible]="tkFilterData.isReallocVisible"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              {{ item.realPer }}
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Net Activity'"
            [binding]="'netBalance'"
            [visible]="true"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
        </wj-flex-grid>
        <!-- BENTO PAGINATION -->
        <bento-pagination
          #pagination
          [page]="data.pageIndex + 1"
          [directionLinks]="true"
          [boundaryLinks]="true"
          [totalItems]="totalCount"
          [(itemsPerPage)]="data.pageSize"
          (itemsPerPageChange)="onPageSizeChanged($event)"
          (pageChange)="onPageChanged($event)"
        ></bento-pagination>
      </div>
    </div>
  </div>
</main>
