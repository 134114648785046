<div class="Main" [bentoBusyLoader]="isLoadingOneOffs">
  <!--Bento loder overlay issue : Temporary Fix for Bento loader overlay in _layout.scss:288-->
  <div class="Main-body" >
    <wj-flex-grid
        #flexGrid
        class="DataGrid"
        [itemsSource]="data"
        [showMarquee]="true"
        [autoClipboard]="false"
        [isReadOnly]="true"
        [headersVisibility]="'Column'"
        [allowDragging]="false"
        [autoRowHeights]="true"
        (initialized)="gridInitialized(flexGrid)"
        (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
        (keydown)="handleKeyDown(flexGrid, $event)"
      >
        <wj-flex-grid-column
          [header]="'Release'"
          [binding]="'name'"
          [visible]="true"
          [width]="550"
          [isReadOnly]="true"
          [wordWrap]="true"
          [cellTemplate]="templateOneoffLink"
        >
      </wj-flex-grid-column>        
    </wj-flex-grid>
  </div>
</div>