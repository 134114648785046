<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isGetDataLoading">
  <header class="Section-header">
    <h2 class="Section-heading">Current groups</h2>
    <p>The performance peer groups are found in the peer groups selection in create a view.</p>
  </header>
  <div class="Section-body">
    <wj-flex-grid
      #flexGrid
      class="DataGrid"
      [itemsSource]="performancePeerGroups"
      [headersVisibility]="'Column'"
      [autoRowHeights]="true"
      [isReadOnly]="true"
      [stickyHeaders]="true"
      [showSort]="true"
      [allowDragging]="false"
      [autoRowHeights]="true"
      [showMarquee]="true"
      (initialized)="gridInitialized(flexGrid)"
      (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
      [selectionMode]="'Cell'"
      (keydown)="handleKeyDown(flexGrid, $event)"
    >
      <wj-flex-grid-column
        [header]="'Performance peer groups'"
        [binding]="'peerLabel'"
        [width]="'*'"
        [align]="'left'"
        [wordWrap]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
          <h3 class="h4 mb-0">{{ item.peerLabel }}</h3>
          <dl class="bento-desc-list bento-desc-list--inline PeerMeta PeerMeta--small">
            <div class="bento-desc-list-item">
              <dt>Performance criteria:</dt>
              <dd>{{ performanceCriteria[item.peerLabel] }}</dd>
            </div>
            <div class="bento-desc-list-item">
              <dt>Practice groups:</dt>
              <dd>{{ practiceGroups[item.peerLabel] }}</dd>
            </div>
            <div class="bento-desc-list-item">
              <dt>Offices:</dt>
              <dd>{{ offices[item.peerLabel] }}</dd>
            </div>
            <div class="bento-desc-list-item" *ngIf="!hideNumberOfFirmAndPreviewButton">
              <dt>Number of firms:</dt>
              <dd>{{ item.numberOfFirms }}</dd>
            </div>
          </dl>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Action'" [width]="150" [align]="'center'" [wordWrap]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
          <button
            type="button"
            class="btn btn-flat-icon"
            aria-label="Delete"
            ngbTooltip="Delete"
            placement="bottom"
            tabindex="-1"
            (click)="removePPG(item)"
          >
            <i class="bento-icon-remove" aria-hidden="true"></i>
          </button>
        </ng-template>
      </wj-flex-grid-column>
    </wj-flex-grid>
  </div>
</section>
