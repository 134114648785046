<div
id="part3"
class="accordion_child ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content ui-accordion-content-active"
aria-labelledby="ui-id-3"
role="tabpanel"
aria-hidden="false"
>
<div class="accordion-child-content">
  <div class="content-left">
    <img src="assets/images/photo_01.png" width="240" height="180" alt="" />
  </div>
  <div class="content-right body01">
    This function is available when the firm’s input sheet and peer groups have been submitted, and all data
    has been reviewed and approved. To receive your Staffing Ratio results, please select ‘Export Reports’
    below. Reports will be generated in Excel format.
    <br />
    <br />
    <div>
      <button type="button" class="btn btn-primary" (click)="openSRReports(SRReportsContent)">
        <span class="btn-text">Export Reports</span>
      </button>
    </div>
  </div>
</div>
</div>


<!--My Reports Popup-->
<ng-template #SRReportsContent let-c="close" let-d="dismiss">
  <bento-alert [items]="alerts"></bento-alert>
  <div class="modal-content" [bentoBusyLoader]="isDataLoading">
    <button
      type="button"
      class="close"
      aria-label="Close"
      ngbTooltip="Close"
      placement="bottom"
      container="body"
      (click)="d('Cross click')"
    >
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
    
    <h3 id="sr-modal-basic-title" class="modal-header">Download Reports</h3>
    
    <div class="modal-body">
      <ng-container *ngIf="!isExportReportBtnPeerGroupDisabled">
        <p>Select the desired report below:</p>
        <ul>
          <li *ngFor="let reportData of selectedPeerGroupsReportList"><a href="javascript:void(0);" (click)="downloadReport(reportData.peerGroupId, reportData.firmId)">Report for Peer Group: <span class="myreport_title">{{ reportData.peerGroupName }}</span></a></li>
        </ul>
      </ng-container>
      <ng-container *ngIf="isExportReportBtnPeerGroupDisabled">
        <p class="text-center">No reports to export</p>
      </ng-container>
    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="c('Close click')">Close</button>
    </div>
  </div> 
  </ng-template>